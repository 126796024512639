import DownloadIcon from "@mui/icons-material/Download";
import { Avatar, Grid, Hidden, TableRow, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { Download, Pdfdownload } from "../../components";
import {
  globalReportheading,
  globalReportpath,
  globalReportrow,
} from "../../utils/globalreport";
import { SearchFilter } from "../index";
import { NetworkCall } from "../../networkcall";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import config from "../../config";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ECFFF6",
    color: "black",
    fontWeight: "bold",
    borderBottom: "1px solid #DFF8D8",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const useStyles = makeStyles((theme) => ({
  paginate: {
    "& .MuiButtonBase-root-MuiPaginationItem-root": {
      borderRadius: "1px",
      border: "none",
      color: "#606060",
    },
    "& .MuiButtonBase-root-MuiPaginationItem-root.Mui-selected": {
      color: "white",
      borderRadius: "0px",
      border: "none",
      backgroundColor: "#007961",
    },
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
  },
  tabletopbtn: {
    fontWeight: "lighter",
    boxShadow: "none",

    marginLeft: "6px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #E9E9E9",
    borderRadius: "6px",
    color: "black",

    "&:nth-child(1)": {
      textTransform: "capitalize",
    },
    "&:hover": {
      backgroundColor: "#F8F8F8",
      border: "1px solid #E9E9E9",
      borderRadius: "6px",
      boxShadow: "none",
    },
  },
  root: {
    whiteSpace: "noWrap",
  },
  avatar: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    opacity: 0,
    position: "absolute",
    zIndex: -1,
  },
  change: {
    textDecoration: "underline",
    color: "#4D7EFF",
    cursor: "pointer",
  },
  sort: {
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right",
    backgroundSize: "20px",
    cursor: "pointer",
    "&.both": {
      backgroundImage:
        "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7XQMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC)",
      "&:after": {
        color: "yellow",
      },
    },
    "&.asc": {
      backgroundImage:
        "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==)",
    },
    "&.desc": {
      backgroundImage:
        "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=)",
    },
  },
}));

export const TableWithPagination = (props) => {
  const classes = useStyles();
  const pageSize = 10;
  const firstIndex = 1;
  const [isDownload, setisDownload] = React.useState(false);
  const [sorted, setSorted] = React.useState({});

  const [page, setPage] = React.useState(1);

  const [rowData, setData] = React.useState();
  const alert = React.useContext(AlertContext);

  React.useEffect(() => {
    setData(props.rows.slice(firstIndex, pageSize));
  }, [props.rows]);

  // Various Component Selection
  const getComponentType = (data) => {
    switch (data.type) {
      case "status": {
        if (data.value === "Approved") {
          return <p style={{ color: "#267C24" }}>{data.value}</p>;
        } else if (data.value === "UnApproved") {
          return <p style={{ color: "#FF4444" }}>{data.value}</p>;
        } else if (data.value === "Pending") {
          return <p style={{ color: "#B06F0C" }}>{data.value}</p>;
        }
        break;
      }

      case "avatar": {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar src={data.image} sx={{ width: 34, height: 34 }} />
            <Typography style={{ marginLeft: 10 }}>{data.value}</Typography>
          </div>
        );
      }

      case "text": {
        return data.value;
      }

      case "increment": {
        return data.index + 1;
      }

      case "image": {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "20%",
            }}
          >
            <img
              src={data.value}
              alt={data.name}
              style={{ width: "120px" }}
            ></img>
            <label for="upload-photo" className={classes.change}>
              Change...
            </label>
            <input
              type="file"
              name="photo"
              id="upload-photo"
              className={classes.input}
              onChange={(e) => onChangeSignature(e)}
            />
          </div>
        );
      }

      default: {
        return data.value;
      }
    }
  };
  // Signature Upload
  const onChangeSignature = (e) => {
    const files = e.target.files[0];
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    let file_details = {
      file_section: "signature",
      file_section_id: "",
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("file_details", JSON.stringify(file_details));
    Formdata.append("file", files, files.name);
    NetworkCall(
      config.api_url + "/ccc_fileupload_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        props.load();
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Signature Changed successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Table Change
  const handleChange = (event, value) => {
    setPage(value);
    setData(
      props.rows.slice(firstIndex + pageSize * (value - 1), pageSize * value)
    );
  };

  // Download Excel
  const hideGeneratebtn = () => {
    setisDownload(true);
    setTimeout(() => {
      setisDownload(false);
    }, 1000);
  };
  //  Handle Sort
  const handleSorting = (id, index) => {
    let data = sorted;
    if (data?.id === id) {
      if (data?.order === "asc") {
        data.order = "desc";
      } else {
        data.id = "";
        data.order = "";
      }
    } else {
      data.id = id;
      data.order = "asc";
    }
    //here
    let tableData1 = [...props.rows];
    if (data.order) {
      let totalMemCopy = tableData1;
      tableData1?.sort((a, b) => {
        var ele1 =
          typeof a[id] === "string" ? parseInt(a[id]?.replace(",", "")) : a[id];
        var ele2 =
          typeof b[id] === "string" ? parseInt(b[id]?.replace(",", "")) : b[id];
        if (data.order === "asc") return ele1 - ele2;
        else return ele2 - ele1;
      });
      tableData1.push(totalMemCopy);
      setData(tableData1.slice(firstIndex, pageSize));
    } else {
      setData(rowData.slice(firstIndex, pageSize));
    }
    setSorted(data);
  };

  return (
    <div className={classes.root} sx={{ maxHeight: 430 }}>
      <Hidden smDown>
        <Grid container>
          <Grid item xs={0} md={2} lg={7}></Grid>

          <Grid item xs={12} md={10} lg={5}>
            <Box display="flex" p={1}>
              <Box flexGrow={1} paddingRight="20px">
                {props.showSearch && <SearchFilter />}
              </Box>
              <Box>
                {/* Excel btn  */}
                {props.showpdfbtn && (
                  <>
                    {
                      <Button
                        startIcon={<DownloadIcon />}
                        variant="contained"
                        className={classes.tabletopbtn}
                        onClick={hideGeneratebtn}
                      >
                        Excel&nbsp;
                      </Button>
                    }
                  </>
                )}
                {/* Excel download section  */}
                {isDownload && (
                  <Download
                    filename={props.exceltitle}
                    headers={props.heading}
                    path={props.path}
                    data={props.rows}
                  />
                )}
              </Box>
              <Box>
                {/* Pdf btn  */}
                {props.showexcelbtn && (
                  <Pdfdownload
                    heading={props.heading}
                    rows={props.rows}
                    title={props.exceltitle}
                  />
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            {props.showSearch && <SearchFilter />}
            <br />
          </Grid>
          <Grid item xs={6} sm={6}>
            {/* Excel btn  */}
            {props.showpdfbtn && (
              <>
                {
                  <Button
                    fullWidth={true}
                    startIcon={<DownloadIcon />}
                    variant="contained"
                    className={classes.tabletopbtn}
                    onClick={hideGeneratebtn}
                  >
                    Excel&nbsp;
                  </Button>
                }
              </>
            )}
            {/* Excel download section  */}
            {isDownload && (
              <Download
                filename={props.exceltitle}
                headers={globalReportheading}
                path={globalReportpath}
                data={globalReportrow}
              />
            )}
          </Grid>
          <Grid item xs={6} sm={6}>
            {/* Pdf btn  */}
            {props.showexcelbtn && (
              <>
                <Pdfdownload
                  heading={props.heading}
                  rows={props.rows}
                  title={props.exceltitle}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Hidden>

      <TableContainer sx={{ overflowX: "visible" }}>
        <br />
        {/* Table Component */}
        <Table aria-label="customized table" stickyHeader>
          {/* heading */}
          <TableHead>
            {props.showSorting ? (
              <TableRow>
                {props.heading.map((data, index) => {
                  return (
                    <StyledTableCell
                      key={index}
                      className={`${classes.sort} ${
                        data?.field === sorted?.id ? sorted?.order : "both"
                      }`}
                      onClick={() => handleSorting(data.field, index)}
                    >
                      {data.title}
                    </StyledTableCell>
                  );
                })}
              </TableRow>
            ) : (
              <TableRow>
                {props.heading.map((data, index) => {
                  return (
                    <StyledTableCell key={index}>{data.title}</StyledTableCell>
                  );
                })}
              </TableRow>
            )}
          </TableHead>

          {/* rows */}
          {props.showpagination ? (
            <TableBody>
              {rowData?.map((row, index) => {
                return (
                  <TableRow key={index + 1}>
                    {props.dataType.map((val) => {
                      return (
                        <StyledTableCell component="th" scope="row">
                          {val.type.map((type) => {
                            return getComponentType({
                              index: index,
                              type: type,
                              value: row[val.name],
                              image: row[val.imagekey],
                            });
                          })}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          ) : (
            <TableBody>
              {props.rows.map((row, index) => {
                return (
                  <TableRow key={index + 1}>
                    {props.dataType.map((val) => {
                      return (
                        <StyledTableCell component="th" scope="row">
                          {val.type.map((type) => {
                            return getComponentType({
                              index: index,
                              type: type,
                              value: row[val.name],
                              image: row[val.imagekey],
                            });
                          })}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}

          {/* Pagination Section */}
        </Table>
      </TableContainer>
      {props.showpagination && (
        <>
          <div className={classes.pagination}>
            <Pagination
              className={classes.paginate}
              shape="rounded"
              count={Math.ceil(props.rows.length / pageSize)}
              page={page}
              onChange={handleChange}
              color="primary"
              showFirstButton
              showLastButton
            />
          </div>
        </>
      )}
    </div>
  );
};
