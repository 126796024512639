import KeyboardArrowDownOutlined from "@mui/icons-material/KeyboardArrowDownOutlined";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  Hidden,
  List,
  ListItem,
  ListItemText, Stack,
  Typography
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Popover from '@mui/material/Popover';
import Toolbar from "@mui/material/Toolbar";
import makeStyles from "@mui/styles/makeStyles";
import { useHistory} from "react-router-dom";
import React from "react";
import { NetworkCall } from "../../../networkcall";
import config from '../../../config'
import { useEffect } from "react";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {

  },
  toolBar: {
    minHeight: 54
  },
  titleContainer: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    alignItems: "center",
  },
  user: {
    display: "flex",
    alignItems: "center",
  },
  border: {
    borderColor: "white",
    display: "flex",
    alignItems: "center",
  },
  userimg: {
    objectFit: "cover",
    cursor: "pointer"
  },
  Worldcresttitle: {
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  },
  gotocomm: {
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  username: {
    marginLeft: "8px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
    cursor: "pointer"
  },
}));

export const TopNavBar = ({
  hidePersonalInfo = false,
  ...props
}) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [data,setData] = React.useState({})
  const history = useHistory();

  // PopUp
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Logout Close
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (localStorage.getItem("owner_id") === null) {
      var clearMe = setTimeout(() => {
        cccLandingVer1Call();
        clearTimeout(clearMe);
      }, 2000);
    } else {
      cccLandingVer1Call();
    }
  },[]);
  
  const cccLandingVer1Call = () => {
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));

    NetworkCall(
        config.api_url+"/ccc_landing_ver1",
        "post",
        Formdata,
        null,
        false
    )
        .then((response) => {
          var main = response?.data;
          let details ={
            user:main?.data?.profile_details?.user_name,
            user_image:main?.data?.profile_details?.user_image,
            oam_name :main?.data?.profile_details?.oam_name,
            oam_image :main?.data?.profile_details?.oam_image,
            community : main?.data?.go_to_community_url
          }
          setData(details)
          sessionStorage.setItem('apt_list', JSON.stringify(main?.data?.profile_details?.apt_list));
          sessionStorage.setItem('quick_action',JSON.stringify(main?.data?.profile_details?.config_items?.quick_action_items))
          sessionStorage.setItem('configs_list',JSON.stringify(main?.data?.profile_details?.config_items?.quick_action_configs))
          sessionStorage.setItem('datatable_filter',JSON.stringify(main?.data?.profile_details?.config_items?.datatable_columns))
          sessionStorage.setItem('module_selected',JSON.stringify(main?.data?.profile_details?.config_items?.selected_columns))
          sessionStorage.setItem('properties_selected',JSON.stringify(main?.data?.profile_details?.config_items?.selected_properties))
          sessionStorage.setItem('configs_values',JSON.stringify(main?.data?.profile_details?.config_items?.configuration_values))
          sessionStorage.setItem('global_report',JSON.stringify(main?.data?.reports_url))
          sessionStorage.setItem('filter_selection',main?.data?.profile_details?.config_items?.filter_selection)
          sessionStorage.setItem('currency',JSON.stringify(main?.data?.profile_details?.currency))
        })
        .catch((err) => console.log(err));
  }

  // Community
  const handleCommunity=()=>{
    window.location.replace(data.community)
  }
  
  // logout
  const logOut =()=>{
    localStorage.removeItem('auth_token')
    localStorage.removeItem('owner_id')
    window.location.reload()
    window.location.replace('https://adda.io/user/logout.php')
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Grid container>
            <Grid item xs={12}>

              <Box display="flex">
                {/* First Section */}
                <Box flexGrow={1} display="flex">
                  <div className={classes.titleContainer}>
                    <Stack
                      direction="row"
                      divider={<Divider orientation="vertical" flexItem className={classes.border} />}
                      spacing={2}
                      alignItems="center"
                    >
                      <img src="/images/AddaLogo.png" alt="Addalogo" height={"30px"} onClick={()=>window.location='https://adda.io'} style={{cursor:"pointer"}}/>
                      {data?.oam_image ?
                       <img src={data?.oam_image} alt="oam_Logo" height={"30px"} style={{borderRadius:"50%"}} onClick={()=>history.push("/graphical_report")} style={{cursor:"pointer"}}  />
                       :
                       <img src='/images/logo_placeholder.svg' alt="oam_Logo" height={"30px"} style={{borderRadius:"50%"}} onClick={()=>history.push("/graphical_report")} style={{cursor:"pointer"}} />
                      }
                     
                    </Stack>

                    {/* Hidden in Mobile Device only */}
                    <Hidden smDown>
                      <Typography variant="body2" className={classes.Worldcresttitle} noWrap>
                        {data?.oam_name}
                      </Typography>
                    </Hidden>
                  </div>
                </Box>

                {/* Second Section */}
                {!hidePersonalInfo && <Box>
                  {/* For Medium and below deviceses */}
                  <Hidden mdDown>
                    <div className={classes.titleContainer}>
                      <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem className={classes.border} />}
                        spacing={2}
                      >
                        <Typography variant="body2" noWrap className={classes.gotocomm} onClick={handleCommunity}>
                          Go To Community
                        </Typography>

                        <div className={classes.user} >
                          <div onClick={handleClick} >
                            <Avatar 
                              src={data.user_image}
                              className={classes.userimg}
                              alt="user"
                            />
                          </div>
                          <div onClick={handleClick} >
                            <Typography noWrap variant="body2" className={classes.username}>
                             {data.user}
                            </Typography>
                          </div>
                          <IconButton size="small" onClick={handleClick}>
                            <KeyboardArrowDownOutlined style={{ color: "white" }} />
                          </IconButton>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <List>
                              <ListItem>
                                <ListItemText primary="Log Out" style={{cursor:"pointer"}} onClick={()=>logOut()}/>
                              </ListItem>
                            </List>

                          </Popover>
                        </div>

                      </Stack>
                    </div>
                  </Hidden>

                  {/* For Medium and above Device  */}
                  <Hidden mdUp>
                    <div className={classes.titleContainer}>
                      <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem className={classes.border} />}
                        spacing={2}
                      >
                        <div className={classes.user} >
                          <Avatar
                            src={data.user_image}
                            className={classes.userimg}
                            alt="user"
                          />

                          <IconButton size="small" onClick={handleClick}>
                            <KeyboardArrowDownOutlined style={{ color: "white" }} />
                          </IconButton>
                          <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <List>
                              <ListItem>
                              <ListItemText primary="Log Out" style={{cursor:"pointer"}} onClick={()=>logOut()}/>
                              </ListItem>
                            </List>

                          </Popover>
                        </div>
                      </Stack>
                    </div>
                  </Hidden>

                </Box>}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
};
