import React, { useEffect, useState } from "react";
import { Card, Grid, Hidden, Typography, Button, Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Modal from "@mui/material/Modal";
import { NetworkCall } from "../../networkcall";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: 10,
    flexGrow: 1,
  },
  card: {
    display: "flex",
    margin: 10,
    borderRadius: 5,
    width: "auto",
    boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.12)",
  },
  mobileCard: {
    margin: 10,
    borderRadius: 5,
    width: "auto",
    boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.12)",
  },
  headerTitle: {
    color: "#545454",
    fontSize: "11px",
  },
  delete: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFEAEA",
    justifyContent: "center",
    cursor: "pointer",
  },
  deleteMobile: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFEAEA",
    justifyContent: "center",
    padding: 10,
    cursor: "pointer",
  },
  button: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    margin: 10,
  },
  ellipsisExpanded: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  ellipsisClosed: {
    width: "300px",
  },
  header: {
    fontSize: "16px",
    fontFamily: "adda_ccc_semi_bold",
  },
  multiLineEllipsis: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
  Line: {},
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 2,
};

export const UserCard = (props) => {

  const { data } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [Id, setId] = React.useState("");
  const [Index, setIndex] = React.useState(null);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    setUserData(data);
  }, [data]);

  // Show More Handle
  const handleClick = (index) => {
    setIndex(index);
  };
   
  // Show Less Handle
  const handleLess = () => {
    setIndex(null);
  };


//  On User Delete
  const onDelete = (id) => {
    setOpen(true);
    setId(id);
  };

  // On Close Handle
  const handleClose = () => {
    setOpen(false);
  };
 
  // On Delete User
  const handleOK = () => {
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    const action_items = {
      staff_id: Id,
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "delete_staff");
    Formdata.append("action_items", JSON.stringify(action_items));
    NetworkCall(
      config.api_url + "/ccc_quickactions_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        setOpen(false);
        props.onCardDelete();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  return (
    <div className={classes.root}>
      {userData?.map((items, index) => {
        return (
          <div>
            <Hidden smDown>
              <Card className={classes.card}>
                <Grid
                  item
                  xs={1}
                  style={{ display: "flex", alignItems: "center", padding: 10 }}
                >
                  <Typography>{index + 1}</Typography>
                </Grid>
                <Grid
                  item
                  container
                  flexDirection="column"
                  spacing={2}
                  xs={9}
                  style={{ padding: 10 }}
                >
                  <Grid item container flexDirection="row" spacing={2}>
                    <Grid item xs={5}>
                      <Typography className={classes.headerTitle}>
                        Staff Name
                      </Typography>
                      <Typography className={classes.header}>
                        {items.staff_name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography className={classes.headerTitle}>
                        Email ID
                      </Typography>
                      <Typography>{items.staff_email}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item container flexDirection="row" spacing={2}>
                    <Grid item xs={5}>
                      <Typography className={classes.headerTitle}>
                        Contact Number
                      </Typography>
                      <Typography>{items.staff_mobile}</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography className={classes.headerTitle}>
                        Properties Assigned
                      </Typography>
                      {items.staff_properties.length > 70 ? (
                        <div>
                          <Typography
                            className={
                              Index === index
                                ? classes.Line
                                : classes.multiLineEllipsis
                            }
                          >
                            {items.staff_properties}
                          </Typography>
                          {Index !== index ? (
                            <Button onClick={() => handleClick(index)}>
                              Show More
                            </Button>
                          ) : (
                            <Button onClick={() => handleLess()}>Less</Button>
                          )}
                        </div>
                      ) : (
                        <Typography>{items.staff_properties}</Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={2}
                  className={classes.delete}
                  onClick={() => onDelete(items.staff_id)}
                >
                  <div>
                    <img src="/images/icons/Delete.svg" alt="delete"></img>
                  </div>
                </Grid>
              </Card>
            </Hidden>
            <Hidden smUp>
              <Card className={classes.mobileCard}>
                <div style={{ padding: 10 }}>
                  <Typography>{index}</Typography>
                  <Typography className={classes.headerTitle}>
                    Staff Name
                  </Typography>
                  <Typography className={classes.header}>
                    {" "}
                    {items.staff_name}
                  </Typography>
                  <Typography className={classes.headerTitle}>
                    Email ID
                  </Typography>
                  <Typography>{items.staff_email}</Typography>
                  <Typography className={classes.headerTitle}>
                    Contact Number
                  </Typography>
                  <Typography>{items.staff_mobile}</Typography>
                  <Typography className={classes.headerTitle}>
                    Properties Assigned
                  </Typography>
                  <Typography>{items.staff_properties}</Typography>
                </div>
                <div
                  className={classes.deleteMobile}
                  onClick={() => onDelete(items.staff_id)}
                >
                  {" "}
                  <img src="/images/icons/Delete.svg" alt="delete"></img>
                </div>
              </Card>
            </Hidden>
          </div>
        );
      })}
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Remove Staff from Records
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to Remove staff from the list
            </Typography>
            <div className={classes.button}>
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#FF0000", width: "144px" }}
                onClick={handleOK}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                size="medium"
                style={{ backgroundColor: "#4E9F3D", width: "144px" }}
                onClick={handleClose}
              >
                No
              </Button>
            </div>
          </Box>
        </Modal>
      )}
    </div>
  );
};
