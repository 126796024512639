import { Box, Button, Grid, Hidden, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useLocation } from "react-router-dom";
import { TableWithPagination } from "../../components";
import BreadCrumb from '../../components/breadcrumb';
import DateTime from "../../components/date";
import { Routes } from '../../router/routes';
import { breadCrumbsGlobal, globalReportheading, globalReportpath, globalReportrow } from '../../utils/globalreport';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,

        borderRadius: 12,
        [theme.breakpoints.down("sm")]: {
            margin: 10,
        },
        [theme.breakpoints.up("lg")]: {
            margin: 20,
        },
    },
    data2: {
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px",
        },
    },
    title: {
        color: theme.palette.title.color,
        fontWeight: "bold",
        marginTop: "10px",
    },
    generatebtn: {
        padding: "12px",

        marginTop: "20px"
    },
    topcontainer: {
        padding: 15
    },
    table: {
        padding: 15,
        marginTop: 8
    },
    border: {
        border: "1px solid #EDEDED"
    }
}))

export const Golabal = props => {
    const classes = useStyles();
    const location = useLocation();

    // Report Title From Route
    const title = location.state.detail
    //  

    return <div className={classes.root}>
        <div className={classes.topcontainer}>
            {/* BreadCrumb section */}
            <Grid container >
                <Grid item xs={12}>

                    <BreadCrumb breadCrumbs={
                        
                        breadCrumbsGlobal 
                    
                    } label={title} link={Routes.global_report_parent + 1} />
                    <Typography variant="h6" className={classes.title}>

                        {
                            title
                        }
                        
                    </Typography>

                </Grid>
            </Grid>
            <br />
            <Hidden smDown>
                <Grid container spacing={10} alignItems="center">
                    {/* Datepicker section */}
                    <Grid item xs={10} sm={8} md={8} lg={5}>
                        <Box display="flex" className={classes.dateContainer}>
                            <Box>
                                <DateTime title="From Date" />
                            </Box>
                            <Box alignSelf="center" marginTop="12px" paddingLeft="12px" paddingRight="12px" >
                                <Typography variant="subtitle1"><b>&nbsp;&nbsp;-&nbsp;&nbsp;</b> </Typography>
                            </Box>
                            <Box>
                                <DateTime title="To Date" />
                            </Box>
                        </Box>
                    </Grid>

                    {/* Generate btn section */}
                    <Grid item xs={2} sm={4} lg={2} >
                        <Button variant="contained" color="success" className={classes.generatebtn} fullWidth={true} >Generate</Button>
                    </Grid>


                </Grid>
            </Hidden>
            {/* Datepicker section */}
            <Hidden smUp>
                <Grid container justifyContent="center">
                    <Grid item xs={12}>
                        <DateTime title="From Date" />
                    </Grid>


                    <Grid item xs={12}>
                        <br />
                        <DateTime title="To Date" />
                    </Grid>

                    {/* Generate btn section */}
                    <Grid item xs={6}  >
                        <Button variant="contained" color="success" className={classes.generatebtn} fullWidth={true} >Generate</Button>
                    </Grid>


                </Grid>
            </Hidden>
        </div>
        <hr className={classes.border} />
        {/* Table Section */}
        <div className={classes.table}>
            <TableWithPagination
                exceltitle="Invoice vs Collection vs Variance"
                path={globalReportpath}
                heading={globalReportheading}
                rows={globalReportrow}
                showpagination={true}
                count="2"
                showpdfbtn={true}
                showexcelbtn={true}
                showSearch={true}
                showSorting ={true}
                dataType={[
                    { type: ["number"], name: "id" },
                    { type: ["text"], name: "propertyName" },
                    { type: ["number"], name: "invoiced" },
                    { type: ["number"], name: "collected" },
                    { type: ["number"], name: "outstanding" },
                    { type: ["number"], name: "collectedpercent" },
                ]}

            />
            <br />
        </div>
    </div>
}
