import React from "react";
import { withNavBars } from "../../HOCs";
import Configure from "./configure";

class ConfigureParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return <Configure {...this.props} />;
    }
}

export default withNavBars(ConfigureParent);
