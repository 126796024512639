import { Button, Grid, Hidden } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useEffect } from "react";
import { SelectBox } from "../../components";
import CheckBox from "../../components/checkbox";
import DateTime from "../../components/date";
import TextBox from "../../components/textbox";
import ReactQuillWrapper from "../../components/richTextEditor/richEditor3";
import { AlertContext, BackdropContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import { MobileView } from "../../components";
import { UploadReports } from "../../components/upload";
import { NetworkCall } from "../../networkcall";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: 10,
  },
  btn: {},
}));

const InitialState = {
  communities: [],
  recipients: {},
  reply_to_emailid: "",
  include_managers: false,
  announcement_title: "",
  announcement_description: "",
  expiry_date: null,
  file_ids: [],
  error: {
    communities: "",
    recipients: "",
    reply_to_emailid: "",
    include_managers: "",
    announcement_title: "",
    announcement_description: "",
    expiry_date: "",
    file_ids: "",
  },
};

const NewState = {
  communities: "",
  recipients: "",
  reply_to_emailid: "",
  include_managers: null,
  announcement_title: "",
  announcement_description: "",
  expiry_date: null,
  file_ids: [],
  error: {
    communities: "",
    recipients: "",
    reply_to_emailid: "",
    include_managers: "",
    announcement_title: "",
    announcement_description: "",
    expiry_date: "",
    file_ids: "",
  },
};

let vals = [];

const CreateAnnouncement = (props) => {
  const classes = useStyles();

  const [announcement, setAnnouncement] = React.useState({ ...InitialState });
  const [preview, setPreview] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const today = new Date();

  // Communities Dropdown
  const tempOptions = [];
  //

  // Recepients Dropdown
  const recepOptions = [];
  //

  let apt_list = JSON.parse(sessionStorage.getItem("apt_list"));
  const recepient = JSON.parse(sessionStorage.getItem("configs_list"));
  let staff_access_to_community = recepient;
  staff_access_to_community = staff_access_to_community?.find((item) => {
    if (item?.module_name === "announcement") return item;
  });
  apt_list = apt_list?.filter((list, index) => {
    let allowedAddasStr = staff_access_to_community?.allowed_addas;
    let allowedAddasStrArr = allowedAddasStr.split(",");
    if (allowedAddasStrArr.includes(list?.apt_id)) return list;
  });
  tempOptions.push({ value: "all", label: "All Properties" });
  apt_list?.map((list) => {
    tempOptions.push({ value: list.apt_id, label: list.apt_name });
    return 0;
  }); //Construction of Communities Dropdown

  recepient?.map((items) => {
    items?.recepient_list?.map((list, index) => {
      recepOptions.push({ value: index, label: list });
      return 0;
    });
    return 0;
  }); //Construction of Recepients Dropdown

  // Initial
  useEffect(() => {
    setAnnouncement({ ...InitialState });
    vals = [];
  }, []);

  // File Upload
  const updateFile = (key, value, variant) => {
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    let file_details = {
      file_section: "notice_attachment",
      file_section_id: "",
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("file_details", JSON.stringify(file_details));
    Formdata.append("file", value.file, value.name);
    NetworkCall(
      config.api_url + "/ccc_fileupload_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        let main = response?.data;
        vals.push(main.data?.file?.file_ids);
      })
      .catch((err) => {
        console.log(err);
      });
    let error = announcement.error;
    error[key] = "";
    setAnnouncement({ ...announcement, [key]: vals, error });
  };
  //

  // Delete File
  const deleteFile = (key, value, variant) => {
    vals = [];
    if (value.length === 0) {
      let error = announcement.error;
      error[key] = "";
      setAnnouncement({ ...announcement, [key]: value, error });
    } else {
      var Formdata = new FormData();
      let auth = {
        owner_id: localStorage.getItem("owner_id"),
        auth_key: localStorage.getItem("auth_token"),
      };
      let file_details = {
        file_section: "notice",
        file_section_id: "",
      };
      Formdata.append("auth", JSON.stringify(auth));
      Formdata.append("file_details", JSON.stringify(file_details));
      Formdata.append("file", value.file, value.name);
      NetworkCall(
        config.api_url + "/ccc_fileupload_ver1",
        "post",
        Formdata,
        null,
        false
      )
        .then((response) => {
          let main = response?.data;
          vals.push(main.data?.file?.file_id);
        })
        .catch((err) => {
          console.log(err);
        });
      let error = announcement.error;
      error[key] = "";
      setAnnouncement({ ...announcement, [key]: vals, error });
    }
  };
  //

  // State Update
  const updateState = (key, value) => {
    if (key === "communities") {
      let Al = [];
      let ALL = [];
      value.map((val) => {
        if (val.value === "all") {
          ALL.push({ value: "all", label: "All Properties" });
          setAnnouncement({ ...announcement, [key]: ALL });
        } else {
          Al.push(val);
          setAnnouncement({ ...announcement, [key]: Al });
        }
        return 0;
      });
    } else {
      let error = announcement.error;
      error[key] = "";
      setAnnouncement({ ...announcement, [key]: value, error });
    }
  };
  //

  // Validation
  const isIamValideToCreateAnnouncement = () => {
    let isValid = true;
    let error = announcement?.error;

    //Checking Community
    if (!announcement.communities.length) {
      isValid = false;
      error.communities = Message.English.requiredMessage("Communities");
    } else {
      error.communities = "";
    }

    //Checking Recipients
    if ( Object.keys(announcement.recipients).length === 0  ) {
      isValid = false;
      error.recipients = "Recipients not Chosen";
    } else {
      error.recipients = "";
    }

    //Checking Email
    if (announcement.reply_to_emailid === "") {
      isValid = false;
      error.reply_to_emailid = "Reply to Email is Required";
    } else {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!announcement.reply_to_emailid.match(validRegex)) {
        error.reply_to_emailid = "Valid Email Address is Required";
      }
    }

    //Checking AnnouncementTitle
    if (announcement.announcement_title === "") {
      isValid = false;
      error.announcement_title =
        "Announcement Title is Required";
    }

    //Checking BriefDescription
    if (announcement.announcement_description === "") {
      isValid = false;
      error.announcement_description = "Announcement Description is Required";
    }
    //Checking ExpiryDate
    if (announcement.expiry_date === null) {
      isValid = false;
      error.expiry_date = "Expiry Date is Required";
    }

    setAnnouncement({ ...announcement, error });
    return isValid;
  };
  //

  // On Save
  const onSendAnnouncementBtnClicked = () => {
    if (isIamValideToCreateAnnouncement()) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Creating Announcement...",
      });
      //Hit Api
      let values = [];
      announcement?.communities?.map((items) => {
        values.push(items.value);
        return 0;
      });
      let rec = announcement?.recipients.label;
      var event = new Date(announcement?.expiry_date);
      let date = JSON.stringify(event);
      date = date.slice(1, 11);

      NewState.communities = values.toString();
      NewState.recipients = rec;
      NewState.reply_to_emailid = announcement.reply_to_emailid;
      NewState.include_managers =
        announcement.include_managers === true ? 1 : 0;
      NewState.announcement_title = announcement.announcement_title;
      NewState.announcement_description = announcement.announcement_description;
      NewState.expiry_date = date;
      NewState.file_ids = announcement.file_ids;

      var Formdata = new FormData();
      let auth = {
        owner_id: localStorage.getItem("owner_id"),
        auth_key: localStorage.getItem("auth_token"),
      };
      Formdata.append("auth", JSON.stringify(auth));
      Formdata.append("action_items", JSON.stringify(NewState));
      Formdata.append("case", "announcement");
      NetworkCall(
        config.api_url + "/ccc_quickactions_ver1",
        "post",
        Formdata,
        null,
        false
      )
        .then((response) => {
          backdrop.setBackDrop({ ...backdrop, open: false });
          props.onClose();
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Announcement Sent Successfully",
          });
        })
        .catch((err) => {
          console.log(err);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.error,
            msg: "Announcement Not Send",
          });
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please Fill all the fields",
      });
    }
  };
  //

  //Mobile Priview of Title and Description
  const onPreview = () => {
    setPreview(true);
  };

  // Preview Close
  const onDClose = (title, desc) => {
    setPreview(false);
  };
  //

  return (
    <div className={classes.root}>
      {!preview && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectBox
              isMulti={true}
              value={announcement.communities}
              onChange={(value) => updateState("communities", value)}
              label={Message.English.announcement.communities.label}
              placeholder={
                Message.English.announcement.communities.placeholder
              }
              options={tempOptions}
              isError={announcement.error.communities.length > 0}
              errorMessage={announcement.error.communities}
              isCheckbox={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              allowSelectAll={true}
              isRequired
            />
          </Grid>
          <Grid item xs={12} spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={6}>
                <SelectBox
                  value={announcement.recipients}
                  onChange={(value) => updateState("recipients", value)}
                  label={Message.English.announcement.recipients.label}
                  placeholder={
                    Message.English.announcement.recipients.placeholder
                  }
                  options={recepOptions}
                  isError={announcement.error.recipients.length > 0}
                  errorMessage={announcement.error.recipients}
                  isRequired
                />
                <CheckBox
                  label={Message.English.announcement.CheckBoxLabel.label}
                  labelPlacement="end"
                  value={announcement.include_managers}
                  onChange={(value) => updateState("include_managers", value)}
                  isError={announcement.error.include_managers.length > 0}
                  errorMessage={announcement.error.include_managers}
                />
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                <TextBox
                  value={announcement.reply_to_emailid}
                  onChange={(e) =>
                    updateState("reply_to_emailid", e.target.value)
                  }
                  label={
                    Message.English.announcement.reply_to_emailid.label
                  }
                  placeholder={
                    Message.English.announcement.reply_to_emailid
                      .placeholder
                  }
                  isError={announcement.error.reply_to_emailid.length > 0}
                  errorMessage={announcement.error.reply_to_emailid}
                  isRequired
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextBox
              value={announcement.announcement_title}
              onChange={(e) =>
                updateState("announcement_title", e.target.value)
              }
              label={
                Message.English.announcement.announcement_title.label
              }
              placeholder={
                Message.English.announcement.announcement_title
                  .placeholder
              }
              isError={announcement.error.announcement_title.length > 0}
              errorMessage={announcement.error.announcement_title}
              isRequired
            />
          </Grid>
          <Grid item xs={12}>
            <ReactQuillWrapper
              label={
                Message.English.announcement.announcement_description
                  .label
              }
              placeholder={
                Message.English.announcement.announcement_description
                  .placeholder
              }
              isError={announcement.error.announcement_description.length > 0}
              errorMessage={announcement.error.announcement_description}
              onChange={(value) =>
                updateState("announcement_description", value)
              }
              value={announcement.announcement_description}
              isRequired
            />
          </Grid>
          <Grid item xs={12}>
            <DateTime
              value={announcement.expiry_date}
              onChange={(value) => updateState("expiry_date", value)}
              title={Message.English.announcement.expiry_date.title}
              isError={announcement.error.expiry_date.length > 0}
              errorMessage={announcement.error.expiry_date}
              minDate={today}
              disablePast={true}
              isRequired
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            {/* upload component here */}
            <UploadReports
              value={announcement.documents}
              onChange={updateFile}
              onDelete={deleteFile}
              label={Message.English.announcement.uploadLabel.label}
              isError={announcement.error.file_ids.length > 0}
              errorMessage={announcement.error.file_ids}
            />
          </Grid>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            spacing={2}
            xs={12}
          >
            <Hidden smDown>
              <Grid item>
                <br />
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => onPreview()}
                >
                  Preview
                </Button>
                <br />
                <br />
              </Grid>
              <Grid item>
                <br />
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSendAnnouncementBtnClicked}
                >
                  Send Announcement
                </Button>
                <br />
                <br />
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid item>
                <Button
                  variant="outlined"
                  color="success"
                  onClick={() => onPreview()}
                >
                  Preview
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={onSendAnnouncementBtnClicked}
                >
                  Send Announcement
                </Button>
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      )}
      {preview && (
        <MobileView
          onClose={onDClose}
          announcementTitle={announcement?.announcement_title}
          announcementDesc={announcement?.announcement_description}
        />
      )}
    </div>
  );
};

export default CreateAnnouncement;
