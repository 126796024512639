import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button } from '@mui/material';

export const InternalServerError = (props) => {

  const {
    message,
    onRetry,
    retryBtnName,
    top
  } = props;

  return <div>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ marginTop: top }}
    >
      <Grid item>
        <Typography variant="h6" align="center">
          {message}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          style={{ marginTop: 16, textAlign: "center" }}
          variant={"outlined"}
          onClick={onRetry}
        >
          {retryBtnName}
        </Button>
      </Grid>
    </Grid>
  </div>
}

InternalServerError.propTypes = {
  message: PropTypes.string,
  onRetry: PropTypes.func.isRequired,
  retryBtnName: PropTypes.string,
  top: PropTypes.string
}

InternalServerError.defaultProps = {
  message: 'Something went wrong!',
  retryBtnName: 'Try Again',
  top: '30vh'
}

