import { Typography } from '@mui/material';
import React from "react";
import {Editor,getDefaultKeyBinding,RichUtils,convertToRaw} from 'draft-js';
import 'draft-js/dist/Draft.css';
import './RichTextEditor.css'

const RichTextEditors =(props)=>{

    const [editorState, setEditorState] = React.useState(props?.value);

      const getTitle = (props) => {
        return (
            <div style={{display:"flex"}}>
                       <Typography variant="body1" style={{ fontSize: "11px", color: "textsecondary" }} gutterBottom >{props.label}</Typography>
                       {props.isRequired && (
                <Typography color="error" variant="caption">
                  &nbsp;*
                </Typography>
              )}
            </div>    
        )
       
    }

      const onChange =(editorState)=>{
        setEditorState(editorState);
        const contentState = editorState?.getCurrentContent();
        props?.onChange(JSON.stringify(convertToRaw(contentState)));
      }

      // useEffect(()=>{
      //   if(editorState.length){
      //       contentState2 = convertFromRaw(JSON.parse(editorState));
      //       editorState2 = EditorState.createWithContent(contentState2);
      //       setEditorState(editorState2)
      //   }
      //   else {
      //       setEditorState(editorState)
      //   }
      // },[editorState]) 


    let className = 'RichEditor-editor';
    var contentState = editorState?.getCurrentContent();
    if(contentState !== undefined){
        if (!contentState.hasText()) {
            if (contentState.getBlockMap().first().getType() !== 'unstyled') {
              className += ' RichEditor-hidePlaceholder';
            }
          }
    }
   

   const _handleKeyCommand=(command, editorState)=> {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
          return true;
        }
        return false;
      }
  
     const _mapKeyToEditorCommand=(e)=> {
        if (e.keyCode === 9 /* TAB */) {
          const newEditorState = RichUtils.onTab(
            e,
            editorState,
            4, /* maxDepth */
          );
          if (newEditorState !== editorState) {
            onChange(newEditorState);
          }
          return;
        }
        return getDefaultKeyBinding(e);
      }
  
     const _toggleBlockType=(blockType)=> {
        onChange(
          RichUtils.toggleBlockType(
            editorState,
            blockType
          )
        );
      }
  
     const _toggleInlineStyle=(inlineStyle)=> {
        onChange(
          RichUtils.toggleInlineStyle(
            editorState,
            inlineStyle
          )
        );
      }
      
    
   
     
return(
    <div>
        {getTitle(props)}
          <div className="RichEditor-root">
           
    <BlockStyleControls
      editorState={editorState}
      onToggle={_toggleBlockType}
    />

    <InlineStyleControls
      editorState={editorState}
      onToggle={_toggleInlineStyle}
    />
    <div className={className}>
    
      <Editor
        blockStyleFn={getBlockStyle}
        customStyleMap={styleMap}
        editorState={editorState}
        handleKeyCommand={_handleKeyCommand}
        keyBindingFn={(e)=>_mapKeyToEditorCommand(e)}
        onChange={onChange}
        placeholder={props.placeholder}
        spellCheck={true}
      />
    </div>
          </div>
          {props.isError && <Typography variant={"caption"} color={"error"}>{props.errorMessage}</Typography>}
    </div>
  
)
}

  // Custom overrides for "code" style.
  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2,
    },
  };

  function getBlockStyle(block) {
    switch (block.getType()) {
      case 'blockquote': return 'RichEditor-blockquote';
      default: return null;
    }
  }

  class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }

    render() {
      let className = 'RichEditor-styleButton';
      if (this.props.active) {
        className += ' RichEditor-activeButton';
      }

      return (
        <span className={className} onMouseDown={this.onToggle}>
          {this.props.label}
        </span>
      );
    }
  }

  const BLOCK_TYPES = [
    {label: 'H1', style: 'header-one'},
    {label: 'H2', style: 'header-two'},
    {label: 'H3', style: 'header-three'},
    {label: 'H4', style: 'header-four'},
    {label: 'H5', style: 'header-five'},
    {label: 'H6', style: 'header-six'},
    {label: 'Blockquote', style: 'blockquote'},
    {label: 'UL', style: 'unordered-list-item'},
    {label: 'OL', style: 'ordered-list-item'},
    {label: 'Code Block', style: 'code-block'},
  ];

  const BlockStyleControls = (props) => {
    const {editorState} = props;
    const selection = editorState?.getSelection();
    const blockType = editorState?.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
      <div className="RichEditor-controls">
        {BLOCK_TYPES.map((type) =>
          <StyleButton
            key={type.label}
            active={type.style === blockType}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

  var INLINE_STYLES = [
    {label: 'Bold', style: 'BOLD'},
    {label: 'Italic', style: 'ITALIC'},
    {label: 'Underline', style: 'UNDERLINE'},
    {label: 'Monospace', style: 'CODE'},
  ];

  const InlineStyleControls = (props) => {
    const currentStyle = props?.editorState?.getCurrentInlineStyle();
    
    return (
      <div className="RichEditor-controls">
        {INLINE_STYLES.map((type) =>
          <StyleButton
            key={type.label}
            active={currentStyle?.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
          />
        )}
      </div>
    );
  };

export default RichTextEditors;
