import { Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useEffect } from "react";
import { SelectBox } from "../../components";
import CheckBox from "../../components/checkbox";
import TextBox from "../../components/textbox";
import { UploadReports } from "../../components/upload";
import { AlertContext, BackdropContext, DialogContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";
import { NetworkCall } from "../../networkcall";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: 10,
  },
  btn: {
    width: "154px",
  },
}));

const InitialState = {
  community: {},
  unit_id: {},
  category: {},
  sub_category: {},
  ticket_type: {},
  issue: "",
  file_ids: [],
  urgent: false,
  error: {
    community: "",
    unit_id: "",
    category: "",
    sub_category: "",
    ticket_type: "",
    issue: "",
    file_ids: "",
    urgent: "",
  },
};

// Initial Val
let vals = [];
//

const CreateTicket = (props) => {
  const classes = useStyles();
  const [ticket, setTicket] = React.useState({ ...InitialState });
  const [options, setOptions] = React.useState({ option: [], unitOption: [] });
  const [categories, setCategory] = React.useState();
  const [subCategory, setsubCategory] = React.useState();
  const [disable, setDisable] = React.useState(false);
  const alert = React.useContext(AlertContext);
  const backdrop = React.useContext(BackdropContext);
  const dialog = React.useContext(DialogContext);

  // Communities Dropdown
  const tempOptions = [];
  let apt_list = JSON.parse(sessionStorage.getItem("apt_list"));
  let staff_access_to_community = JSON.parse(sessionStorage.getItem("configs_list"));
  staff_access_to_community = staff_access_to_community?.find((item) => {
    if (item?.module_name === "helpdesk") return item;
  });
  apt_list = apt_list?.filter((list, index) => {
    let allowedAddasStr = staff_access_to_community?.allowed_addas;
    let allowedAddasStrArr = allowedAddasStr.split(",");
    if(allowedAddasStrArr.includes(list.apt_id)) return list;
  });
  apt_list?.map((list) => {
    tempOptions.push({ value: list.apt_id, label: list.apt_name });
    return 0;
  });
  //

  useEffect(() => {
    setTicket({ ...InitialState });
    vals = [];
  }, []);

  const updateState = (key, value) => {
    let error = ticket.error;
    error[key] = "";
    setTicket({ ...ticket, [key]: value, error });
  };

  // File Upload
  const updateFile = (key, value, variant) => {
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    let file_details = {
      file_section: "helpdesk",
      file_section_id: "",
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("file_details", JSON.stringify(file_details));
    Formdata.append("file", value.file, value.name);
    NetworkCall(
      config.api_url + "/ccc_fileupload_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        let main = response?.data;
        vals.push(main.data?.file?.file_id);
      })
      .catch((err) => {
        console.log(err);
      });
    let error = ticket.error;
    error[key] = "";
    setTicket({ ...ticket, [key]: vals, error });
  };
  //

  // File Delete
  const deleteFile = (key, value, variant) => {
    vals = [];
    if (value.length === 0) {
      let error = ticket.error;
      error[key] = "";
      setTicket({ ...ticket, [key]: value, error });
    } else {
      var Formdata = new FormData();
      let auth = {
        owner_id: localStorage.getItem("owner_id"),
        auth_key: localStorage.getItem("auth_token"),
      };
      let file_details = {
        file_section: "helpdesk",
        file_section_id: "",
      };
      Formdata.append("auth", JSON.stringify(auth));
      Formdata.append("file_details", JSON.stringify(file_details));
      Formdata.append("file", value.file, value.name);
      NetworkCall(
        config.api_url + "/ccc_fileupload_ver1",
        "post",
        Formdata,
        null,
        false
      )
        .then((response) => {
          let main = response?.data;
          vals.push(main.data?.file?.file_id);
        })
        .catch((err) => {
          console.log(err);
        });
      let error = ticket.error;
      error[key] = "";
      setTicket({ ...ticket, [key]: vals, error });
    }
  };
  //

  // Update Community
  const updateCommunity = (key, value) => {
    let error = ticket.error;
    ticket.unit_id = {};
    ticket.category = {};
    ticket.sub_category = {};
    ticket.ticket_type = {};
    error[key] = "";
    setTicket({ ...ticket, [key]: value, error });
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "ticket_categories");
    Formdata.append("key", value.value);
    NetworkCall(
      config.api_url + "/ccc_quickfetch_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        let main = response?.data;
        const category = main?.data?.ticket_categories;
        const units = main?.data?.units;
        const options = [];
        const unit = [];
        category?.map((c) => {
          options.push({ value: c.category_name, label: c.category_name });
          return 0;
        });
        units?.map((c) => {
          unit.push({ value: c.unit_id, label: c.unit_no });
          return 0;
        });
        setCategory(category);
        setOptions({
          option: options,
          unitOption: unit,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //

  // Update Category
  const updateCategory = (key, value) => {
    let error = ticket.error;
    ticket.sub_category = {};
    ticket.ticket_type = {};
    error[key] = "";
    setTicket({ ...ticket, [key]: value, error });
    const catOption = [];
    categories?.map((c) => {
      if (c.category_name === value.value) {
        if (c.subcategories.length > 0) {
          setDisable(false);
          c.subcategories?.map((keys) => {
            catOption.push({
              value: keys.subcategory_name,
              label: keys.subcategory_name,
            });
            return 0;
          });
        } else {
          setDisable(true);
        }
      }
      return 0;
    });
    setsubCategory(catOption);
  };
  //

  // Update Sub Category
  const updateSubcategory = (key, value) => {
    let error = ticket.error;
    error[key] = "";
    setTicket({ ...ticket, [key]: value, error });
  };
  //

  //Validate
  const isIamValideToCreateTicket = () => {
    let isValid = true;
    let error = ticket.error;

    //Checking Community
    if (!ticket.community.value) {
      isValid = false;
      error.community = Message.English.requiredMessage("Community");
    }

    //Checking Unit
    if (!ticket.unit_id.value) {
      isValid = false;
      error.unit_id = Message.English.requiredMessage("Unit");
    }

    //Checking Issue
    if (ticket.issue === "") {
      isValid = false;
      error.issue = Message.English.requiredMessage("Issue");
    }

    //Checking Category
    if (!ticket.category.value) {
      isValid = false;
      error.category = Message.English.requiredMessage("Category");
    }
    //Checking subCategory
    if (!ticket.sub_category.value && !disable) {
      isValid = false;
      error.sub_category = "Sub Category is Required";
    }
    //Checking ticketType
    if (!ticket.ticket_type.value) {
      isValid = false;
      error.ticket_type = "Ticket Type is Required";
    }

    //Checking

    setTicket({ ...ticket, error });
    return isValid;
  };
  //

  // on Create
  const onCreateTicketBtnClicked = () => {
    if (isIamValideToCreateTicket()) {
      backdrop.setBackDrop({
        ...backdrop,
        open: true,
        message: "Creating Ticket...",
      });

      //Hit Api

      const Ticket = { ...ticket };
      if (Ticket.urgent === true) {
        Ticket.urgent = 1;
      } else Ticket.urgent = 0;
      Ticket.file_ids = Ticket.file_ids.toString();
      Ticket.community = Ticket.community.value;
      Ticket.unit_id = Ticket.unit_id.value;
      Ticket.category = Ticket.category.value;
      Ticket.sub_category = Ticket.sub_category.value;
      Ticket.ticket_type = Ticket.ticket_type.value;

      var Formdata = new FormData();
      let auth = {
        owner_id: localStorage.getItem("owner_id"),
        auth_key: localStorage.getItem("auth_token"),
      };
      Formdata.append("auth", JSON.stringify(auth));
      Formdata.append("case", "create_ticket");
      Formdata.append("action_items", JSON.stringify(Ticket));
      NetworkCall(
        config.api_url + "/ccc_quickactions_ver1",
        "post",
        Formdata,
        null,
        false
      )
        .then((response) => {
          var main = response?.data;
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main?.message,
          });
          dialog.setDialog({ ...dialog, open: false });
          props.onClose();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please fill all the mandatory fields",
      });
      return false;
    }
  };
  //

  // Ticket Dropdown
  const ticketType = [
    { value: "Personal", label: "Personal" },
    { value: "Community", label: "Community" },
  ];
  //

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        {/* Community  */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SelectBox
            value={ticket.community}
            onChange={(value) => updateCommunity("community", value)}
            label={Message.English.create_ticket.community.label}
            placeholder={Message.English.create_ticket.community.placeholder}
            options={tempOptions}
            isError={ticket.error.community.length > 0}
            errorMessage={ticket.error.community}
            isRequired
          />
        </Grid>

        {/* Unit */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SelectBox
            value={ticket.unit_id}
            onChange={(value) => updateState("unit_id", value)}
            label={Message.English.create_ticket.unit.label}
            placeholder={Message.English.create_ticket.unit.placeholder}
            options={options.unitOption}
            isError={ticket.error.unit_id.length > 0}
            errorMessage={ticket.error.unit_id}
            isRequired
          />
        </Grid>

        {/* Category  */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SelectBox
            value={ticket.category}
            onChange={(value) => updateCategory("category", value)}
            label={Message.English.create_ticket.category.label}
            placeholder={Message.English.create_ticket.category.placeholder}
            options={options.option}
            isError={ticket.error.category.length > 0}
            errorMessage={ticket.error.category}
            isRequired
          />
        </Grid>

        {/* Sub-Category */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SelectBox
            value={ticket.sub_category}
            onChange={(value) => updateSubcategory("sub_category", value)}
            label={Message.English.create_ticket.subCategory.label}
            placeholder={Message.English.create_ticket.subCategory.placeholder}
            options={subCategory}
            isError={ticket.error.sub_category.length > 0}
            errorMessage={ticket.error.sub_category}
            disable={disable}
          />
        </Grid>

        {/*Titcket Type */}
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <SelectBox
            value={ticket.ticket_type}
            onChange={(value) => updateState("ticket_type", value)}
            label={Message.English.create_ticket.ticketType.label}
            placeholder={Message.English.create_ticket.ticketType.placeholder}
            options={ticketType}
            isError={ticket.error.ticket_type.length > 0}
            errorMessage={ticket.error.ticket_type}
            isRequired
          />
        </Grid>

        {/* "Write Issue */}
        <Grid item xs={12}>
          <TextBox
            multiline
            value={ticket.issue}
            onChange={(e) => updateState("issue", e.target.value)}
            label={Message.English.create_ticket.issue.label}
            placeholder={Message.English.create_ticket.issue.placeholder}
            isError={ticket.error.issue.length > 0}
            errorMessage={ticket.error.issue}
            isRequired
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* upload component here */}
          <UploadReports
            value={ticket.documents}
            onChange={updateFile}
            onDelete={deleteFile}
            label={Message.English.create_ticket.uploadLabel.label}
            isError={ticket.error.file_ids.length > 0}
            errorMessage={ticket.error.file_ids}
          />
        </Grid>
        {/* checkbox  */}
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent={"center"}
          xs={12}
        >
          <CheckBox
            label={Message.English.create_ticket.CheckBoxLabel.label}
            labelPlacement="end"
            value={ticket.urgent}
            onChange={(value) => updateState("urgent", value)}
          />
        </Grid>
        {/* button */}
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
          xs={12}
          marginTop={2}
        >
          <Button
            variant="contained"
            color="success"
            className={classes.btn}
            onClick={onCreateTicketBtnClicked}
          >
            Create Request
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
export default CreateTicket;
