export const Moduledata = [
    {
      id: "0",
      name: "Units & Users",
      children: [
        {
          id: "1",
          name: "Child - 1",
        },
        {
          id: "2",
          name: "Child - 3",
        },
        {
          id: "3",
          name: "Child - 3",
        },
        {
          id: "4",
          name: "Child - 4",
        },
      ],
    },
    {
      id: "5",
      name: "Helpdesk",
      children: [
        {
          id: "6",
          name: "Child - 6",
        },
        {
          id: "7",
          name: "Child - 7",
        },
        {
          id: "8",
          name: "Child - 8",
        },
        {
          id: "9",
          name: "Child - 9",
        },
      ],
    },
    {
        id: "10",
        name: "Income Tracker",
        children: [
          {
            id: "11",
            name: "Child - 10",
          },
          {
            id: "12",
            name: "Child - 11",
          },
          {
            id: "13",
            name: "Child - 12",
          },
          {
            id: "14",
            name: "Child - 13",
          },
        ],
      },
      {
        id: "15",
        name: "Expence Tracker",
        children: [
          {
            id: "16",
            name: "Child - 10",
          },
          {
            id: "17",
            name: "Child - 11",
          },
          {
            id: "18",
            name: "Child - 12",
          },
          {
            id: "19",
            name: "Child - 13",
          },
        ],
      },
      {
        id: "20",
        name: "Bank Balance",
        children: [
          {
            id: "21",
            name: "Child - 10",
          },
          {
            id: "22",
            name: "Child - 11",
          },
          {
            id: "23",
            name: "Child - 12",
          },
          {
            id: "24",
            name: "Child - 13",
          },
        ],
      },
  ];

  export const Propdata=[
    {
      id:0,
      name:'Oasis Star'
    },
    {
      id:1,
      name:'Imperial Residance',
    },
    {
      id:2,
      name:'Diamond City'
    },
    {
      id:3,
      name:'the Townhouse'
    },
    {
      id:4,
      name:'1 Lake plaza'
    }
  ]