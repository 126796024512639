import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TableWithPagination } from "../../components";
import { NetworkCall } from "../../networkcall";
import config from "../../config";
import { Signatureheading } from "../../utils/signatureUtils";
import { LoadingSection } from "../../components";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    marginTop: "-35px",
    // padding: 10
  },
  log: {
    marginTop: 40,
  },
}));

export const Signature = (props) => {
  const classes = useStyles();
  const [tableData, setTabledata] = useState({
    dataPath: [],
    rowData: [],
  });
  const [load, setLoad] = useState(false);

  // Intial Table Load
  useEffect(() => {
    setLoad(true);
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "signatures");
    Formdata.append("key", "");
    NetworkCall(
      config.api_url + "/ccc_quickfetch_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        let main = response?.data;
        let signature = main.data?.signatures_data;
        const dataRow = [];

        signature.map((row, index) => {
          let data = {};
          Object.keys(row).forEach((key) => {
            data["Sno"] = index;
            data[key] = row[key];
          });
          dataRow.push(data);
          return 0;
        });
        setTabledata({
          dataPath: Object.keys(main.data?.signatures_data[0]),
          rowData: dataRow,
        });
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Load When Signature Change
  const Load = () => {
    setLoad(true);
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "signatures");
    Formdata.append("key", "");
    NetworkCall(
      config.api_url + "/ccc_quickfetch_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        let main = response?.data;
        let signature = main.data?.signatures_data;
        const dataRow = [];

        signature.map((row, index) => {
          let data = {};
          Object.keys(row).forEach((key) => {
            data["Sno"] = index;
            data[key] = row[key];
          });
          dataRow.push(data);
          return 0;
        });
        setTabledata({
          dataPath: Object.keys(main.data?.signatures_data[0]),
          rowData: dataRow,
        });
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.root}>
      {load && <LoadingSection />}
      {!load && tableData.rowData.length > 0 ? (
        <TableWithPagination
          heading={Signatureheading}
          rows={tableData.rowData}
          path={tableData.dataPath}
          showpagination={true}
          count="2"
          showpdfbtn={false}
          showexcelbtn={false}
          showSearch={false}
          load={() => Load()}
          showSorting={false}
          dataType={[
            { type: ["number"], name: "Sno" },
            { type: ["avatar"], name: "name", imagekey: "profile" },
            { type: ["text"], name: "email" },
            { type: ["image"], name: "signature" },
          ]}
        />
      ) : null}
    </div>
  );
};
