
import { Routes } from "../router/routes"

export const breadCrumbs = [
    {
        title: "Data Tables",
        link: Routes.dataReport
    },
    {
        title: "Global Reports",
        link: '#'
    },

]

export const breadCrumbsGraph = [
    {
        title: "Graphical Report",
        link: Routes.graphicalReport
    },
    {
        title: "Global Reports",
        link: '#'
    },

]