import DownloadIcon from "@mui/icons-material/Download";
import { Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import jsPDF from "jspdf";
import "jspdf-autotable";
import React from "react";
const useStyles = makeStyles((theme) => ({
  tabletopbtn: {
    fontWeight: "lighter",
    boxShadow: "none",

    marginLeft: "6px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #E9E9E9",
    borderRadius: "6px",
    color: "black",

    "&:nth-child(1)": {
      textTransform: "capitalize",
    },
    "&:hover": {
      backgroundColor: "#F8F8F8",
      border: "1px solid #E9E9E9",
      borderRadius: "6px",
      boxShadow: "none",
    },
  },
}));
export const Pdfdownload = (props) => {
  const classes = useStyles();

  let head;

  if(props?.main){
    let header = [];
    let header2 = [];
  
    // First Header
    props.head.map((col, index) => {
      header.push({
        title: col.title,
        colSpan: col.rowSpan,
        styles: { halign: "center", fillColor: [22, 160, 133] },
      });
      return 0;
    });
  
    // Second Header
    props.heading.map((col, index) => {
      header2.push({title: col.title, dataKey:col.field });
      return 0;
    });
  
     head = [header, header2];
  }
 
 
  const downloadBigPdf = () => {
  
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [25, 10],
    });
   

    doc.autoTable({
      head: head,
      body:props.rows.map((row)=>{
        let rows = []
        Object.keys(row).forEach((key)=>{
           rows.push(row[key]);
        })
        return rows 
      })
     ,
      theme: "striped",
      styles: {
        fontSize: 15,
      },
    });
   
    doc.save("table.pdf");
  };

  const downloadPdf = () => {
    const doc = new jsPDF();
    doc.text(props.title, 20, 10);
    doc.autoTable({
      theme: "grid",
      columns: props.heading.map((col) => ({ ...col, dataKey: col.field })),
      body: props.rows,
    });
    doc.save("table.pdf");
  };

  return (
    <div className="App">
      <Button
        startIcon={<DownloadIcon />}
        variant="contained"
        fullWidth={true}
        className={classes.tabletopbtn}
        onClick={props.main ? () => downloadBigPdf() : () => downloadPdf()}
      >
        PDF&nbsp;
      </Button>
    </div>
  );
};
