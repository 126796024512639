import React from 'react';
import { ReportNavTable } from './reportNavtable';
import { withNavBars } from '../../HOCs';

class GlobalReports extends React.Component{
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <ReportNavTable {...this.props} />
    }
}

export default withNavBars(GlobalReports);