import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from "@mui/styles";
import React from 'react';
import { useHistory } from 'react-router';
import BreadCrumb from '../../components/breadcrumb';
import { Routes } from '../../router/routes';
import { breadCrumbs } from '../../utils/BreadcrumpUtil';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
    padding: 15,
    margin: 20,
    borderRadius: 12,
    boxShadow: '0px 4px 24px rgba(18, 18, 18, 0.12)'
  },
  header: {
    fontWeight: 'bold',
    fontSize: 22,
    color: theme.palette.title.color,
    marginTop: 40,
    marginBottom: 20
  },
  tablecell: {
    "& :hover": {
      backgroundColor: "lightgrey"
    },
  },
  row: {
    cursor: "pointer"
  },
  arrow: {
    color: "#545454",
    fontWeight: "lighter"
  }


}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#ECFFF6",
    fontFamily: 'adda_ccc_bold',

    //   color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'adda_ccc_regular',
    backgroundColor: "White",
    cursor: "pointer",

  },
}));






export const ReportNavTable = (props) => {

  const history = useHistory();
  
  const Report = JSON.parse(sessionStorage.getItem("global_report"))

  const onRowClick = (id,name) => {
    history.push({
      pathname: Routes.global_report_parent + id,
      state: { detail: name }
  });
    
  }
  
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BreadCrumb breadCrumbs={breadCrumbs}/>
      <Typography className={classes.header}>Reports</Typography>

      <TableContainer>
        <Table aria-label="customized table" >
          <TableHead>
            <TableRow>
              <StyledTableCell size="small" align="center">S&nbsp;No</StyledTableCell>
              <StyledTableCell align="left" sx={{ minWidth: 400 }}>Report Name</StyledTableCell>
              <StyledTableCell align="right"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Report.map((row,index) => (
              <TableRow key={row.report_key} size="small" onClick={() => onRowClick(index+1,row.report_name)} hover className={classes.row}>
                <TableCell align="center">
                  {index +1}
                </TableCell>
                <TableCell align="left">{row.report_name}</TableCell>
                <TableCell align="right"><ChevronRightIcon className={classes.arrow} /></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

}