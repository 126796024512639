import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { LoadingSection, NotFoundSection } from '../../components';
import { Message } from '../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        marginTop: "20%"
    }
}))

export const Home = ({
    showErrorPage = false
}) => {

    const classes = useStyles();

    return <div className={classes.root}>
        {showErrorPage ? <NotFoundSection message={Message.English.unAuthorizedAccess} />
            : <LoadingSection message={Message.English.navigationMessage} />}
    </div>
}