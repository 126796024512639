import React from "react";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "./routes";
import { didIAllowToAccess } from "./access";

const PrivateRoute = ({ children, ...rest }) => {

  const isAuthenticated = (router) => {
    return didIAllowToAccess()
  };

  return (
    <Route
      {...rest}
      render={(_) =>
        isAuthenticated(_) ? children : (
          <Redirect
            to={{
              pathname: Routes.home,
              state: { from: _?.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
