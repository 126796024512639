import { Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { MobileNumberInputComponent, SelectBox } from "../../components";
import TextBox from "../../components/textbox";
import { AlertContext } from "../../contexts";
import { AlertProps, Message } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: 10,
  },
}));

export const Addstaff1 = (props) => {
  const classes = useStyles();

  const { addstaff } = props;

  const alert = React.useContext(AlertContext);

  //Validate The Values
  const isIamValideToCreateTicket = () => {
    let isValid = true;
    let error = addstaff?.error;

    //Checking Community
    if (addstaff.community.length === 0) {
      isValid = false;
      error.community = Message.English.requiredMessage("Community");
    } else {
      error.community = "";
    }
    //Checking First Name
    if (addstaff.firstname.length === 0) {
      isValid = false;
      error.firstname = Message.English.requiredMessage("First Name");
    }
    //Checking Last Name
    // if (addstaff.lastname.length === 0) {
    //     isValid = false;
    //     error.lastname = Message.English.requiredMessage('Last Name')
    // }

    //Checking Email ID
    if (addstaff.email.length === 0) {
      isValid = false;
      error.email = Message.English.requiredMessage("Email ID");
    } else {
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!addstaff.email.match(validRegex)) {
        error.email= "Valid Email Address is Required";
      }

    }
    //Checking Phone Number
    if (addstaff.mobile.length === 0) {
      isValid = false;
      error.mobile = Message.English.requiredMessage("Phone Number");
    }
    //Checking Destination
    if (addstaff.destination.length === 0) {
      isValid = false;
      error.destination = "Designation is Required";
    }
    return isValid;
  };

  // When next button clicked
  const onCreateTicketBtnClicked = () => {
    if (isIamValideToCreateTicket()) {
      props.nextStep();
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "Please fill all the mandatory fields",
      });
      return false;
    }
  };

  // Get all the Properties and Construct for Options for Community
  const tempOptions = [];
  tempOptions.push({ value: "all", label: "All Properties" });
  let apt_list = JSON.parse(sessionStorage.getItem("apt_list"));
  let staff_access_to_community = JSON.parse(sessionStorage.getItem("configs_list"));
  staff_access_to_community = staff_access_to_community?.find((item) => {
    if (item?.module_name === "add_staff") return item;
  });
  apt_list = apt_list?.filter((list, index) => {
    let allowedAddasStr = staff_access_to_community?.allowed_addas;
    let allowedAddasStrArr = allowedAddasStr.split(",");
    if(allowedAddasStrArr.includes(list.apt_id)) return list;
  });
  apt_list?.map((list) => {
    tempOptions.push({ value: list.apt_id, label: list.apt_name });
    return 0;
  });
  //

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        {/* Community Select*/}
        <Grid item xs={12}>
          <SelectBox
            value={addstaff.community}
            options={tempOptions}
            label={Message.English.add_staff.community.label}
            placeholder={Message.English.add_staff.community.placeholder}
            onChange={(value) => props.handleAddstaff("community", value)}
            isMulti={true}
            isError={addstaff?.error.community.length > 0}
            errorMessage={addstaff?.error.community}
            isCheckbox
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            allowSelectAll={true}
            isRequired
          />
        </Grid>
        {/* First Name field*/}
        <Grid item xs={12} sm={6} lg={6}>
          <TextBox
            value={addstaff?.firstname}
            onChange={(e) => props.handleAddstaff("firstname", e.target.value)}
            label={Message.English.add_staff.firstname.label}
            placeholder={Message.English.add_staff.firstname.placeholder}
            isError={addstaff?.error.firstname.length > 0}
            errorMessage={addstaff?.error.firstname}
            isRequired
          />
        </Grid>
        {/* Last Name field*/}
        <Grid item xs={12} sm={6} lg={6}>
          <TextBox
            value={addstaff?.lastname}
            onChange={(e) => props.handleAddstaff("lastname", e.target.value)}
            label={Message.English.add_staff.lastname.label}
            placeholder={Message.English.add_staff.lastname.placeholder}
            isError={addstaff?.error.lastname.length > 0}
            errorMessage={addstaff?.error.lastname}
          />
        </Grid>
        {/*Mobile field*/}
        <Grid item xs={12} sm={6} lg={6}>
          <MobileNumberInputComponent
            label={Message.English.add_staff.mobile.label}
            placeholder={Message.English.add_staff.mobile.placeholder}
            value={addstaff?.mobile}
            handleChange={(value) => props.handleAddstaff("mobile", value)}
            isError={addstaff?.error.mobile.length > 0}
            errorMessage={addstaff?.error.mobile}
            isRequired
          />
        </Grid>
        {/* Email ID field*/}
        <Grid item xs={12} sm={6} lg={6}>
          <TextBox
            value={addstaff?.email}
            onChange={(e) => props.handleAddstaff("email", e.target.value)}
            label={Message.English.add_staff.email.label}
            placeholder={Message.English.add_staff.email.placeholder}
            isError={addstaff?.error.email.length > 0}
            errorMessage={addstaff?.error.email}
            isRequired
          />
        </Grid>
        {/* Designation field*/}
        <Grid item xs={12} sm={6} lg={6}>
          <TextBox
            value={addstaff?.destination}
            onChange={(e) =>
              props.handleAddstaff("destination", e.target.value)
            }
            label={Message.English.add_staff.destination.label}
            placeholder={Message.English.add_staff.destination.placeholder}
            isError={addstaff?.error.destination.length > 0}
            errorMessage={addstaff?.error.destination}
            isRequired
          />
        </Grid>
        <Grid item xs={0} sm={6} lg={6} />
        {/* Submit */}

        <Grid item xs={4} lg={1.5}>
          <br />
          <br />
          <Button
            fullWidth={true}
            onClick={onCreateTicketBtnClicked}
            size="large"
            variant="contained"
            color="success"
            //onClick={handleNext}
          >
            &nbsp;Next&nbsp;
          </Button>
          <br />
          <br />
          <br />
        </Grid>
      </Grid>
    </div>
  );
};
