import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
import { AlertTitle } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({

alertWidth:{
  minWidth: 504 ,
  [theme.breakpoints.down('sm')]: {
    minWidth: 204 
  },
}

}));

//MuiAlert component which is shown inside the Snackbar component

export const Alerts = (props) => {

  const [open, setOpen] = React.useState(props.open);

const classes = useStyles()
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.onclose();
    setOpen(false);
  };

  return (
    <Snackbar
      id="main_alert_snackbar"
      anchorOrigin={{
        vertical: props.vertical,
        horizontal: props.horizontal,
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
      style={{
        border: props.severity === "success" ? '2px solid #22D180' : props.severity === "error" ? '2px solid #E82828' : null,
        borderRadius: 8,
        boxShadow: '3px 4px 24px rgba(0, 0, 0, 0.25)',
      }}
    >
      <MuiAlert
        id="main_alert"
        severity={props.severity}

        icon={
          props.severity === "success" ?
            <img src="/images/icons/Success.svg" alt="success"></img> :
            props.severity === "error" ?
              <img src="/images/icons/Error.svg" alt="error"></img> :
              null
        }
       className={classes.alertWidth}
        action={
          <ClearIcon style={{ marginTop: 12, cursor: "pointer" }} onClick={handleClose} />
        }
      >
        <AlertTitle style={{ fontFamily: "adda_ccc_semi_bold" }}>{props.severity === "success" ? "Success" : props.severity === "error" ? "Error" : props.severity === 'warning' ? "Warning" : props.severity === "info" ? "Info" : null}</AlertTitle>
        {props.msg}
      </MuiAlert>
    </Snackbar>
  );
};
