import React from "react";
import { DataReport } from './dataReport';
import { withNavBars } from "../../HOCs";

class DataReportParent extends React.Component {
  render() {
    return <DataReport />;
  }
}

export default withNavBars(DataReportParent);
