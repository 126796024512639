import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, CircularProgress } from '@mui/material';

export const LoadingSection = (props) => {

  const {
    message,
    top
  } = props;

  return <div>
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ marginTop: top }}
    >
      <Grid item>
        <CircularProgress />
      </Grid>
      <Grid item>
        <Typography variant="h6" align="center">
          {message}
        </Typography>
      </Grid>
    </Grid>
  </div>
}

LoadingSection.propTypes = {
  message: PropTypes.string,
  top: PropTypes.string
}

LoadingSection.defaultProps = {
  message: 'Loading...',
  top: '30vh'
}

