import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
const useStyles = makeStyles((theme) => ({

    title: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",

    },
    caption: {


    },

}));
const ParaTitle = (props) => {
    const classes = useStyles(props);


    const getTitle = (props) => {
        return (
            <>
                <Typography variant="h5" className={classes.title} gutterBottom >
                    {props.title}
                </Typography>
            </>
        );
    };
    return (
        <div className={classes.root}>
            <>
                {getTitle(props)}
                <Typography variant="subtitle1" color="textSecondary" className={classes.caption}>
                    {props.caption}
                </Typography>
            </>
        </div>
    );
};
ParaTitle.propTypes = {
    title: PropTypes.string,
    caption: PropTypes.string,
    id: PropTypes.string,
    isReadonly: PropTypes.bool,
    
};
ParaTitle.defaultProps = {
    title: "Configure",
    caption: "In the Central Command Center, do you want your attention to be drawn to certain data whose values are greater than or below a certain threshold? You can configure those values here.",
};

export default ParaTitle;
