import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { Button, Grid, Typography } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { ModuleTree } from "../../components/filterTree/moduleTree";
import { NetworkCall } from "../../networkcall";
import config from "../../config";
import { AlertContext, DialogContext } from "../../contexts";
import { AlertProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  details: {
    border: "none",
    borderRadius: "12px",
  },
  title: {
    fontWeight: 600,
    padding: "8px 8px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 0,
    backgroundColor: theme.palette.background.paper,
    left: 0,
    right: 0,
  },
  all: {
    position: "absolute",
    right: 12,
    textDecoration: "underline",
  },
  access: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

// Customised Accordian
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "1px solid #E8E8E8",
  borderRadius: "12px",
  "&:before": {
    display: "none",
  },
  "& .css-ta84vm-MuiAccordionDetails-root": {
    borderTop: "none",
    borderBottom: "none",
  },
}));
// Customised Accordian Summary
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#F5FFF7",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    borderTop: "none",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    borderTop: "none",
  },
  borderRadius: "12px",
}));
// Customised Accordian Details
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(1),
  marginTop: "-10px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  backgroundColor: "#F5FFF7",
}));

// NewState for Saving
const NewState = {
  communities: "",
  first_name: "",
  last_name: "",
  isd: "",
  mobile: "",
  email_id: "",
  designation: "",
  modules: [
    {
      units_users: "",
    },
  ],
};

export const Addstaff2 = (props) => {
  //Props from Index
  const { initialData, Mselected, checked, arr } = props;
  //

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState("panel1");

  const alert = React.useContext(AlertContext);
  const dialog = React.useContext(DialogContext);

  //handle Accordian
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  //

  //Adding New Staff
  const Addstaff = () => {
    if (Mselected.length > 0) {
      var Formdata = new FormData();
      let auth = {
        owner_id: localStorage.getItem("owner_id"),
        auth_key: localStorage.getItem("auth_token"),
      };

      let vals = [];
      initialData?.community?.map((items) => {
        vals.push(items.value);
        return 0;
      });
      NewState.communities = vals.toString();
      NewState.first_name = initialData.firstname;
      NewState.last_name = initialData.lastname;
      NewState.email_id = initialData.email;
      NewState.designation = initialData.destination;
      NewState.isd = initialData.mobile.mobile_code;
      NewState.mobile = initialData.mobile.mobile;

      NewState.modules.forEach((key) => {
        key.units_users = Mselected.toString();
      });

      const action_items = NewState;

      Formdata.append("auth", JSON.stringify(auth));
      Formdata.append("case", "add_staff");
      Formdata.append("action_items", JSON.stringify(action_items));
      NetworkCall(
        config.api_url + "/ccc_quickactions_ver1",
        "post",
        Formdata,
        null,
        false
      )
        .then((response) => {
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: "Staff Added and informed successfully",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
          dialog.setDialog({ ...dialog, open: false });
          props.onMain();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      alert.setSnack({
        ...alert,
        open: true,
        severity: AlertProps.severity.warning,
        msg: "You can't add without providing any access",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.center,
      });
    }
  };
  //

  return (
    <div>
      <Grid container justifyContent="center" spacing={3}>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <div className={classes.access}>
                <Typography variant="body1" className={classes.title}>
                  Admin App Access
                </Typography>
                <Typography
                  className={classes.all}
                  onClick={(e) => props.onAll(e)}
                >
                  Select All Access
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <ModuleTree
                data={arr.length ? arr : []}
                onChange={props.handleModuleSelect}
                OnOnly={props.OnOnly}
                MData={Mselected}
                checked={checked}
              />
            </AccordionDetails>
          </Accordion>
          <br />
        </Grid>
      </Grid>
      <div className={classes.button}>
        <Button
          variant="outlined"
          color="success"
          style={{ margin: 7 }}
          onClick={props.back}
        >
          Go Back
        </Button>
        <Button
          variant="contained"
          color="success"
          style={{ margin: 7 }}
          onClick={() => Addstaff()}
        >
          Add Staff
        </Button>
      </div>
    </div>
  );
};
