import DateAdapter from "@mui/lab/AdapterDateFns";
// import DatePicker from '@mui/lab/DatePicker';
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { TextField, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  datepicker: {
    border: "none",
  },
  textbox: {
    [`& fieldset`]: {
      borderRadius: 7,
      height: 50,
      border: "1.5px solid #E2E2E2",
      boxShadow: "none",
    },
  },
}));

const DateTime = (props) => {
  const classes = useStyles(props);

  const getTitle = (props) => {
    return (
      <div style={{ display: "flex" }}>
        <Typography
          variant="body1"
          style={{ fontSize: "12px", color: "textsecondary" }}
          gutterBottom
        >
          {props.title}{" "}
        </Typography>
        {props.isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>
    );
  };

  return (
    <>
      {getTitle(props)}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <Stack spacing={3}>
          <DesktopDatePicker
            className={classes.datepicker}
            label=""
            openTo={props.minDate ? "day" : "year"}
            views={["year", "month", "day"]}
            value={props.value}
            minDate={props.minDate}
            disablePast={props.disablePast}
            onChange={(newValue) => {
              props.onChange(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, placeholder: "DD/MM/YYYY" }}
              />
            )}
            inputFormat="dd/MM/yyyy"
          />
        </Stack>
        {props.isError && (
          <Typography variant={"caption"} color={"error"}>
            {props.errorMessage}
          </Typography>
        )}
      </LocalizationProvider>
    </>
  );
};
export default DateTime;
