import { Routes } from "../router/routes"
export const globalReportpath = ["id","propertyName","invoiced","collected","outstanding","collectedpercent"]

export const globalReportrow = [
    {
        id: "1",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "2",
        propertyName: "Diamond State",
        invoiced: 12050122,
        collected: 12050123,
        outstanding: 12050124,
        collectedpercent: 12,
      },
      {
        id: "3",
        propertyName: "Blue City",
        invoiced: 12050122,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "4",
        propertyName: "Green City",
        invoiced: 12050122,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 0,
      },
      {
        id: "5",
        propertyName: "Yellow City",
        invoiced: 12050122,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "6",
        propertyName: "Red City",
        invoiced: 12050122,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "7",
        propertyName: "Purple City",
        invoiced: 12050122,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "8",
        propertyName: "India City",
        invoiced: 12050122,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "9",
        propertyName: "England City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 0,
        
      },
      {
        id: "10",
        propertyName: "US City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "11",
        propertyName: "Italy City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "12",
        propertyName: "Spain City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "13",
        propertyName: "France City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "14",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 0,
      },
      {
        id: "15",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "16",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "17",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "18",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
      {
        id: "19",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 0,
      },
      {
        id: "20",
        propertyName: "Diamond City",
        invoiced: 12050120,
        collected: 12050120,
        outstanding: 12050120,
        collectedpercent: 12,
      },
  
]
export const globalReportheading = [
   
  { title: "S No", field: "id", type: "numeric" },
  { title: "Property Name", field: "propertyName", },
  { title: "Invoiced", field: "invoiced", },
  { title: "Collected", field: "collected", type: "numeric" },
  { title: "Outstanding", field: 'outstanding', type: "currency" },
  { title: "% Collected", field: 'collectedpercent', type: "currency" }
   
]
export const breadCrumbsGlobal = [
    {
        title: "Data Tables",
        link: Routes.dataReport
    },
    {
        title: "Global Reports",
        link: Routes.global_reports
    },
   

]
export const breadCrumbsGlobalGraph = [
  {
      title: "Graphical Report",
      link: Routes.graphicalReport
  },
  {
      title: "Global Reports",
      link: Routes.global_reports
  },
 

]


        