import { Grid, Hidden, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  ChartsWrapper,
  DashboardCard,
  TabsComponent,
  TodoCardParent,
} from "../../components";
import { Routes } from "../../router/routes";
import { LocalStorageKeys } from "../../utils";
import { useEffect } from "react";
import { NetworkCall } from "../../networkcall";
import { LoadingSection, InternalServerError } from "../../components";
import config from "../../config";


const useStyles = makeStyles((theme) => ({
  root: {
   
  },
  gridcontainer: {},
  dashboardcard: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    padding: theme.spacing(2),
  },
  todocard: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    },
    padding: theme.spacing(2),
  },
  chartsgrid: {
    padding: theme.spacing(0.5),
  },
  footer: {
    marginTop: 20,
    height: "35.31px",
    display: "flex",
    justifyContent: "space-between",
    bottom: 0,
    background: theme.palette.background.paper,
  },
  footerText: {
    padding: 5,
    display: "flex",
    alignItems: "center",
  },
  footerText1: {
    fontFamily: "adda_ccc_regular",
    fontSize: "13.12px",
    marginRight: 5,
    marginTop: 2,
  },
  footerText2: {
    fontFamily: "adda_ccc_regular",
    fontSize: "15.31px",
  },
}));

export const GraphicalReport = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [gData, setData] = React.useState({
    dashData: [],
    stackData: [],
    TodoData: [],
    tickData: [],
    vendorData: [],
    serviceData: [],
    payData: [],
  });
  const [loading, setLoader] = React.useState(false);
  const [error, setError] = React.useState(false);

    
  const arrOfObjToArrOfArr = (datas, key) => {
    if (key === "escalated") {
      let dateData = [];
      let result = [];
      datas.map((i) => {
        dateData.push({
          date:i?.end_date,
          Tickets: i?.tickets_count,
        });
        return 0;
      });
      result.push(Object.keys(dateData[0]));
      dateData.forEach((data) => {
        result.push(Object.values(data));
      });
      return result;
    }
    if(key === 'service'){
      let dateData = [];
      let result = [];
      datas.map((i) => {
        dateData.push({
          type:`${i?.type}`,
          expense:i?.expense
        });
        return 0;
      });
      result.push(Object.keys(dateData[0]));
      dateData.forEach((data) => {
        result.push(Object.values(data));
      });
      return result;
    }
    let result = [];
    //to get the keys
    result.push(Object.keys(datas[0]));
    //to get the values
    datas.map((data) => {
      result.push(Object.values(data));
      return 0;
    });
    return result;
  };
  
  // Initial
  useEffect(() => {
    setLoader(true);
    if (localStorage.getItem("owner_id") === null) {
      setTimeout(() => {
        networkCAllForReport();
      }, 2000);
    } else {
      networkCAllForReport();
    }

  }, []);

  const networkCAllForReport = () => {
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    NetworkCall(
        config.api_url + "/ccc_graphical_report_ver1",
        "post",
        Formdata,
        null,
        false
    )
        .then((response) => {
          var main = response?.data;
          let properties = main?.data?.collection_summary_report?.property_wise;
          let escalated = main?.data?.escalated_tickets?.requests;
          let vendor = main?.data?.vendor_expense_comparision?.expenses;
          let serviceVendor = main?.data?.service_expense_comparision.expenses;
          let payment = main?.data?.payment_method_comparision.payments;
          let Todo = main?.data?.my_todos;
          const propertiesResult = properties.length >0 ? arrOfObjToArrOfArr(properties) : [];
          const escalatedResult =  escalated.length >0 ? arrOfObjToArrOfArr(escalated, "escalated") :[];
          const vendorResult = vendor.length > 0 ? arrOfObjToArrOfArr(vendor) : [] ;
          const serviceResult = serviceVendor.length > 0 ? arrOfObjToArrOfArr(serviceVendor,"service") : [];
          const paymentResult = payment.length > 0 ? arrOfObjToArrOfArr(payment) : [];

          let stackedChartStart = [
            {
              id: main?.data?.collection_summary_report?.id,
              title: main?.data?.collection_summary_report?.title,
              activeFilterText: main?.data?.collection_summary_report?.sub_title,
              subTitle: [
                {
                  id: 0,
                  subHead: "Total Billing",
                  value: (main?.data?.collection_summary_report?.total_billing)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                },
                {
                  id: 1,
                  subHead: "Total Received",
                  value: (main?.data?.collection_summary_report?.total_received)
                      .toFixed(2)
                      .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                },
                {
                  id: 2,
                  subHead: "Total Outstanding",
                  value:
                      (main?.data?.collection_summary_report?.total_outstanding)
                          .toFixed(2)
                          .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
                },
              ],
              data: propertiesResult,
              chartType: "BarChart",
              vTitle:"Properties",
              hTitle:`Amount (in ${JSON.parse(sessionStorage.getItem('currency'))})`
            },
          ];

          let tickChart = [
            {
              id: main?.data?.escalated_tickets?.id,
              title: main?.data?.escalated_tickets?.title,
              activeFilterText: main?.data?.escalated_tickets?.sub_title,
              data: escalatedResult,
              chartType: "AreaChart",
              vTitle:"Number of Requests",
              hTitle:"Week"
            },
          ];

          let vendorChart = [
            {
              id: main?.data?.vendor_expense_comparision?.id,
              title: main?.data?.vendor_expense_comparision?.title,
              activeFilterText: main?.data?.vendor_expense_comparision?.sub_title,
              data: vendorResult,
              chartType: "ColumnChart",
              vTitle:`Amount (in ${JSON.parse(sessionStorage.getItem('currency'))})`,
              hTitle:"Vendors"
            },
          ];

          let serviceChart = [
            {
              id: main?.data?.service_expense_comparision.id,
              title: main?.data?.service_expense_comparision.title,
              activeFilterText: main?.data?.service_expense_comparision.sub_title,
              data: serviceResult,
              chartType: "PieChart",
            },
          ];

          let payChart = [
            {
              id: main?.data?.payment_method_comparision.id,
              title: main?.data?.payment_method_comparision.title,
              activeFilterText: main?.data?.payment_method_comparision.sub_title,
              data: paymentResult,
              chartType: "LineChart",
              vTitle:`No. of Transactions`,
              // hTitle:"Month"
            },
          ];

          setData({
            dashData: main?.data?.statistics,
            stackData: stackedChartStart,
            tickData: tickChart,
            vendorData: vendorChart,
            serviceData: serviceChart,
            payData: payChart,
            TodoData: Todo,
          });
          setLoader(false);
          setError(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          setError(true);
        });
  }


  //Tabs Handling
  const onTab2Clicked = () => {
    history.push(Routes.dataReport);
  };
 
  //Server Error Retry
  const Retry = () => {
    window.location.reload();
  };

  //On Dropdown Update 
  const onUpdate = (id, data) => {
    let vals = [];
    data?.map((items) => {
      vals.push(items.value);
      return 0;
    });
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("chart", id);
    Formdata.append("properties", vals.toString());
    NetworkCall(
      config.api_url + "/ccc_fetchgraph_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        let main = response.data.data;
        if (main) {
          if (main[id].id === "vendor_expense_comparision") {
            let chart = main[id]?.expenses;
            const chartResult = (chart.length > 0 ) ? arrOfObjToArrOfArr(chart) : [];
            const mainChart = [
              {
                id: main[id]?.id,
                title: main[id]?.title,
                activeFilterText: gData.vendorData.map(k => {return k.activeFilterText}),
                data: chartResult,
                chartType: "ColumnChart",
                vTitle:`Amount (in ${JSON.parse(sessionStorage.getItem('currency'))})`,
                hTitle:"Vendors"
              },
            ];
            setData({
              dashData: gData.dashData,
              stackData: gData.stackData,
              tickData: gData.tickData,
              vendorData: mainChart,
              serviceData: gData.serviceData,
              payData: gData.payData,
              TodoData: gData.TodoData,
            });
          } else if (main[id].id === "service_expense_comparision") {
            let chart = main[id]?.expenses;
            const chartResult = (chart.length > 0 ) ? arrOfObjToArrOfArr(chart,"service") : [];
            const mainChart = [
              {
                id: main[id]?.id,
                title: main[id]?.title,
                activeFilterText: gData.serviceData.map(k => {return k.activeFilterText}),
                data: chartResult,
                chartType: "PieChart",
              },
            ];
            setData({
              dashData: gData.dashData,
              stackData: gData.stackData,
              tickData: gData.tickData,
              vendorData: gData.vendorData,
              serviceData: mainChart,
              payData: gData.payData,
              TodoData: gData.TodoData,
            });
          } else if (main[id].id === "payment_method_comparision") {
            let chart = main[id]?.payments;
            const chartResult = (chart.length > 0 ) ? arrOfObjToArrOfArr(chart) : [];
            const mainChart = [
              {
                id: main[id]?.id,
                title: main[id]?.title,
                activeFilterText: gData.payData.map(k => {return k.activeFilterText}),
                data: chartResult,
                chartType: "LineChart",
                vTitle:`No. of Transactions`,
              },
            ];
            setData({
              dashData: gData.dashData,
              stackData: gData.stackData,
              tickData: gData.tickData,
              vendorData: gData.vendorData,
              serviceData: gData.serviceData,
              payData: mainChart,
              TodoData: gData.TodoData,
            });
          } else if (main[id].id === "escalated_tickets") {
            let chart = main[id]?.tickets;
            const chartResult = (chart.length > 0 ) ? arrOfObjToArrOfArr(chart, "escalated") : [];
            const tickChart = [
              {
                id: main[id]?.id,
                title: main[id]?.title,
                activeFilterText: gData.tickData.map(k => {return k.activeFilterText}),
                data: chartResult,
                chartType: "AreaChart",
                vTitle:"Number of Request",
                hTitle:"Week"
              },
            ];
            setData({
              dashData: gData.dashData,
              stackData: gData.stackData,
              tickData: tickChart,
              vendorData: gData.vendorData,
              serviceData: gData.serviceData,
              payData: gData.payData,
              TodoData: gData.TodoData,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.root}>
      <TabsComponent
        selectedTab={0}
        showButtonSection={false}
        onTab2Clicked={() => onTab2Clicked()}
      />
      {loading && <LoadingSection />}
      {error && (
        <InternalServerError onRetry={Retry} message="Internal Server Error" />
      )}
      <Hidden smDown>
        <Grid container className={classes.gridcontainer}>
          <Grid item xs={12} md={8} lg={8} className={classes.dashboardcard}>
            {/* dashboard card */}
            <Grid container spacing={3}>
              {Object.keys(gData.dashData).map((item, index) => {
                return (
                  <Grid item xs={12} sm={3} md={3} lg={3} key={item}>
                    <DashboardCard
                      data={{ key: item, value: gData.dashData[item] }}
                    />
                  </Grid>
                );
              })}

              {gData.stackData.map((charts) => {
                return (
                  <Grid item xs>
                    <ChartsWrapper {...charts} propertyBtn={false} height={250}/>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          {/* todo list */}
          <Grid item xs={12} sm={12} md={4} lg={4} className={classes.todocard}>
              <TodoCardParent data={gData?.TodoData} />    
          </Grid>
        </Grid>
      </Hidden>

      <Hidden smUp>
        <Grid container className={classes.gridcontainer}>
          <Grid
            item
            container
            spacing={2}
            xs={12}
            md={8}
            lg={8}
            className={classes.dashboardcard}
          >
            {Object.keys(gData?.dashData).map((item, index) => {
              return (
                <Grid item xs={12} sm={3} md={3} lg={3} key={index}>
                  <DashboardCard
                    data={{ key: item, value: gData.dashData[item] }}
                  />
                </Grid>
              );
            })}
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} className={classes.todocard}>
            {gData?.TodoData.length >0 &&
              <TodoCardParent data={gData?.TodoData} />
            }  
          </Grid>

          <Grid item xs={12} md={8} lg={8} className={classes.dashboardcard}>
            {gData?.stackData.map((charts) => {
              return (
                <Grid item xs>
                  <ChartsWrapper {...charts} propertyBtn={false} height={250} />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Hidden>

      {/* charts */}
      <Grid container spacing={2} className={classes.chartsgrid}>
        {gData?.tickData?.map((charts) => {
          return (
            <Grid item xs={12} md={6} lg={6}>
              <ChartsWrapper
                {...charts}
                propertyBtn={true}
                isBig={false}
                heightIncrease={true}
                onUpdate={onUpdate}
                height={300}
              />
            </Grid>
          );
        })}

        {gData?.serviceData?.map((charts) => {
          return (
            <Grid item xs={12} md={6} lg={6}>
              <ChartsWrapper
                {...charts}
                propertyBtn={true}
                isBig={false}
                heightIncrease={true}
                onUpdate={onUpdate}
                height={300}
              />
            </Grid>
          );
        })}

        {gData?.vendorData?.map((charts) => {
          return (
            <Grid item xs={12} md={6} lg={12}>
              <ChartsWrapper
                {...charts}
                propertyBtn={true}
                isBig={true}
                heightIncrease={true}
                onUpdate={onUpdate}
                height={250}
              />
            </Grid>
          );
        })}

        {gData?.payData?.map((charts) => {
          return (
            <Grid item xs={12} md={6} lg={12}>
              <ChartsWrapper
                {...charts}
                propertyBtn={true}
                isBig={true}
                heightIncrease={true}
                onUpdate={onUpdate}
                height={250}
              />
            </Grid>
          );
        })}
      </Grid>

      {/* Footer */}
      {gData.tickData.length > 0 && (
        <div className={classes.footer}>
          <Typography className={classes.footerText}>{`v${localStorage.getItem(LocalStorageKeys.version)}`}</Typography>
          <div className={classes.footerText}>
            <Typography className={classes.footerText1}>Powered by</Typography>
            <Typography className={classes.footerText2}>
              3Five8 Technologies
            </Typography>
          </div>
        </div>
       )}
    </div>
  );
};
