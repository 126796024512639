//Normal messagess
export let Message = {
  'English': {
    requiredMessage: (field) => `${field ? field : "This field"} is required`,
    loading: "Loading...",
    unAuthorizedAccess: "404 - Unauthorized Access",
    navigationMessage: "Redirecting.... Please wait....",
    announcement: {
      title: "Create Announcement",
      communities: {
        label: "Community",
        placeholder: "Select Community"
      },
      recipients: {
        label: "Recipients",
        placeholder: "Select Recipients"
      },
      CheckBoxLabel: {
        label: "Include Managers",
      },
      reply_to_emailid: {
        label: "Reply to  email",
        placeholder: "Enter Email Id"
      },
      announcement_title: {
        label: "Announcement Title",
        placeholder: "Enter Title"
      },
      announcement_description: {
        label: "Brief Description",
        placeholder: "Enter Announcement Description here..."
      },
      expiry_date: {
        title: "Expiry date"
      },
      uploadLabel: {
        label: "Attachment (10 MB Maximum)",
      },

    },
    create_ticket: {
      title: "Create Request",
      community: {
        label: "Community",
        placeholder: "Select Community"
      },
      issue: {
        label: "Write Request",
        placeholder: "Enter Request details"
      },
      unit: {
        label: "Unit",
        placeholder: "Select Unit"
      },
      category: {
        label: "Category",
        placeholder: "Select Category"
      },
      subCategory: {
        label: "Sub-Category",
        placeholder: "Select Sub-Category"
      },
      ticketType: {
        label: "Request Type",
        placeholder: "Select Request Type"
      },
      uploadLabel: {
        label: "Attachment (10MB Maximum)",
      },
      CheckBoxLabel: {
        label: "Mark as Urgent ",
      },
    },
    add_staff: {
      title: "Add Staff",
      community: {
        label: "Community",
        placeholder: "Select Community"
      },
      firstname: {
        label: "First Name",
        placeholder: "Enter First Name"
      },
      lastname: {
        label: "Last Name",
        placeholder: "Enter Last Name"
      },
      email: {
        label: "Email ID",
        placeholder: "Enter Email ID"
      },
      mobile: {
        label: "Phone Number",
        placeholder: "Enter Phone Number"
      },
      destination: {
        label: "Designation",
        placeholder: "Enter Designation"
      },

    },
    save_mollak: {
      title: "Configure Mollak"
    },
    save_signature: {
      title: "Manage Signature"
    },
    remove_staff: {
      title: "Remove Staff"
    }
  }
};
