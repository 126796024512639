

const stickyEleSettings = {
    position: "sticky",
    left: 0,
    zIndex: 5,
    textAlign: "left",
    minWidth: 200,
    border: "none",
}


const commonBorder = 12;

const Styles = {
    container: {
        maxHeight: 640,
        width: '100%',
        
        overflowX: "auto",
        overflowY: "auto",
        borderRadius: commonBorder
    },
    root: {
        width: '100%',
        borderRadius: commonBorder
    },
    badRating:{
        '& .MuiRating-iconFilled': {
            color: '#EA5053',
          },
    },
    mediumRating:{
        '& .MuiRating-iconFilled': {
            color: '#F7B837',
          },
    },
    goodRating:{
        '& .MuiRating-iconFilled': {
            color: '#5BA712',
          },
    },
  
    table: {
        border: 'solid 1px #C4C4C4',
        borderRadius: commonBorder,
        "& .MuiTableHead-root": {
            // table head -- HEADER 
            "& .MuiTableRow-root:nth-child(1) th": {
                fontSize: "17px",
                fontWeight: 600,
                backgroundColor: "#F5F5F5 !important",
                "&:first-child": {
                    color: '#659374',
                    backgroundColor: '#fff !important',
                    border: 'none',
                    borderRight: 'none !important',
                   
                    borderTopLeftRadius: commonBorder,
                    ...stickyEleSettings
                },
                "&:last-child": {
                    borderTopRightRadius: commonBorder,
                },
        
            },
            // table head -- SUBHEADER 
            "& .MuiTableRow-root:nth-child(2) th": {
                fontSize: "15px",
                fontWeight: 500,
                color: "#000000",
                backgroundColor: "#FFF4E3",
                userSelect: "none",
            },
            "& .MuiTableCell-root": {
                borderBottom: "solid 1px #C4C4C4",
                fontWeight: 500,
            
            }
        },
        "& .MuiTableBody-root tr": {
            // table body - TOP HEADER
            "& td:first-child": {
                fontSize: 15,
                fontWeight: 600,
                backgroundColor: "#F3FAEE !important",
                position: "sticky",
                left: 0,
                textAlign: "left",
                cursor:"pointer",
                minWidth: 200,
                border: "none",
                zIndex:1
                // ...stickyEleSettings,
            },
            // table body - EVEN ROWS
            "&:nth-child(even)": {
                backgroundColor: '#F9FFF5'
            },
            /// table body - LAST ROW ALL CELLS
            "&:last-child td": {

                fontSize: 15,
                fontWeight: 600,
                backgroundColor: '#CE9F66 !important',
                // position: 'sticky',
                // bottom: 0,
                // zIndex: 4,
                "&:first-child": {
                    // zIndex: '5 !important',
                    borderBottomLeftRadius: commonBorder
                },
                "&:last-child": {
                    borderBottomRightRadius: commonBorder,
                }
            },
            "& .MuiTableCell-root": {
                borderBottom: "solid 1px #C4C4C4",
                fontWeight: 500
            }
        },
        "& .MuiPaper-root": {
            borderRadius: '8px !important',
        },
        "& .borderRight": {
            borderRight: "solid 1px #C4C4C4"
        },
        "& .css-1hfqcgs-MuiTableCell-root": {
            padding: "2px"
        }
    },
    highlightedText: {
        borderRadius: 1,
        color: "#FF0000",
        backgroundColor: "#FFE8E8",
        fontWeight: 600,
        minWidth: 70
    },
    nonhighlightedText: {
        borderRadius: 1,
        fontWeight: 600,
        minWidth: 70
    },
    sort:{
        backgroundRepeat : "no-repeat",
        backgroundPosition  : "center right",
        backgroundSize:'20px',
        "&.both":{
            backgroundImage : "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAQAAADYWf5HAAAAkElEQVQoz7XQMQ5AQBCF4dWQSJxC5wwax1Cq1e7BAdxD5SL+Tq/QCM1oNiJidwox0355mXnG/DrEtIQ6azioNZQxI0ykPhTQIwhCR+BmBYtlK7kLJYwWCcJA9M4qdrZrd8pPjZWPtOqdRQy320YSV17OatFC4euts6z39GYMKRPCTKY9UnPQ6P+GtMRfGtPnBCiqhAeJPmkqAAAAAElFTkSuQmCC)",
            "&:after":{
                color : 'yellow'
              }
        },
        "&.asc":{
            backgroundImage : "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZ0lEQVQ4y2NgGLKgquEuFxBPAGI2ahhWCsS/gDibUoO0gPgxEP8H4ttArEyuQYxAPBdqEAxPBImTY5gjEL9DM+wTENuQahAvEO9DMwiGdwAxOymGJQLxTyD+jgWDxCMZRsEoGAVoAADeemwtPcZI2wAAAABJRU5ErkJggg==)",
        },
        "&.desc":{
            backgroundImage : "url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAAZUlEQVQ4y2NgGAWjYBSggaqGu5FA/BOIv2PBIPFEUgxjB+IdQPwfC94HxLykus4GiD+hGfQOiB3J8SojEE9EM2wuSJzcsFMG4ttQgx4DsRalkZENxL+AuJQaMcsGxBOAmGvopk8AVz1sLZgg0bsAAAAASUVORK5CYII=)",
        }
    }
}

export default Styles;