import Excel from "exceljs";
import saveAs from "file-saver";
var aplha = [
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
  "AA",
  "AB",
  "AC",
  "AD",
  "AE",
  "AF",
  "AG",
  "AH",
  "AI",
  "AJ",
  "AK",
  "AL",
  "AM",
  "AN",
  "AO",
  "AP",
  "AQ",
  "AR",
  "AS",
  "AT",
  "AU",
  "AV",
  "AW",
  "AX",
  "AY",
  "AZ",
];
const makeExcel = (location, sheet_name) => {
  return new Promise((resolve, reject) => {
    try {
      const options = {
        filename: location,
        useStyles: true,
        useSharedStrings: true,
      };
      const workbook = new Excel.Workbook(options);
      let workSheet = workbook.addWorksheet(sheet_name, {
        properties: { defaultColWidth: 20 },
      });
      console.log("File has been created successfully!");
      return resolve({ workbook, workSheet });
    } catch (error) {
      reject({
        status: false,
        message: error,
      });
    }
  });
};

const writeHeader = async (workSheet, headers) => {
  if (headers.length > 0) {
    const cols = headers.map((headerName) => {
      return { header: headerName.title, key: headerName.field, width: 20 };
    });
    workSheet.columns = cols;
  }
};

const writeHead = async (workSheet, head) => {
  if (head.length > 0) {
    const cols = head.map((headerName) => {
      return { header: headerName.title };
    });
    workSheet.columns = cols;
  }
};

const WriteRows = async (workSheet, data) => {
  await data.map((row) => {
   workSheet.addRow(row).commit();
    return 0;
  });
};

const writeTable = async (workSheet, head, headers, data) => {
  workSheet.getCell("A1").value = head[0].title;
  workSheet.getRow(1).font = { bold: true, size: 11 };
  workSheet.getColumn("A").font = { bold: true, size: 11, color: "blue" };
  workSheet.getRow(1).alignment = {
    wrapText: true,
    vertical: "middle",
    horizontal: "center",
  };
 
  await head.map((val, i) => {
    if (i > 0) {
      let index_val = 1;
      let col_address = workSheet._rows[0]._cells[workSheet._rows[0]._cells.length - 1]._address;
      if (col_address.length > 2) {
        index_val = 2;
      }
      let mergInt = aplha.indexOf(
          col_address.substring(0, index_val)
      );

      let colIndex = aplha
        .slice(mergInt < 0 ? 0 : mergInt + 1)
        .filter((_, i) => i < val.rowSpan);
    

        let StartCell = `${colIndex.shift()}`;
        let EndCell = `${colIndex.pop()}`;

      if (val.rowSpan > 1) {
        workSheet.mergeCells(`${StartCell}1 : ${EndCell}2`);
      } else {
        workSheet.mergeCells(`${StartCell}1 : ${StartCell}2`);
      }
      workSheet.getCell(`${StartCell}1`).value = val.title;
    }
    return 0;
  });


  await workSheet.mergeCells('A1:A2')
 
  await workSheet.columns.forEach((column) => {
    column.border = {
      top: { style: "thin" },
      left: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    };
  });
  await workSheet.addRow(headers);
  await data.map((row) => {
      workSheet.addRow(row).commit();
    return 0;
  });
  workSheet.getRow(2).font = { bold: true, size: 11 };
  workSheet.columns = [{ width: 25 }];
};

const commitExcel = async (workbook, workSheet) => {
  await workbook.xlsx.writeBuffer().then(function (buffer) {
    saveAs(
      new Blob([buffer], { type: "application/octet-stream" }),
      `DataTable.xlsx`
    );
  });
};

export {
  WriteRows,
  makeExcel,
  writeHeader,
  commitExcel,
  writeTable,
  writeHead,
};
