import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Paper, Typography } from "@mui/material";
import { Chart } from "../index";
import { SelectBox } from "../dropdown";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 10,
    marginBottom: 20,
    marginRight: 10,
    padding: 15,
    boxShadow: "0px 4.375px 26.25px rgba(0, 0, 0, 0.12)",
    borderRadius: 6,
    backgroundColor: "white",
    height: "auto",
    // overflow:"auto"
  },
  noData: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  root1: {
    marginBottom: 20,
    padding: 10,
    boxShadow: "0px 4.375px 26.25px rgba(0, 0, 0, 0.12)",
    borderRadius: 6,
    backgroundColor: "white",
    // overflow:"auto"
  },
  dropDownButton: {
    fontSize: 12,
    color: " #4E9F3D",
    border: "1px solid #4E9F3D",
    textTransform: "none",
    minWidth: "fit-content",
    "& .MuiButton-endIcon": {
      marginLeft: 3,
    },
  },
  titleRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subHead: {
    fontSize: "14.22px",
    fontWeight: 600,
    color: theme.palette.subhead.color,
    marginTop: theme.spacing(1.5),
    paddingLeft: 2,
    whiteSpace: "nowrap",
  },
  value: {
    fontSize: "19px",
    fontWeight: "bold",
    marginBottom: "-5px",
    paddingLeft: 2,
    whiteSpace: "nowrap",
  },
}));

const ChartsWrapper = ({
  chartType,
  title = "",
  activeFilterText = "",
  hTitle = "",
  vTitle = "",
  data = [],
  id,
  height = "",
  propertyBtn,
  isBig,
  heightIncrease,
  subTitle,
  onUpdate = () => false,
}) => {
  const classes = useStyles();
  const [prop, setProperties] = useState();

  // Options To filter Graph
  const graphOptions = [];
  graphOptions.push({value: "all", label: "All Properties", selected: true});
  const apt_list = JSON.parse(sessionStorage.getItem("apt_list"));
  apt_list?.map((list) => {
    graphOptions.push({value: list.apt_id, label: list.apt_name, selected: false});
    return 0;
  });

  //Remove Attr While Selection
  const removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (
        arr[i] &&
        arr[i].hasOwnProperty(attr) &&
        arguments.length > 2 &&
        arr[i][attr] === value
      ) {
        arr.splice(i, 1);
      }
    }
    return arr;
  };

  useEffect(() => {
    setProperties({ value: "all", label: "ALL Properties" });
  }, []);

  //  State Update
  const updateState = (title, data, e) => {
    if (e.option.value === "all") {
      const all = [{value: "all", label: "ALL Properties"}];
      setProperties(all);
      onUpdate(id, all);
    } else {
      let other = [...data];
      removeByAttr(other, "value", "all");
      setProperties(other);
      onUpdate(id, other);
    }
    // data?.map((key) => {
    //   if (key.value === "all") {
    //     const all = [{value: "all", label: "ALL Properties"}];
    //     setProperties(all);
    //     onUpdate(id, all);
    //   } else {
    //     let other = [...data];
    //     removeByAttr(other, "value", "all");
    //     setProperties(other);
    //     onUpdate(id, other);
    //   }
    //   return 0;
    // });
  };

  return (
    <Grid item spacing={{ xs: 2, lg: 10 }}>
      <Paper className={heightIncrease ? classes.root : classes.root1}>
        <Grid item className={classes.titleRow}>
          <Typography variant="body2" fontWeight="bold" fontSize="17.5px">
            {title}
            {activeFilterText && (
              <Typography
                variant="span"
                fontWeight={400}
                fontSize="13.12px"
                sx={{ marginLeft: 1 }}
              >
                ({activeFilterText})
              </Typography>
            )}
            {subTitle && data.length > 0 ? (
              <Grid container spacing={{ xs: 2, md: 3 }}>
                {subTitle.map((item) => (
                  <Grid item lg={4} md={4} sm={4} xs={6} key={item.id}>
                    <Typography className={classes.subHead}>
                      {item.subHead}
                    </Typography>
                    <Typography className={classes.value}>
                      {`${JSON.parse(sessionStorage.getItem("currency"))}${
                        item.value
                      }`}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            ) : null}
          </Typography>

          {propertyBtn && (
            <Grid xs={12} lg={isBig ? 2.7 :5.5} md={6} sm={6}>
              <SelectBox
                isCheckboxGraph={true}
                options={graphOptions}
                isMulti={true}
                value={prop}
                placeholder={"All Properties"}
                onChange={(value, event) => updateState(title, value, event)}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                allowSelectAll={true}
              />
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            height: height,
            width: "auto",
            justifyContent: "center",
          }}
        >
          {data.length > 0 ? (
            <Chart
              chartType={chartType}
              data={data ?? []}
              id={id}
              hTitle={hTitle}
              vTitle={vTitle}
            />
          ) : (
            <div className={classes.noData}>
              <img src="/images/noData.svg" alt="no data"></img>
              <Typography>No Data Found!</Typography>
            </div>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ChartsWrapper;
