import React from "react";
import { makeStyles } from "@mui/styles";
import { Avatar, Typography, Box,Button, Grid} from "@mui/material";
import ReactQuill from 'react-quill';

const useStyles = makeStyles((theme) => ({
  main: {
    [theme.breakpoints.down('sm')]: {
      minWidth:"200px"
    },
    display: "flex",
    flexDirection:"column"
  },
  mainContent: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    maxWidth: "30%",
    width:"100%",
    // marginLeft:"265px",
    margin:"0 auto",
    [theme.breakpoints.down('sm')]: {
      overflow:"hidden",
      maxWidth:"75%",
      marginLeft:"35px"
    },
  },
  mainImg: {
    justifyContent: "center",
  },
  announcement: {
    paddingTop: 34,
    display: "flex",
    justifyContent: "center",
    color: "white",
    position: "absolute",
    [theme.breakpoints.down('sm')]: {
      paddingTop: 22,
    }
  },
  box: {
    position: "absolute",
    marginTop:"75px",
    // width: "65%",
    overflow: "auto",
    maxWidth: '200px',
    maxHeight: '400px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 10,
      paddingTop: 45,
      height:"85%",
      overflow:"hidden"
    }
  },
  avatar: {
    paddingTop: 25,
    marginRight: "10px",
    display: "flex",
    position:"relative"
  },
  avatarText: {
    flexDirection: "row",
    marginTop: "-5px",
    marginLeft: "10px",
  },
  title: {
    fontFamily: "adda_ccc_mobile_bold",
    color: "#000000",
  },
  subText: {
    fontFamily: "adda_ccc_normal",
    fontSize: "14px",
  },
  title1: {
    fontFamily: "adda_ccc_mobile_bold",
    color: "#000000",
    paddingTop: 20,
    position:"relative"
  },
  button:{
    padding:5,display:"flex",justifyContent:"center",
    cursor:"pointer"
  },
  quill:{
    maxHeight: '200px',
    maxWidth: '190px',
    marginTop:20,
    overflow:"auto"
  }
}));


export const MobileView = ({ data,onClose=()=>false,announcementTitle="",announcementDesc=""}) => {
  const classes = useStyles();
  const modules = {
    toolbar: false
   }

  return (
    <div className={classes.main} >
      <div className={classes.mainContent}>
        <img
          src="/images/mobile.svg"
          className={classes.mainImg}
          alt="mobile_view"
        />
        <Typography className={classes.announcement}>Announcement</Typography>
        <Box className={classes.box}>
          <Grid className={classes.avatar}>
            <Grid item>
            <Avatar src="/images/user2.svg"></Avatar>
            </Grid>
            <Grid item className={classes.avatarText}>
              <Typography className={classes.title}>Anant</Typography>
              <Typography className={classes.subText}>
                Owner Block 314
              </Typography>
            </Grid>
          </Grid>
         <Typography className={classes.title1}>
           {announcementTitle}
           </Typography>
          <ReactQuill theme="bubble" value={announcementDesc} modules={modules} className={classes.quill}/>
    
        </Box>
      </div>
      <div className={classes.button}>
      <Button onClick={onClose} variant="outlined">
             Go Back
       </Button>
      </div>
    
    </div>
  );
};
