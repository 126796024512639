import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from 'prop-types';
import { Typography, Box, Grid, Card, Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  title: {
    m: 0, p: 2, display: 'flex', flexDirection: 'row', justifyContent: "space-between", fontFamily: "adda_ccc_bold", fontSize: "22px", color: "#0A483C"
  },
  iconCard: {
    display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: '8px', alignItems: "center", borderRadius: '6px', marginTop: 10,
    boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.12)", cursor: 'pointer',width:"230px"

  },
  item: {
    padding: '20px', display: 'flex', flexDirection: 'column', minWidth: '213px'
  },
  content: {
    display: "flex", flexDirection: "row", justifyContent: "space-around", flexWrap: "wrap"
  },
  icon: {
    display: "flex", justifyContent: "center", margin: '10px'
  },
  iconText: {
    marginLeft: '5px',
    fontFamily: 'adda_ccc_regular'
  },
  textHeader: {
    fontFamily: "adda_ccc_bold"
  },
  Dialog:{
  '& .MuiDialog-paper': {
    minWidth: 822,
    borderRadius: '12px',
    [theme.breakpoints.down('md')]: {
      minWidth: 522,
    },
    [theme.breakpoints.down('sm')]: {
      minWidth: 300,
    },
    
  }
  }
}));


//Bootstraped DialogTitle
const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  const classes = useStyles();

  return (
    <DialogTitle className={classes.title} {...other}>
      {children}
      {onClose && (
        <Button
          aria-label="close"
          onClick={onClose}
          color="error"
          style={{ textDecorationLine: 'underline' }}
          endIcon={<CloseIcon />}
        >
          Close
        </Button>
      )}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
//

export const QuickActionCard = (props) => {
  const {data} = props;

  const classes = useStyles();
  const history = useHistory();
  const [open, setOpen] = React.useState(props.open);
     
 // Close Dialog
  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  //  Quick Action Selection
  const OnClick = (item) => {
    handleClose();
    history.push(history.location.pathname + item.route);
  }

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes.Dialog}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Quick Actions
        </BootstrapDialogTitle>
        <DialogContent className={classes.content}>

          {data.length > 0 ? data.map((items, index) => (
            <Box sx={{ flexGrow: 1 }} key={index}>
              <Grid container spacing={1} >
                <Grid item xs={12} sm={12} md={12} lg={4} key={items.id} className={classes.item} >
                  <Typography className={classes.textHeader}>
                    {items.header}
                    </Typography>
                  <div style={{
                    height: 7,
                    backgroundColor: `${
                      items.header === "Communication" ?
                          "#445B75"
                          : items.header === "Helpdesk" ?
                           "#23BBA5"
                            : items.header === "Mollak" ?
                              "#FD4F4F"
                              : items.header === "Purchase Manager" ?
                                "#445B75"
                                : items.header === "Access Control" ?
                                  "#9066C5"    
                                  :null
                        
                    }`,
                    borderRadius: 50,
                    margin: 2,
                    width:"230px"
                  }}></div>
                  
                  {items.content?.map((i) =>{
                    return(
                      <div>
                  {i.icontext !== "" ?
                    <Card className={classes.iconCard} onClick={() => OnClick(i)}>
                      <div style={{height: '36px', borderRadius: '6px', backgroundColor: `${
                         items.header === "Communication" ?
                          "#445B75"
                          : items.header === "Helpdesk" ?
                           "#23BBA5"
                            : items.header === "Mollak" ?
                              "#FD4F4F"
                              : items.header === "Purchase Manager" ?
                                "#445B75"
                                : items.header === "Access Control" ?
                                  "#9066C5"          
                                  :null
                        
                    }`, }}>
                        {i.icontext === "announcement" ?
                          <img className={classes.icon} src='/images/icons/Announcement.svg' alt="announcement"></img>
                          : i.icontext === "create_ticket" ?
                            <img className={classes.icon} style={{ margin: "8px" }} src='/images/icons/CreateTicket.svg' alt="ticked"></img>
                            : i.icontext === "save_mollak" ?
                              <img className={classes.icon} style={{ marginLeft: 6 }} src='/images/icons/Mollak.svg' alt='Mollak'></img>
                              : i.icontext === "save_signature" ?
                                <img className={classes.icon} src='/images/icons/Signature.svg' alt='Signature'></img>
                                : i.icontext === "add_staff" ?
                                  <img className={classes.icon} src='/images/icons/staff.svg' alt='AddStaff'></img>
                                  : i.icontext === "remove_staff" ?
                                  <img className={classes.icon} src='/images/icons/removeStaff.svg' alt='RemoveStaff'></img>
                                  :null
                        }
                      </div>
                      <Typography className={classes.iconText}>{i.icontext === "announcement" ?
                          "Announcement"
                          : i.icontext === "create_ticket" ?
                            "Create Request"
                            : i.icontext === "save_mollak" ?
                             "Configure Mollak"
                              : i.icontext === "save_signature" ?
                               "Manage Signature"
                                : i.icontext === "add_staff" ?
                                  "Add Staff"
                                  : i.icontext === "remove_staff" ?
                                  "Remove Staff"
                                  :null
                        }</Typography>
                    </Card>
                    :
                    null
                  }

                       </div>
                    )
                  })}

                 
                </Grid>
              </Grid>
            </Box>
          )) : null}
        </DialogContent>
      </Dialog>
    </div>
  );
}

QuickActionCard.propTypes = {
  data: PropTypes.array
}
