import { Grid, Typography, Hidden } from "@mui/material";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { SelectBox } from "..";
const useStyles = makeStyles((theme) => ({
  paper: {
    margin: 7,
    backgroundColor: " #E1FEDB",
    borderRadius: "6px",
    padding: 10,
  },
  heading: {
    m: 1,
    fontWeight: 600,
    fontSize: "18px",
  },
  headings2: {
    color: "#606060",
    fontSize: "16px",
  },
  middleContent: {
    justifyContent: "flex-end",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("xs")]: {
      justifyContent: "flex-start",
    },
  },
  middleContent1: {
    [theme.breakpoints.up("xs")]: {
      marginLeft: 0,
    },
  },
  subheadings: {
    color: "# 272727",
    fontSize: "16px",
  },
}));
export const ConfigurationCard = (props) => {
  const classes = useStyles();
 
  return (
    <>
      <div className={classes.root}>
 
          {props?.data?.map((data) => {
            return (
              <>
                <Paper variant="outlined" className={classes.paper}>
                <Typography variant="subtitle1" className={classes.heading}>
                  {data.group}
                </Typography>
                <Grid container justifyContent="start" alignItems="center">
                  {data?.configuration?.map((conf) => {
                    let select = [];
                    const arr = conf.config_values.split(",");
                    arr.map((items) => {
                      select.push({
                        value: items,
                        label: `${items}${conf.config_unit}`,
                      });
                      return 0;
                    });
                    return (
                      <>                 
                        <Grid
                          item
                          container
                          xs={12}
                          sm={9}
                          md={10}
                          lg={10}
                          className={classes.middleContent}
                        >
                           <Grid item xs={12} sm={3} md={2} lg={2}>
                          <Typography
                            variant="subtitle1"
                            className={classes.headings2}
                          >
                            {conf.config_description}
                          </Typography>
                        </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            md={2}
                            lg={2}
                            marginLeft={0}
                          >
                            <Typography
                              variant="subtitle1"
                              className={classes.subheadings}
                            >
                              {conf.config_pre}&nbsp;&nbsp;
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={2} md={2} lg={2}>
                            <SelectBox
                              options={select}
                              value={props.drop !== undefined ? props.drop[conf.config_item] :[]}
                              onChange={(value)=>props.onUpdate(conf.config_item,value)}
                              placeholder={conf.config_selected}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={7}
                            md={6}
                            lg={6}
                            className={classes.middleContent1}
                          >
                            <Hidden smDown>
                              <Typography
                                variant="subtitle1"
                                className={classes.subheadings}
                              >
                                &nbsp;&nbsp;{conf.config_post}
                              </Typography>
                            </Hidden>
                            <Hidden smUp>
                              <Typography
                                variant="subtitle1"
                                className={classes.subheadings}
                              >
                                {conf.config_post}
                              </Typography>
                            </Hidden>
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
                <br />
                </Paper>
              </>
            );
          })}
       
      </div>
    </>
  );
};
