import React from "react";
import { TopNavBar } from "./topNavbar";
import { TopNavBar2 } from "./topNavbar2";

class TopNavbarParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>
      <TopNavBar hidePersonalInfo={this.props.hidePersonalInfo} />
      <TopNavBar2 hidePersonalInfo={this.props.hidePersonalInfo} />
    </div>
  }
}

export default TopNavbarParent;
