import FilterListIcon from '@mui/icons-material/FilterList';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box, Hidden, IconButton,Badge} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.paper,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        boxShadow: "0px 4px 14px 0px #0000001F",
        [theme.breakpoints.up("xs")]: {
            background: theme.palette.background.paper,
            boxShadow: "0px 4px 14px 0px #0000001F",
        },
    },
    filterBtn: {
        color: theme.palette.text.primary
    },
    configBtn: {
        color: theme.palette.text.primary
    },
    mobConfigBtn: {
        color: theme.palette.text.primary,
        padding: 10
    },
    saveViewBtn: {},
    tabItem: {
        textTransform: "capitalize"
    },
    configcontent: {
        float:"right"
    }

}))

const TabsComponent = ({
    selectedTab = 0,
    onFilterClicked = '',
    tab1Label = "Graphical Report",
    tab2Label = "Data Table",
    onTab1Clicked = () => false,
    onTab2Clicked = () => false,
    onFilterBtnClicked = () => false,
    onConfigureBtnClicked = () => false,
    onSaveBtnClicked = () => false,
    showSaveBtn = false,
    showButtonSection = true,
}) => {


    const classes = useStyles();
    const [invisible,setInvisible] = React.useState(false)

    React.useEffect(()=>{
     let invisibles = JSON.parse(sessionStorage.getItem('filter_selection'))
     if(invisibles === true){
         setInvisible(false)
     }
     else setInvisible(true)
    },[])
    
    return (

        <div className={classes.root}>

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center">

                {/* Tab Section */}
                <Grid item xs={9}>
                    <Tabs value={selectedTab}>
                        <Tab className={classes.tabItem} label={tab1Label} onClick={onTab1Clicked} />
                        <Tab className={classes.tabItem} label={tab2Label} onClick={onTab2Clicked} />
                    </Tabs>
                </Grid>

                {/* Button Section */}
                {showButtonSection && <Grid item xs={3}>
                    <Stack direction="row" alignContent="flex-end"  className={classes.configcontent}>

                        {/* Filter Button */}
                        <Box>
                            <Hidden smDown>
                                <Button
                                    className={classes.filterBtn}
                                    style={{ color: onFilterClicked }}
                                    variant="text"
                                    onClick={onFilterBtnClicked}
                                    startIcon={ 
                                    <Badge  color="error" variant="dot" invisible={invisible}>
                                      <FilterListIcon />
                                   </Badge>
                                   }
                                >
                                    Filter
                                </Button>
                            </Hidden>

                            <Hidden smUp>
                                <IconButton
                                    className={classes.filterBtn}
                                    style={{ color: onFilterClicked }}
                                    onClick={onFilterBtnClicked} 
                                >
                                    <Badge  color="error" variant="dot" invisible={invisible}>
                                      <FilterListIcon />
                                   </Badge>
                                </IconButton>
                            </Hidden>

                        </Box>

                        {/* Configure Button */}
                        <Box>
                            <Hidden smDown>
                                <Button className={classes.configBtn} variant="text" onClick={onConfigureBtnClicked} startIcon={<img src="images/configure_icon.svg" alt="configureLogo" />}>
                                    Configure
                                </Button>
                            </Hidden>
                            <Hidden smUp>
                                <IconButton className={classes.mobConfigBtn} onClick={onConfigureBtnClicked}>
                                    <img src="images/configure_icon.svg" alt="configureLogo" />
                                </IconButton>
                            </Hidden>
                        </Box>
                        {/* Save View Button */}
                        {showSaveBtn &&
                            <Button color={"info"} variant="outlined" onClick={onSaveBtnClicked} startIcon={<RemoveRedEyeIcon />}>
                                save view
                            </Button>
                        }
                    </Stack>
                </Grid>}
            </Grid>
        </div>

    );
}

export default TabsComponent;