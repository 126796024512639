import React from "react";
import { withNavBars } from "./../../HOCs";
import { Golabal } from './globalreport';

class GlobalParent extends React.Component {
  render() {
    return <Golabal />;
  }
}

export default withNavBars(GlobalParent);
