import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Dialog, Tabs, Tab, Box, Button, DialogActions } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { ModuleTree } from "./moduleTree";
import { PropCheck } from "./propCheck";
import { NetworkCall } from "../../networkcall";
import config from "../../config";
import { AlertProps } from "../../utils";
import { AlertContext } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: "20%",
  },
  Content: {
    padding: 0,
    overflow: "auto",
    height: "250px",
  },
  Dialog: {
    "& .MuiDialog-paper": {
      boxShadow: "0px 3px 24px rgba(0, 0, 0, 0.18)",
      borderRadius: 10,
      position: "absolute",
      top: 140,
      right: 50,
      "@media (max-width: 425px)": {
        right: 5,
      },
    },
  },
  DialogContent: {
    minWidth: "410px",
    overflow: "scroll",
    "@media (max-width: 425px)": {
      minWidth: 280,
    },
  },
  TabItem: {
    "&:hover": {
      "& $HidetextItem": {
        color: "#007961",
      },
    },
  },
  labelItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  HidetextItem: {
    position: "absolute",
    right: 0,
    color: "transparent",
    fontFamily: "adda_ccc_regular",
    fontSize: 14,
  },
  Tabchild: {
    "&:hover": {
      "& $HidetextChild": {
        color: "#007961",
      },
    },
  },
  labelChild: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  HidetextChild: {
    position: "absolute",
    right: 0,
    color: "transparent",
  },
}));
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{}} style={{ padding: 10 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const FilterTree = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const [value, setValue] = useState(0);
  const [Pselected, setPSelected] = useState([]);
  const [Mselected, setMSelected] = useState([]);
  const [checked, setChecked] = useState(false);
  const alert = React.useContext(AlertContext);

  useEffect(() => {
    const fselected = JSON.parse(sessionStorage.getItem("module_selected"));
    const pselected = JSON.parse(sessionStorage.getItem("properties_selected"));
    let initialSelected = [];
    let propertiesSelected = [];
    //Filter Icon Badge Deider
    if (fselected === "all") {
      const modules = JSON.parse(sessionStorage.getItem("datatable_filter"));
      modules?.map((items, index) => {
        initialSelected.push(items.id);
        items.child?.map((keys) => {
          initialSelected.push(keys.id);
          return 0;
        });
        return 0;
      });
      setMSelected(initialSelected);
    } else {
      initialSelected = fselected?.replace(/"|\[|\]/g, "").split(",");
      setMSelected(initialSelected);
    }
    if (pselected === "all") {
      const Properties = JSON.parse(sessionStorage.getItem("apt_list"));
      Properties?.map((items, index) => {
        propertiesSelected.push(items.apt_id);
        return 0;
      });
      setPSelected(propertiesSelected);
    } else {
      propertiesSelected = pselected?.replace(/"|\[|\]/g, "").split(",");
      setPSelected(propertiesSelected);
    }
    initialSelected?.length > 0
      ? props.handleVisible(false)
      : props.handleVisible(true);
    // 
  }, [props]);

  const arr = [];
  const Propdata = [];

  //Modules Filter
  const modules = JSON.parse(sessionStorage.getItem("datatable_filter"));
  modules?.map((items, index) => {
    let arrChild = [];
    items?.child?.map((list) => {
      arrChild.push({
        id: list.id,
        name: list.label,
      });
      return 0;
    });
    arr.push({
      id: items.id,
      name: items.name,
      children: arrChild,
    });
    return 0;
  });

  //Properties Filter
  const Properties = JSON.parse(sessionStorage.getItem("apt_list"));
  Properties?.map((items, index) => {
    Propdata.push({ id: items.apt_id, name: items.apt_name });
    return 0;
  });

  // Filter Tab Changes
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Filter Close
  const handleClose = () => {
    props.onClose();
    setOpen(false);
  };

  // On Filter Apply
  const handleApply = () => {
    if (Mselected.length && Pselected.length) {
      var Formdata = new FormData();
      let auth = {
        owner_id: localStorage.getItem("owner_id"),
        auth_key: localStorage.getItem("auth_token"),
      };
      let action_items = {
        modules: Mselected.toString(),
        properties: Pselected.toString(),
      };
      Formdata.append("auth", JSON.stringify(auth));
      Formdata.append("case", "save_filter");
      Formdata.append("action_items", JSON.stringify(action_items));
      NetworkCall(
        config.api_url + "/ccc_quickactions_ver1",
        "post",
        Formdata,
        null,
        false
      )
        .then((response) => {
          const main = response?.data;
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.success,
            msg: main.message,
          });
          props.onClose();
          window.location.reload();
          props.handleFiltered();
        })
        .catch((err) => {});
    }
  };

  // Select Properties
  const handlePropertiesSelect = (event, nodeId) => {
    const first = nodeId;
    if (Pselected.includes(first)) {
      setPSelected(Pselected.filter((id) => id !== first));
    } else {
      setPSelected([first, ...Pselected]);
    }
  };
 
  // Select Modules
  const handleModuleSelect = (e, node, option) => {  
    const first = node.id;
    if (Mselected.includes(first)) {
      let unchecked = [...Mselected];
      for (var i = 0; i < unchecked.length; i++) {
        if (unchecked[i] === first) {
  
          unchecked.splice(i, 1);
        }
      }
      if (node.children) { 
        node.children.forEach((items) => {
          for (var i = 0; i <= unchecked.length; i++) {
            if (unchecked[i] === items.id) {
              unchecked.splice(i, 1);
            }
          }
        });
      }
      setMSelected(unchecked);
    } else {
      let checked = [...Mselected];    
      if (node.children) {
        checked.push(first)
        node.children.forEach((items) => {
          checked.push(items.id);
        });
      }
      else{
        arr.forEach(ch =>{
          if(ch.children.length){
            if(ch.children.some(x => x.id === first)){
              checked.push(first);
              checked.push(ch.id)
            }
          }
          return 0;
        })
      }
      setMSelected(checked);
    }
  };

  // Select Only Module
  const OnOnly = (e, node) => {
    setChecked(false);
    const onlySelected = [];
    if (node.children) {
      Mselected.map((key) => {
        if (key === node.id) {
          onlySelected.push(node.id);
        }
        node.children.map((item) => {
          if (key === item.id) {
            onlySelected.push(key);
          }
          return item;
        });
        return key;
      });
      setMSelected(onlySelected);
    } else {
      let checked =[...Mselected]
       arr.forEach((key) => {
        if (key.children.length) {
          if(key.children.some(x => x.id === node.id)){
           key.children.map(ch =>{
            for (var i = 0; i < checked.length; i++) {
              if (checked[i] === ch.id) {
                checked.splice(i, 1);
              }
             }
             return 0;
           })
           checked.push(node.id)
          }
        }
      });
       setMSelected(checked);
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className={classes.Dialog}
    >
      <DialogTitle style={{ padding: 0 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                color: "#007961",
              }}
            >
              <Tab label="Module" {...a11yProps(0)} />
              <Tab label="Properties" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel
            value={value}
            index={0}
            className={classes.DialogContent}
            style={{ padding: 0 }}
          >
            <DialogContent className={classes.Content}>
              {arr.length > 0 && 
               <ModuleTree
              data={arr}
              onChange={handleModuleSelect}
              OnOnly={OnOnly}
              MData={Mselected}
              checked={checked}
              />
              }
              
            </DialogContent>
            <DialogActions style={{justifyContent:"center"}}>
              <Button
                variant="outlined"
                sx={{ width: "40%" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ width: "40%" }}
                onClick={handleApply}
                disabled={Mselected?.length > 0 ? false : true}
              >
                Apply filter
              </Button>
            </DialogActions>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            className={classes.DialogContent}
            style={{ padding: 0 }}
          >
            <DialogContent className={classes.Content}>
              {Propdata.length > 0 && 
               <PropCheck
               data={Propdata}
               onChange={handlePropertiesSelect}
               PData={Pselected}
             />
              }
             
            </DialogContent>
            <DialogActions style={{justifyContent:"center"}}>
              <Button
                variant="outlined"
                sx={{ width: "40%" }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ width: "40%" }}
                onClick={handleApply}
                disabled={
                  Pselected?.length > 0 && Mselected?.length > 0 ? false : true
                }
              >
                {" "}
                Apply filter
              </Button>
            </DialogActions>
          </TabPanel>
        </Box>
      </DialogTitle>
    </Dialog>
  );
};

FilterTree.propType = {
  data: PropTypes.array,
  data2: PropTypes.array,
};
