
export const Mollakheading = [   
  { title: "S No", field:"Sno", type: "numeric" },
  { title: "Master Company Name", field: "name", },
  { title: "Project Name", field: "project_name", },
  { title: "Property Group", field: "group_name", },
  { title: "Property Group ID", field: 'group_id', type: "numeric" },
  { title: "Status", field: 'status', type: "currency" }
   
]


export const MollakReportpath = ["date","by","of","field","before","after"]

export const MollakReportrow = [
    {
        date: "22-11-21",
         by:"Admin",
       of:"Block D-104",
       field:"Shift L2",
       before: '-' ,
       after:'-'
      },
     
]
export const MollakReportheading = [
   
  { title: "Date", field: "date", type: "numeric" },
  { title: "By", field: "by", },
  { title: "Of", field: "of", },
  { title: "Field", field: "field", },
  { title: "Before", field: 'before', },
  { title: "After", field: 'after', }
   
]