import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Card,
  CardHeader,
  Divider,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Avatar,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";

const useStyles = makeStyles((theme) => ({
  Card: {
    borderRadius: 8,
    width: "100%",
    height: 500,
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px 4.375px 26.249998092651367px 0px #0000001F",
  },
  CardHeader: {
    width: "100%",
    display: "flex",
    textAlign: "left",
    "& .MuiCardHeader-title": {
      fontSize: "17.5px",
      fontWeight: 600,
    },
  },
  Po: {
    border: "1.09375px solid #649746",
    color: theme.palette.Po.color,
    background: theme.palette.Po.background,
    borderRadius: "50%",
    width: "41.56px",
    height: "41.56px",
    padding: "10px",
  },
  PoIcon: {
    position: "absolute",
    width: "19.69px ",
    height: "19.69px",
  },
  MoveIn: {
    border: "1.09375px solid #525B8A",
    color: theme.palette.MoveIn.color,
    background: theme.palette.MoveIn.background,
    borderRadius: "50%",
    width: "41.56px",
    height: "41.56px",
    padding: "10px",
  },
  MoveIcon: {
    position: "absolute",
    width: "19.69px ",
    height: "19.69px",
  },
  Ticket: {
    border: "1.09375px solid #7E5230",
    color: theme.palette.Ticket.color,
    background: theme.palette.Ticket.background,
    borderRadius: "50%",
    width: "41.56px",
    height: "41.56px",
    padding: "10px",
  },
  TicketIcon: {
    position: "absolute",
    width: "20.65px",
    height: "21.88px",
  },
  closeIcon: {
    color: theme.palette.error.close,
    marginRight: "10px",
    cursor: "pointer",
  },
  eyeIcon: {
    color: theme.palette.Po.color,
    cursor: "pointer",
  },
  CardContent: {
    overflow: "auto",
    padding: 0,
    marginTop: -7,
  },
  ListItemText: {
    "& .MuiTypography-root": {
      fontWeight: 600,
    },
  },
  title: {
    m: 0,
    p: 2,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    fontFamily: "adda_ccc_bold",
    fontSize: "22px",
    color: "#0A483C",
  },
  todoTitle: {
    fontSize: "20px",
    color: "#000000",
    padding: 5,
  },
  todoTitle2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    opacity: 0.5,
    width: "86%",
    textAlign: "center",
  },
  cardContent2: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "47px",
  },
}));

export const TodoCard = (props) => {
  const { data } = props;
  const classes = useStyles();
  //  Todo Card View
  const onView = (url, heading) => {
    window.open(url);
  };
  return (
    <div>
      {data.title && (
        <Card className={classes.Card}>
          <CardHeader
            title={data.title}
            className={classes.CardHeader}
          ></CardHeader>
          {data?.all_todos?.length > 0 ? (
            <CardContent className={classes.CardContent}>
              {data.all_todos?.map((items, index) => (
                <List style={{ overflow: "auto" }}>
                  <div>
                    <Divider variant="middle" />
                    <ListItem>
                      <ListItemIcon>
                        <Avatar
                          src={items.todo_icon}
                          sx={{ width: "55.56px", height: "55.56px" }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        className={classes.ListItemText}
                        primary={items.todo_heading}
                        secondary={items.todo_notes}
                      />

                      <RemoveRedEyeOutlinedIcon
                        className={classes.eyeIcon}
                        onClick={() =>
                          onView(items.todo_url, items.todo_heading)
                        }
                      />
                    </ListItem>
                  </div>
                </List>
              ))}
            </CardContent>
          ) : (
            <CardContent>
              <div className={classes.cardContent2}>
                <img src="/images/Frame.svg" alt="frame"></img>
                <Typography variant="h6" className={classes.todoTitle}>
                  All done There's Nothing Left to do!
                </Typography>
                <Typography variant="subtitle2" className={classes.todoTitle2}>
                  Stay Tuned, if there are any Todo's assigned to you it will
                  show up here.
                </Typography>
              </div>
            </CardContent>
          )}
        </Card>
      )}
    </div>
  );
};

TodoCard.propTypes = {
  data: PropTypes.array,
};
