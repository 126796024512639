import React from "react";
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const Download =(props)=> {
  
        const downloadData = props.data;
        const headers = props.headers;
        const path = props.path;
        const fileName = props.filename;
    
            return (
                <ExcelFile> 
                  <ExcelSheet  data={downloadData} name={fileName}>       
                    {path.map((value, i) =>
                            <ExcelColumn key={i} label={`${headers[i]?.title}`} value={`${value}`} />
                        )}
                  </ExcelSheet>      
                </ExcelFile>
            );

    }


 
