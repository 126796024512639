import { Typography, Hidden, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "0px 4.375px 26.25px rgba(0, 0, 0, 0.12)",
    borderRadius: "8.45px",
    fontFamily: "Open Sans",
    backgroundColor: theme.palette.background.paper,
  },
  count: {
    fontWeight: "bold",
    marginBottom: "12px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
      marginLeft:"35px"
    },
  },
  Cardimg: {
    padding: "8px 10px",
    borderRadius: "8.75px",
  },
  Cardcontent: {
    padding: "8px 12px",
  },
  title: {
    color: "#545454",
    fontWeight: "bold",
    fontStyle: "normal",
    marginTop: "4px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
      marginLeft:"-15px"
    },
  },
}));
export const DashboardCard = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.card}>
      <div className={classes.Cardcontent}>
        
        <Hidden smDown>
          <img 
          src ={
            props.data.key === "properties" ?
            '/images/properties.svg' :
            props.data.key === "units" ?
            "/images/unit.svg" :
            props.data.key === "owners_tenants" ?
            '/images/owner.svg' :
            props.data.key === "staff" ?
            '/images/staff.svg' :
            null
          }
          alt ={
            props.data.key === "properties" ?
            "properties":
            props.data.key === "units" ?
            "units" :
            props.data.key === "owners_tenants" ?
            "owners_tenants" :
            props.data.key === "staff" ?
            "staff" :
            ""
          }
          height="40.31" 
          className={classes.Cardimg} 
          style={{
            backgroundColor:props.data.key === "properties" ?
            '#E9F5FF' :
            props.data.key === "units" ?
            "#FFF6E7" :
            props.data.key === "owners_tenants" ?
            '#E7ECF3' :
            props.data.key === "staff" ?
            '#E5FCE7' :
            null
          }}
          
          />
          <Typography variant="subtitle2" className={classes.title}>
          {props.data.key === "properties" ?
            'Properties' :
            props.data.key === "units" ?
            "Units" :
            props.data.key === "owners_tenants" ?
            'Owners/Tenants' :
            props.data.key === "staff" ?
            'Admin Users' :
            null}     
          </Typography>
          <Typography
            variant="body1"
            fontWeight="bold"
            className={classes.count}
          >
            {props.data.value}
          </Typography>
        </Hidden>

        <Hidden smUp>
          <Grid container alignItems="center">
            <Grid item xs={3} >
            <img 
          src ={
            props.data.key === "properties" ?
            '/images/properties.svg' :
            props.data.key === "units" ?
            "/images/unit.svg" :
            props.data.key === "owners_tenants" ?
            '/images/owner.svg' :
            props.data.key === "staff" ?
            '/images/staff.svg' :
            null
          }
          alt ={
            props.data.key === "properties" ?
            "properties":
            props.data.key === "units" ?
            "units" :
            props.data.key === "owners_tenants" ?
            "owners_tenants" :
            props.data.key === "staff" ?
            "staff" :
            ""
          }
          height="40.31"
          className={classes.Cardimg} 
          style={{
            backgroundColor:props.data.key === "properties" ?
            '#E9F5FF' :
            props.data.key === "units" ?
            "#FFF6E7" :
            props.data.key === "owners_tenants" ?
            '#E7ECF3' :
            props.data.key === "staff" ?
            '#E5FCE7' :
            null
          }}
          
          />
            </Grid>
            <Grid item xs={5} justifyContent="flex-start" >
              <Typography variant="subtitle2" className={classes.title}>
              {props.data.key === "properties" ?
            'Properties' :
            props.data.key === "units" ?
            "Units" :
            props.data.key === "owners_tenants" ?
            'Owners/Tenants' :
            props.data.key === "staff" ?
            'Staff' :
            null}        
              </Typography>
            </Grid>
            <Grid item xs={4} justifyContent="flex-end" >
              <Typography
                variant="body1"
                fontWeight="bold"
                className={classes.count}
              >
             {props.data.value}
              </Typography>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    </div>
  );
};
