import React from "react";
import { Home } from './home';
import { withNavBars } from "./../../HOCs";
import { withRouter } from "react-router";
import { Routes } from "../../router/routes";
import { didIAllowToAccess } from "../../router/access";

let redirector = null;
class HomeParent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showErrorPage: false
    }
  }

  componentDidMount() {
    redirector = setTimeout(() => {
      if (didIAllowToAccess()) {
        this.props.history.push(Routes.graphicalReport);
      } else {
        this.setState({ showErrorPage: true })
      }
    }, 1000);
  }

  render() {

    if (this.state.showErrorPage) {
      clearTimeout(redirector);
    }

    return <Home showErrorPage={this.state.showErrorPage} />;
  }
}

export default withRouter(withNavBars(HomeParent));
