import React from "react";
import { Box, TextField } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    search: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        marginLeft: 0,
        width: "100%",
        display: "inline-flex",
    },
    Removesearch: {
        position: "relative",
        borderRadius: theme.shape.borderRadius,
        marginLeft: '20px',
        width: "95%",
        display: "inline-flex",
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    inputRoot: {
        height: "40px",
        color: "#C5C5C5",
        borderRadius: 5,
        width: "100%",
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create("width"),
        width: "100%",
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },

}));

export const SearchFilter = ({
    handleChange = () => false,
    value = "",
    removeStaff = false

}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box>
                <div className={removeStaff ? classes.Removesearch : classes.search}>
                    <TextField
                        InputProps={{
                            startAdornment: <SearchIcon style={{ margin: 8, color: "#999999" }} />,
                        }}
                        placeholder="Search"
                        onChange={(e) => handleChange(e.target.value)}
                        value={value}
                        variant={"outlined"}
                        size={"small"}
                        fullWidth
                        className={classes.textfield}
                    />


                </div>
            </Box>
        </div>
    );
};
