import { TextField, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from "prop-types";
import React from "react";


const useStyles = makeStyles((theme) => ({
    textbox: {
        [`& fieldset`]: {
            borderRadius: 7,
            height: (props) => props.multiline ? "unset" : 50,
            border: "1.5px solid #E2E2E2",
            boxShadow: "none",
        },
        '& .MuiOutlinedInput-input':{
            padding:'9.5px'
        }
    },
    

}));



const TextBox = (props) => {

    const classes = useStyles(props);
    const getTitle = (props) => {
        return (
            <div style={{display:"flex"}}>
                       <Typography variant="body1" style={{ fontSize: "12px", color: "textsecondary" }} gutterBottom >{props.label} </Typography>
                       {props.isRequired && (
                <Typography color="error" variant="caption">
                  &nbsp;*
                </Typography>
              )}
            </div>    
        )
       
    }

    return (
        <div className={classes.root}>
            <>
                {getTitle(props)}
                <TextField
                    className={classes.textbox}
                    type={props.type}
                    id={props.id}
                    // label={props.label}
                    placeholder={props.placeholder}
                    variant={props.variant ?? "outlined"}
                    fullWidth
                    InputLabelProps={{
                        shrink: false
                    }}
                    inputProps={{ readOnly: props?.isReadonly ?? false }}
                    disabled={props?.disabled ?? false}
                    size="small"
                    multiline={props.multiline}
                    rows={5}
                    rowsMax={10}
                    onChange={props.onChange}
                    value={props.value}           
                />
            </>
            {props.isError && <Typography variant={"caption"} color={"error"}>{props.errorMessage}</Typography>}
   
        </div>
    );
};

TextBox.propTypes = {
    value: PropTypes.string,
    label: PropTypes.string,
    id: PropTypes.string,
    multiline: PropTypes.bool,
    type: PropTypes.string,
    isReadonly: PropTypes.bool,
    onChange: PropTypes.func,
};
TextBox.defaultProps = {
    label: "Textbox",
    multiline: false,
    type: "text",
};
export default TextBox;
