import React from "react";
import {
  BrowserRouter as Router, Route, Switch
} from "react-router-dom";
import PrivateRouter from "./privateRouter";
import {
  Configure, DataReport, GlobalReportDataTable, GlobalReports, GraphicalReport, Home, NotFound
} from './../screens';
import { Routes } from "./routes";


const RouterApp = (props) => {

  return (
    <Router>
      <Switch>

        {/* Home */}
        <Route exact path={Routes.home}>
          <Home hidePersonalInfo={true} />
        </Route>

        {/* Data Report Route */}
        <PrivateRouter exact path={Routes.dataReport}>
          <DataReport />
        </PrivateRouter>

        {/* Configure Route */}
        <PrivateRouter exact path={Routes.configure}>
          <Configure />
        </PrivateRouter>

        {/* Graphical Report Route */}
        <PrivateRouter exact path={Routes.graphicalReport}>
          <GraphicalReport />
        </PrivateRouter>

        {/* Global Reports */}
        <PrivateRouter exact path={Routes.global_reports}>
          <GlobalReports />
        </PrivateRouter>

        {/* Global Report Data Table */}
        <PrivateRouter exact path={Routes.global_report}>
          <GlobalReportDataTable />
        </PrivateRouter>

        {/* For unknow/non-defined path */}
        <Route exact path="*" component={NotFound} />
      </Switch>
    </Router>
  );
};

export default RouterApp;
