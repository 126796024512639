import Chart from "react-google-charts";
import allOptions from './utils';

const Charts = ({ chartType, data, id,hTitle,vTitle }) => {
    return <Chart
        width={"100%"}
        height={"100%"}
        chartType={chartType ?? 'line'}
        loader={<div>Loading Chart</div>}
        data={data ?? []}
        options={{
            ...allOptions[id],
            hAxis: {
                ...allOptions[id].hAxis,
                textStyle: { color: '#545454', fontSize: 10 },
                title:hTitle
            },
            vAxis: {
                ...allOptions[id].vAxis,
                textStyle: { color: '#545454', fontSize: 10 },
                baselineColor: '#9D9D9D',
                title:vTitle
            }
        }}
        legendToggle
        
    />
}

export default Charts;