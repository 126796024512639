import CloseIcon from '@mui/icons-material/Close';
import {
    Grid,
    IconButton, Typography
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { AlertContext } from '../../contexts';
import { AlertProps } from '../../utils';
import { Upload } from "./fileupload";


const useStyles = makeStyles((theme) => ({
    rootContainer: {
        position: "relative",
        minHeight: "100%",
        backgroundColor: theme.palette.background.paper,
        margin: 0,
    },
    root: {

    },
    footer: {
        position: "absolute",
        bottom: 0,
        width: "100%",
    },
    content: {
        display: "inline-flex",
        width: "100%",
    },
    cardContainer: {
        backgroundColor: theme.palette.background.paper,
        marginTop: theme.spacing(2),
        boxShadow: "0px 10px 15px #00000014",
        border: "2px solid #E2E2E2",
        borderRadius: "8px",
    },
    Container: {
        marginBottom: theme.spacing(2),

    },
    circularLoader: {
        paddingRight: "8px",
        position: "relative",
        "& .MuiCircularProgress-root": {
            position: "relative",
        },
    },
    loadCenter: {
        position: "absolute",
        left: 14,
    },
    uploadText: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    errorText: {
        color: 'red',
        fontSize: '0.75rem'
    }
}));

export const UploadReports = (props) => {
    const classes = useStyles(props);

    const alert = React.useContext(AlertContext);

    const [uploaddocc, setuploaddocc] = React.useState([]);

    // Know File Size
    const returnFileSize = (number) => {
        if (number < 1024) {
            return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
            return (number / 1048576).toFixed(1) + 'MB';
        }
    }
    // Check File Size Limit
    const FileSizeSetLimit = (number) => {
        if (number >= 1048576) {
            if ((number / 1048576).toFixed(1) > 15) {
                return false;
            } else {
                return true;
            }
        } else {
            return true;
        }
    }
    
    // Handle File Upload
    const handleUpload = async (e) => {
        e.preventDefault();
        let file = e.target.files[0];
        let size = returnFileSize(file.size);
        const data = {
            name: file?.name,
            load: true,
            file: file,
            size: size
        };
        if (FileSizeSetLimit(file.size)) {
            if (props?.single && uploaddocc.length >= 1) {
                alert.setSnack({
                    ...alert,
                    open: true,
                    severity: AlertProps.severity.error,
                    msg: "Only Single File can be Uploaded !",
                    vertical: AlertProps.vertical.top,
                    horizontal: AlertProps.horizontal.center
                })
            } else {

                if (props?.single) {
                    setuploaddocc([...uploaddocc, ...[data]]);
                    props.onChange('file_ids',data);
                }
                if (!props?.single) {
                    setuploaddocc([...uploaddocc, ...[data]]);

                    props.onChange('file_ids',data);
                }

            }

        }
        if (!FileSizeSetLimit(file.size)) {
            alert.setSnack({
                ...alert,
                open: true,
                severity: AlertProps.severity.error,
                msg: "File Size Too Large !",
                vertical: AlertProps.vertical.top,
                horizontal: AlertProps.horizontal.center
            })
        }


    };
     
    // Handle Delete File
    const handleDelete = (i) => {
        uploaddocc.splice(i, 1);
        setuploaddocc([...uploaddocc]);
        if(uploaddocc.length > 0){
            uploaddocc?.map((item)=>{
                props.onDelete('file_ids',item,'multi')
                return 0;
            })
        }
        else{
            props.onDelete('file_ids',uploaddocc,'single')
        }
       
    };

    const getTitle = (props) => {
        return (
            <div style={{display:"flex"}}>
                       <Typography variant="body1" style={{ fontSize: "12px", color: "textsecondary" }} gutterBottom >{props.label} </Typography>
                       {props.isRequired && (
                <Typography color="error" variant="caption">
                  &nbsp;*
                </Typography>
              )}
            </div>    
        )
       
    }


    return (
        <div className={classes.rootContainer}>

            <div className={classes.root} noValidate>   
                    {getTitle(props)}
                <Grid>
                    {!props?.isReadonly && <Grid item>
                        <Upload onChange={handleUpload} single={props?.single} state={uploaddocc} />
                    </Grid>}


                    {uploaddocc?.length > 0 && <div style={{ overflowY: 'auto', overflowX: 'hidden', height: props?.single ? "62.5px" : "125px" }}>

                        {
                            uploaddocc?.map((item,index) => {
                                return (

                                    <Grid
                                        container
                                        direction="row"
                                        alignItems="center"
                                        className={classes.Container}
                                        key={index}
                                    >
                                        <Grid
                                            item
                                            xs={1.5}
                                        >
                                            <img src="./images/Docs.svg" alt="docs_image" />
                                        </Grid>

                                        <Grid
                                            item
                                            xs={props?.isReadonly ? 9 : 6.5}
                                            className={classes.uploadText}
                                        >
                                            <Typography variant="subtitle2" color="textPrimary">
                                                {item?.name}
                                            </Typography>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            {/* {<IconButton
                                                onClick={() => Download(item)}
                                            >
                                                <GetAppIcon style={{ color: "green" }} />
                                            </IconButton>
                                            } */}
                                        </Grid>

                                        <Grid
                                            item
                                            xs={2}
                                        >
                                            {!props?.isReadonly && <IconButton
                                                onClick={() => handleDelete(item)}
                                                aria-label="settings"
                                                disabled={props?.isReadonly}
                                            >
                                                <CloseIcon style={{ color: "red", marginRight: "3px" }} />
                                            </IconButton>}
                                        </Grid>
                                    </Grid>
                                );
                            })
                        }
                    </div>}
                </Grid>

            </div>
        </div >
    );
};
