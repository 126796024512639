import {QuickActionQueryParams} from ".";


export let Quickdata = [];

export const setQuickActions = () => {
    Quickdata = [];
    const items = JSON.parse(sessionStorage.getItem('quick_action'))

    items?.map((i) => {
        let arrChild = [];
        i?.items?.map((list) => {
            arrChild.push({
                icontext: list,
                route: "?quick_action=" + QuickActionQueryParams[list]
            });
            return 0;
        });
        Quickdata.push({
            id: i.title,
            header: i.title,
            content: arrChild,
        });
        return 0;
    });

    Quickdata.push({id: "Last", header: "", content: []})
};

setQuickActions();

//  const Quickdata = [
//     {
//         id: 0,
//         header: "Communication",
//         lineColor: '#445B75',
//         content:[
//             {
//                 icontext: 'Announcement',
//                 route: "?quick_action=" + QuickActionQueryParams.createAnnouncement
//             }
//         ]     
//     },
//     {
//         id: 1,
//         header: "Helpdesk",
//         lineColor: '#23BBA5',
//         content:[
//             {
//                 icontext: 'Create Ticket',
//                 route: "?quick_action=" + QuickActionQueryParams.createTicket
//             }
//         ]
//     },
//     {
//         id: 2,
//         header: "Mollak",
//         lineColor: '#FD4F4F',
//         content:[
//             {
//                 icontext: 'Configure Mollak',
//                 route: "?quick_action=" + QuickActionQueryParams.configureMollak
//             }
//         ]

//     },
//     {
//         id: 3,
//         header: "Purchase Manager",
//         lineColor: '#445B75',
//         content:[
//             {
//                 icontext: 'Manage Signature',
//                 route: "?quick_action=" + QuickActionQueryParams.manageSignature
//             }
//         ]

//     },
//     {
//         id: 4,
//         header: "Access Control",
//         lineColor: '#9066C5',
//         content:[
//             {
//                 icontext: 'Add Staff',
//                 route: "?quick_action=" + QuickActionQueryParams.addStaff
//             },
//             {
//                 icontext: 'Remove Staff',
//                 route: "?quick_action=" + QuickActionQueryParams.removeStaff
//             }
//         ]    
//     },
//     {
//         id: 5,
//         header: "",
//         lineColor: "",
//         icontext: "",
//         icon: ''
//     }
// ]
