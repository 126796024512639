import React from 'react';
import { QuickActionCard } from './quickActionCard';

class QuickActionCardParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <QuickActionCard {...this.props} />
    }
}

export default QuickActionCardParent;