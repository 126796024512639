import * as React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Tooltip,
  Rating,
  tooltipClasses
} from "@mui/material";
import { makeStyles,styled } from "@mui/styles";
import Styles from "./styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(Styles);

export default function DataReportTable({columns,rows,configure}) {
  const classes = useStyles();
  const [subHeaders, setSubHeaders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [sorted, setSorted] = React.useState({});
  const [tableData, setTableData] = React.useState([]);


  // Table Sorting
  const handleSorting = (id, index) => {
    let data = sorted;
    if (data?.id === id) {
      if (data?.order === "asc") {
        data.order = "desc";
      } else {
        data.id = "";
        data.order = "";
      }
    } else {
      data.id = id;
      data.order = "asc";
    }
    //here
    let tableData1 = [...tableData];
    if (data.order) {
      let totalMemCopy = tableData1.pop();
      tableData1.sort((a, b) => {
        var ele1 =
          typeof a[id]?.name === "string"
            ? parseInt(a[id]?.name?.replace(",", ""))
            : a[id]?.name;
        var ele2 =
          typeof b[id]?.name === "string"
            ? parseInt(b[id]?.name?.replace(",", ""))
            : b[id]?.name;
        if (data.order === "asc") return ele1 - ele2;
        else return ele2 - ele1;
      });
      tableData1.push(totalMemCopy);
      setTableData(tableData1);
    } else {
      setTableData(rows);
    }
    setSorted(data);
  };

  const hitLink =(link)=>{
     if(link !== ""){
      window.open(link)
     }
  }

  React.useEffect(() => {
    setTableData(rows);
  }, [rows]);

  ///generating subheaders for columns
  React.useEffect(() => {
    var subHeaders = [];
    if (columns && columns[0]) {
      subHeaders.push({
        ...columns[0].child[0],
        align: "left",
      });
      columns.slice(1).map((column, i) => {
        var subHeadersList = column.child;
        subHeadersList.map((subheader, i1) => {
          subHeaders.push({
            ...subheader,
            class: i1 + 1 === subHeadersList.length && "borderRight",
          });
          return subheader;
        });
        return column;
      });
    }
    setSubHeaders(subHeaders);
    setLoading(true);
  }, [columns]);

  return (
    <Paper className={classes.root}>
      {loading && (
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              {/* table Header generator START */}
              <TableRow>
                {columns.map((item,index) => {
                  return (
                    <TableCell
                      align="center"
                      colSpan={item?.child?.length ?? 1}
                      rowSpan={item.rowSpan ?? 1}
                      style={item.style}
                      minwidth={item.minWidth}
                      className={"borderRight"}
                      key={index}
                    >
                      {item.name}
                    </TableCell>
                  );
                })}
              </TableRow>
              {/* table Header generator END */}
              {/* table subheader generator START */}
              <TableRow>
                {subHeaders.map((column, shIndex) => (
                  <>
                    {column?.label ? (
                      <TableCell
                        key={column.id}
                        align={column?.align ?? "center"}
                        className={`${column?.class} ${classes.sort} ${
                          column.id === sorted?.id ? sorted?.order : "both"
                        }`}
                        style={{
                          top: 57,
                          minWidth: column?.minWidth ?? "186px",
                          zIndex: column?.style?.zIndex ? 5 : 1,
                          ...column.style,
                          cursor: "pointer",
                          whiteSpace:'nowrap'
                        }}
                        onClick={() => handleSorting(column.id, shIndex)}
                      >
                        {column?.info ? (
                          <ShowInfo info={column.info} label={column.label} />
                        ) : (
                          column.label
                        )}
                      </TableCell>
                    ) : null}
                  </>
                ))}
              </TableRow>
              {/* table subheader generator END */}
            </TableHead>
            {/* table body generator START */}
            <TableBody>
              {tableData.map((row,rIndex) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {subHeaders.map((column, i) => {
                      let value = row[column.id]?.name;
                      let isHighlited = false;
                      // let configuration = configure[column?.id];
                      isHighlited = row[column.id]?.highlight === 1 ? true : false
                        // i && configuration?.value && tableData[rIndex+1]
                        //   ? configuration?.comparision === "gt"
                        //     ? value > configuration?.value
                        //     : value < configuration?.value
                        //   : false;

                      return (
                        <TableCell
                          style={column.style}
                          className={column.class}
                          key={column.id}
                          align={column?.align ?? "center"}
                          onClick={()=>hitLink(row[column.id]?.link)}
                        >
                          {/* variation isHighlighted,rating,defaultValue */}
                          {isHighlited ? (
                            <Chip
                              label={value}
                              size={"small"}
                              className={classes.highlightedText}
                            />
                          ) : (
                            <>
                              {column.id === "hd_average_rating" ? (
                                <CustomRating 
                                 value={value}
                                />
                              ) : (
                                value
                              )}
                            </>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
            {/* table body generator END */}
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
}

DataReportTable.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
};

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    fontSize:"14px"
  },
});

const ShowInfo = ({ label, info }) => {
  const InfoIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width={14} height={14} fill="none">
      {" "}
      <path
        d="M7 0a6.998 6.998 0 00-7 6.938V14h7.187C10.967 13.891 14 10.796 14 7c0-3.873-3.127-7-7-7z"
        fill="#FFF500"
      />{" "}
      <path
        d="M7.747 3.7a.721.721 0 01-.234.529.777.777 0 01-.544.218.631.631 0 01-.296-.063 1.343 1.343 0 01-.248-.155.65.65 0 01-.172-.233.61.61 0 01-.062-.28.55.55 0 01.062-.265.82.82 0 01.171-.233c.078-.062.156-.125.25-.156A.631.631 0 016.968 3c.109 0 .202.016.295.062.094.047.172.094.25.156.077.062.124.14.17.218a.511.511 0 01.063.264zm-.078 5.771c0 .218-.062.389-.187.513a.692.692 0 01-.498.203c-.202 0-.373-.063-.497-.203a.719.719 0 01-.187-.513V5.91c0-.218.062-.389.187-.529a.692.692 0 01.497-.202c.203 0 .374.062.498.202.125.124.187.311.187.529V9.47z"
        fill="#151515"
      />{" "}
    </svg>
  );

  return (
    <React.Fragment>
      <span style={{ paddingRight: 3 }}>{label}</span>
      <CustomWidthTooltip title={info}>
      <span>
          <InfoIcon />
        </span>
      </CustomWidthTooltip>
    </React.Fragment>
  );
};

const CustomRating = ({value}) => {
  const classes = useStyles();

  return (
    <>
    <Rating
      value={value}
      precision={0.1}
      max={5}
      className={ value < 3 ? 
        classes.badRating 
        : value === 5 
        ? classes.goodRating 
        : classes.mediumRating}
      readOnly
    />
    </>
  );
};
