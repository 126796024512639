import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Checkbox,FormControl,FormGroup} from "@mui/material";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    check:{
      '&.Mui-checked': {
        color: "#4E9F3D",
      },
    }
  }));

  export const PropCheck =(props)=>{

    // Get data and Selected Data
    const {data,PData} = props;
    // 
    const classes = useStyles();  

      return(
        <FormControl
        sx={{ m: 0,ml:2 }}
        component="fieldset"
        variant="standard"
      >
        {data.length > 0
          ? data.map((items) => (
              <FormGroup key={items.id}>
                <FormControlLabel
                  control={
                    <Checkbox
                    checked={PData.some(
                      item => item === items.id
                    )}
                      onChange={(e) => props?.onChange(e, items.id) }
                     className={classes.check}
                    />
                  }
                  label={items.name}
                />
              </FormGroup>
            ))
          : null}
      </FormControl>
      )
  }

  PropCheck.propType={
   data2 : PropTypes.array,
  }