export const Signaturepath = ["id","user","emailid","image"]

export const Signaturerow = [
    {
        id: "1",
       user:"Jhon F Kennedy",
       emailid:"jhonfk@jfk.com",
       avatar:"/images/user1.svg",
       image:'/images/icons/Sign.svg'
      },
      {
        id: "2",
        user:"Peter Jeminson",
        emailid:"peter12@jfk.com",
        avatar:"/images/user2.svg",
        image:'/images/icons/Sign.svg'
      },
      {
        id: "3",
       user:"Andy Flower",
       emailid:"andyflower234@jfk.com",
       avatar:"/images/user3.svg",
       image:'/images/icons/Sign.svg'
      },
      {
        id: "4",
        user:"Scott Styries",
        emailid:"sstuss@jfk.com",
        avatar:"/images/user4.svg",
        image:'./images/icons/Sign.svg'
      },
      {
        id: "5",
        user:"Mathew Flintoff",
        emailid:"mf@jfk.com",
        avatar:"/images/user5.svg",
        image:'./images/icons/Sign.svg'
      },
      {
        id: "6",
        user:"James Anderson",
        emailid:"janderson@jfk.com",
        avatar:"/images/user1.svg",
        image:'./images/icons/Sign.svg'
      },
      {
        id: "7",
        user:"Andy Flower",
        emailid:"andyflower234@jfk.com",
        avatar:"/images/user2.svg",
        image:'./images/icons/Sign.svg'
      },
      {
        id: "8",
        user:"Scott Styries",
        emailid:"sstuss@jfk.com",
        avatar:"/images/user3.svg",
        image:'/images/icons/Sign.svg'
      },
      {
        id: "9",
        user:"Mathew Flintoff",
        emailid:"mf@jfk.com",
        avatar:"/images/user4.svg",
        image:'/images/icons/Sign.svg'
      },
      {
        id: "10",
        user:"James Anderson",
        emailid:"janderson@jfk.com",
        avatar:"/images/user5.svg",
        image:'/images/icons/Sign.svg'
      },
     
]
export const Signatureheading = [
   
  { title: "S no", field: "Sno" },
  { title: "Name", field: "name", },
  { title: "Email ID", field: "email", },
  { title: "Signature", field: "signature"},
   
]


export const SignatureReportpath = ["date","by","of","field","before","after"]

export const SignatureReportrow = [
    {
        date: "22-11-21",
         by:"Admin",
       of:"Block D-104",
       field:"Shift L2",
       before: '-' ,
       after:'-'
      },
     
]
export const SignatureReportheading = [
   
  { title: "Date", field: "date", type: "numeric" },
  { title: "By", field: "by", },
  { title: "Of", field: "of", },
  { title: "Field", field: "field", },
  { title: "Before", field: 'before', },
  { title: "After", field: 'after', }
   
]