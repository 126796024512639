import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import withStyles from '@mui/styles/withStyles';
import React from "react";
import { DialogContext } from "./contexts";


const styles = (theme) => ({
  dialog: {
    '& .MuiDialogActions-root': {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        '& .MuiButtonBase-root': {
          width: '80%',
          '&:not(:first-child)': {
            marginTop: '10px'
          }
        },
      },
   
      width:"100%"
    },
    overflowY:'clip',
  },
  title: {
    textAlign: 'center'
  },
  dialogActions: {
    padding: '0px 24px 16px 24px'
  },
  Btitle: {
    m: 0, p: 2, display: 'flex', flexDirection: 'row', justifyContent: "space-between", fontFamily: "adda_ccc_bold", fontSize: "22px", color: "#0A483C"
  },
})

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle  {...other}>
      {children}
      {onClose && (
        <Button
          aria-label="close"
          onClick={onClose}
          color="error"
          style={{ textDecorationLine: 'underline' }}
          endIcon={<CloseIcon />}
        >
          Close
        </Button>
      )}
    </DialogTitle>
  );
};
class AppDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: "",
      body: "",
      positiveBtn: "Ok",
      negativeBtn: "Cancel",
      hideNegativeBtn: false,
      hidePostivieBtn: false,
      onOk: () => null,
      onCancel: this.close,
    };
  }

  close = () => {
    this.setState({
      open: false,
    });
  };




  set = (props) => {
    this.setState({ ...props });
  };

  render() {
    const { classes } = this.props
    return (
      <DialogContext.Provider
        value={{
          ...this.state,
          setDialog: this.set,
        }}

      >
        {this.props.children}
        <Dialog
          maxWidth="md"
          fullWidth
          open={this.state.open}
          onClose={this.state.onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{overflowY: this.state.title === "Manage Signature" ? "clip" :"auto"}}
          classes={
            {
              paper: classes.dialog
            }
          }
        >
          <BootstrapDialogTitle id="customized-dialog-title" className={classes.Btitle} onClose={this.state.onCancel}>
            {this.state.title}
          </BootstrapDialogTitle>
          {/* <DialogTitle id="alert-dialog-title" className={classes.title}>
            {this.state.title}
          </DialogTitle> */}
          {this.state.body &&
            <DialogContent style={{ padding: '0px 5px 15px 15px' }} >
              <DialogContentText id="alert-dialog-description">
                {this.state.body}
              </DialogContentText>
            </DialogContent>
          }
          <DialogActions className={classes.dialogActions}>
            {!Boolean(this.state?.hideNegativeBtn) &&
              <Button onClick={this.state.onCancel} color="primary" variant="outlined">
                {this.state.negativeBtn}
              </Button>}
            {!Boolean(this.state?.hidePostivieBtn) && <Button onClick={this.state.onOk} color="primary" variant="contained">
              {this.state.positiveBtn}
            </Button>}
          </DialogActions>
        </Dialog>
      </DialogContext.Provider>
    );
  }
}

export default withStyles(styles)(AppDialog);
