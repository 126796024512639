import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { useHistory } from 'react-router';


const BreadCrumb = ({
    breadCrumbs = [],
    link = "",
    label = ""
}) => {
    
    const history = useHistory();

    function handleClick(link) {
        history.push(link)
    }
    
    return (
        <Stack spacing={2}>

            <Breadcrumbs
               
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
            >
                {breadCrumbs.map((element) => {
                    return (
                        <Link
                            underline="hover"
                            key="2"
                            color="#4782F5"
                            style={{cursor:"pointer"}}
                            onClick={()=>handleClick(element.link)}
                        >
                            {element.title}
                        </Link>
                    )
                })}
                {link && label && <Link
                            underline="hover"
                            key="2"
                            color="#4782F5"
                            style={{cursor:"pointer"}}
                            onClick={()=>handleClick(link)}
                        >
                            {label}
                        </Link>}
            </Breadcrumbs>
        </Stack>
    );
}
export default BreadCrumb;