import makeStyles from "@mui/styles/makeStyles";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DataReportTable, TabsComponent } from "../../components";
import FilterTreeParent from "../../components/filterTree";
import { Routes } from "../../router/routes";
import { Moduledata, Propdata } from "../../utils/filterTreeUtil";
import { NetworkCall } from "../../networkcall";
import {
  LoadingSection,
  InternalServerError,
  Pdfdownload,
} from "../../components";
import { makeExcel, commitExcel, writeTable } from "../../utils";
import config from "../../config";
import { Button, Grid } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

const useStyles = makeStyles((theme) => ({
  root: {},
  dataTable: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  tabletopbtn: {
    fontWeight: "lighter",
    boxShadow: "none",
    marginLeft: "6px",
    backgroundColor: "#F8F8F8",
    border: "1px solid #E9E9E9",
    borderRadius: "6px",
    color: "black",
    "&:hover": {
      backgroundColor: "#F8F8F8",
      border: "1px solid #E9E9E9",
      borderRadius: "6px",
      boxShadow: "none",
    },
  },
}));

export const DataReport = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [Filteropen, setFilteropen] = useState(false);
  const [rData, setData] = useState({
    columns: [],
    rows: [],
    configure: {},
  });
  const [loading, setLoader] = useState(false);
  const [error, setError] = useState(false);
  const [invisible, setInvisible] = React.useState(false);

  // Intial DataTable API
  useEffect(() => {
    setLoader(true);
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));

    NetworkCall(
      config.api_url + "/ccc_datatable_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        var main = response?.data;
        setData({
          columns: main?.data?.columns,
          rows: main?.data?.rows,
          configure: main?.data?.configure,
        });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setError(true);
      });
  }, []);

  const onFilterBtnClicked = () => {
    setFilteropen(!Filteropen);
  };

  // Once Filtered
  const OnFiltered = () => {
    setLoader(true);
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));

    NetworkCall(
      config.api_url + "/ccc_datatable_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        var main = response?.data;
        setData({
          columns: main?.data?.columns,
          rows: main?.data?.rows,
          configure: main?.data?.configure,
        });
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setError(true);
      });
  };

  // Handle Visible Button
  const handleVisible = (value) => {
    setInvisible(value);
  };

  // Configure Route
  const onConfigureBtnClicked = () => {
    history.push(Routes.configure);
  };

  // Tabs Handling
  const onTab1Clicked = () => {
    history.push(Routes.graphicalReport);
  };

  // Retry on Server Down
  const Retry = () => {
    window.location.reload();
  };

  // Table Column
  const dataColumn = [];
  //

  // Table Path
  const dataPath = [];
  //

  // Table Row
  const dataRow = [];
  //

  // Table Header Column
  const dataHead = [];
  //

  // Table Construction
  rData?.columns?.map((col) => {
    dataHead.push({
      title: col.name,
      field: col.id,
      rowSpan: col.child.length,
    });
    col?.child?.map((col2) => {
      dataColumn.push({ title: col2.label, field: col2.id });
      dataPath.push(col2.id);
      return 0;
    });
    return 0;
  });
  //

  // Row Construction
  rData?.rows?.map((row, index) => {
    let data = {};
    Object.keys(row).forEach((key) => {
      data[key] = row[key].name;
    });
    dataRow.push(data);
    return 0;
  });
  //

  const Headrow = [];
  const Columnrow = [];
  // Excel Column Construction
  dataColumn.forEach((col) => {
    Columnrow.push(col.title);
  });
  //

  // Excel Header Construction
  dataHead.forEach((head) => {
    Headrow.push(head.title);
  });
  //

  // Excel Row Construction
  const mainRow = dataRow.map((row) => {
    let rows = [];
    Object.values(row).forEach((value) => {
      rows.push(value);
    });
    return rows;
  });
  //

  // Download Excel
  const download = async () => {
    const { workbook, workSheet } = await makeExcel(
      "../../../DataTable.xlsx",
      "Data Sheet"
    );
    await writeTable(workSheet, dataHead, Columnrow, mainRow);
    await commitExcel(workbook, workSheet);
  };
  //
  return (
    <div className={classes.root}>
      <TabsComponent
        selectedTab={1}
        onTab1Clicked={() => onTab1Clicked()}
        onFilterBtnClicked={() => onFilterBtnClicked()}
        onFilterClicked={Filteropen ? "#007961" : ""}
        onConfigureBtnClicked={() => onConfigureBtnClicked()}
        invisible={invisible}
      />

      <div className={classes.dataTable}>
        {loading && <LoadingSection />}
        {error && (
          <InternalServerError
            onRetry={Retry}
            message="Internal Server Error"
          />
        )}

        {!loading && !error && (
          <div>
            <Grid
              container
              justifyContent="flex-end"
              style={{ padding: 10, paddingRight: 20 }}
            >
              <Grid item>
                <Button
                  startIcon={<DownloadIcon />}
                  variant="contained"
                  className={classes.tabletopbtn}
                  onClick={download}
                >
                  Excel&nbsp;
                </Button>
              </Grid>
              <Grid item>
                <Pdfdownload
                  main
                  heading={dataColumn}
                  head={dataHead}
                  rows={dataRow}
                  title={"Data Table"}
                />
              </Grid>
            </Grid>
            <div style={{ paddingRight: 12 }}>
              <DataReportTable
                columns={rData.columns}
                rows={rData.rows}
                configure={rData.configure}
              />
            </div>
          </div>
        )}

        {Filteropen && (
          <FilterTreeParent
            open={Filteropen}
            Moduledata={Moduledata}
            Propdata={Propdata}
            onClose={onFilterBtnClicked}
            handleVisible={handleVisible}
            handleFiltered={OnFiltered}
          />
        )}
        <br />
        <br />
      </div>
    </div>
  );
};
