import { Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { Steppers } from "../../components";
import { Addstaff1 } from "./addstaff1";
import { Addstaff2 } from "./addstaff2";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: 10,
  },
}));

//Initial State
const initialState = {
  community: [],
  firstname: "",
  lastname: "",
  email: "",
  mobile: "",
  destination: "",
  error: {
    community: "",
    firstname: "",
    lastname: "",
    email: "",
    mobile: "",
    destination: "",
  },
};

export const AddstaffParent = (props) => {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [addstaff, setAddstaff] = React.useState({ ...initialState });
  const [Mselected, setMSelected] = React.useState([]);
  const [checked, setChecked] = React.useState(false);
  const completed = {};
  const steps = ["Add Staff", "Add Staff2"];

  // Configs List for Admin Staff Access
  const arr = [];
  const recepient = JSON.parse(sessionStorage.getItem("configs_list"));
  recepient?.map((items) => {
    let cnt = 0;
    let child = 0;
    items?.modules_list?.map((list, index) => {
      let arrChild = [];
      child = cnt;
      list.Sub_Headings.map((val, index) => {
        child = child + 1;
        arrChild.push({
          id: child,
          name: val,
        });
        return 0;
      });
      arr.push({
        id: cnt,
        name: list.Heading,
        children: arrChild,
      });
      cnt = child + 1;
      return 0;
    });
    return arr;
  });
  //

  // Next Stepper
  const handleNext = () => {
    const newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    setShow(true);
  };
  //

  //Back Stepper
  const handleback = () => {
    const newActiveStep = activeStep - 1;
    setActiveStep(newActiveStep);
    setShow(false);
  };
  //

  //Active Stepper
  const handleStep = (step) => () => {
    setActiveStep(step);
  };
  //

  //On Select All Access
  const onAll = (e) => {
    e.stopPropagation();
    let all = [];
    arr?.map((val) => {
      all.push(val.id);
      if (val.children) {
        val?.children?.map((ch) => {
          all.push(ch.id);
          return 0;
        });
      }
      return 0;
    });
    setMSelected(all);
  };
  //

  //On Final Add staff
  const handleAddstaff = (key, value) => {
    if (key === "community") {
      let Al = [];
      let ALL = [];
      value.map((val) => {
        if (val.value === "all") {
          ALL.push({ value: "all", label: "All Properties" });
          setAddstaff({ ...addstaff, [key]: ALL });
        } else {
          Al.push(val);
          setAddstaff({ ...addstaff, [key]: Al });
        }
        return 0;
      });
    } else {
      let error = addstaff.error;
      error[key] = "";
      setAddstaff({ ...addstaff, [key]: value, error });
    }
  };
  //

  // Handle Checkbox Tree
  const handleModuleSelect = (e, node, option) => {
    const first = node.id;
    if (Mselected.includes(first)) {
      let unchecked = [...Mselected];
      for (var i = 0; i < unchecked.length; i++) {
        if (unchecked[i] === first) {
          unchecked.splice(i, 1);
        }
      }
      if (node.children) {
        node.children.forEach((items) => {
          for (var i = 0; i <= unchecked.length; i++) {
            if (unchecked[i] === items.id) {
              unchecked.splice(i, 1);
            }
          }
        });
      }
      setMSelected(unchecked);
    } else {
      let checked = [...Mselected];
      if (node.children) {
        checked.push(first);
        node.children.forEach((items) => {
          checked.push(items.id);
        });
      } else {
        arr.forEach((ch) => {
          if (ch.children.length) {
            if (ch.children.some((x) => x.id === first)) {
              checked.push(first);
              checked.push(ch.id);
            }
          }
        });
      }
      setMSelected(checked);
    }
  };
  //

  // Handle Only Module
  const OnOnly = (e, node) => {
    setChecked(false);
    const onlySelected = [];
    if (node.children) {
      Mselected.map((key) => {
        if (key === node.id) {
          onlySelected.push(node.id);
        }
        node.children.map((item) => {
          if (key === item.id) {
            onlySelected.push(key);
          }
          return item;
        });
        return key;
      });
      setMSelected(onlySelected);
    } else {
      let checked = [...Mselected];
      arr.forEach((key) => {
        if (key.children.length) {
          if (key.children.some((x) => x.id === node.id)) {
            key.children.map((ch) => {
              for (var i = 0; i < checked.length; i++) {
                if (checked[i] === ch.id) {
                  checked.splice(i, 1);
                }
              }
              return 0;
            });
            checked.push(node.id);
          }
        }
      });
      setMSelected(checked);
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={8} lg={6}>
          <br />
          {/* Stepper section*/}
          <Steppers
            steps={steps}
            handleStep={handleStep}
            completed={completed}
            activeStep={activeStep}
          />
        </Grid>
        {/* Step 1*/}
        {!show && (
          <Grid item xs={12}>
            <Addstaff1
              nextStep={handleNext}
              handleAddstaff={handleAddstaff}
              addstaff={addstaff}
            />
          </Grid>
        )}

        {/* Step 2*/}
        {show && (
          <Grid item xs={12}>
            <Addstaff2
              back={handleback}
              initialData={addstaff}
              OnOnly={OnOnly}
              handleModuleSelect={handleModuleSelect}
              checked={checked}
              Mselected={Mselected}
              arr={arr}
              onMain={props.onClose}
              onAll={onAll}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
