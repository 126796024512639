import { Box, Step, StepButton, StepLabel } from "@mui/material";
import StepConnector, {
  stepConnectorClasses
} from "@mui/material/StepConnector";
import Stepper from "@mui/material/Stepper";
import { makeStyles, styled } from "@mui/styles";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  iconContainer: {
    transform: "scale(2.6)",
  },
  stepIcon: {
    color: "#E7E7E7",
  },
  stepper: {
    '& .css-1kjhfkh-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
      color:theme.palette.primary.light
    },
    '& .makeStyles-stepIcon-12': {
      color:"#E7E7E7"
    }
  }
}));
// Custom Stepper
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#E7E7E7",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#E7E7E7",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 5,
    border: 0,
    backgroundColor: "#E7E7E7",
    borderRadius: 1,
  },
}));

export const Steppers = (props, { steps = [], handleStep = () => false }) => {
  const classes = useStyles();
  return (
    <Box>
      <Stepper
        nonLinear 
        className={classes.stepper}
        activeStep={props.activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {props.steps.map((label, index) => (
          <Step  key={label}   completed={props.completed[index]}>
            <StepButton >
              <StepLabel
                color="success"
                StepIconProps={{
                  classes: { root: classes.stepIcon },
                }}
                style={{ zIndex: "999" }}
                classes={{
                  // apply this style
                  iconContainer: classes.iconContainer,
                }}
              ></StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <br />
    </Box>
  );
};
