import React from 'react';
import { TodoCard } from './todoCard';

class TodoCardParent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return <TodoCard {...this.props} />
    }
}

export default TodoCardParent;