import React, { useEffect } from "react";
import { NetworkCall } from "../../networkcall";
import config from "../../config";
import makeStyles from "@mui/styles/makeStyles";
import { SearchFilter, UserCard } from "../../components";
import { LoadingSection } from "../../components";
import { Typography } from "@mui/material";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: 10,
    minHeight: "80vh",
    maxHeight: "80vh",
  },
  headerTitle: {
    color: "#545454",
    fontSize: "17px",
    fontFamily: "adda_ccc_semi_bold",
    display: "flex",
    padding: 10,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
}));

export const RemoveStaff = () => {
  const classes = useStyles();
  const alert = React.useContext(AlertContext);
  const [staff, setStaff] = React.useState([]);
  const [mainStaff,setMainStaff] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [val, setVal] = React.useState("");

  // Initial
  useEffect(() => {
    setLoad(true);
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "staff");
    NetworkCall(
      config.api_url + "/ccc_quickfetch_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        const main = response?.data;
        const mainStaff = main?.data?.staff_data;
        setStaff(mainStaff);
        setMainStaff(mainStaff);
        setLoad(false);
        setVal("");
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // On Delete Staff
  const onDelete = () => {
    setLoad(true);
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "staff");
    NetworkCall(
      config.api_url + "/ccc_quickfetch_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        const main = response?.data;
        const mainStaff = main?.data?.staff_data;
        setStaff(mainStaff);
        setMainStaff(mainStaff);
        setLoad(false);
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Staff deleted!",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSearch = (value) => {
    setVal(value);
    if (value === "") {
      setStaff(mainStaff);
    } else {
      const newArray = staff.filter((el) => {
        if (
          el.staff_name.toLowerCase().includes(value.toLowerCase()) ||
          el.staff_email.toLowerCase().includes(value.toLowerCase()) ||
          el.staff_mobile.includes(value) ||
          el.staff_email.toLowerCase().includes(value.toLowerCase()) ||
          el.staff_properties.toLowerCase().includes(value.toLowerCase())
        ) {
          return el;
        } else {
          return 0;
        }
      });

      setStaff(newArray);
    }
  };

  return (
    <div className={classes.root}>
      <SearchFilter handleChange={onSearch} value={val} removeStaff />
      {load ? (
        <LoadingSection />
      ) : staff.length === 0 ? (
        <Typography className={classes.headerTitle}>
          There are no search results for your search
        </Typography>
      ) : (
        <UserCard data={staff} onCardDelete={onDelete} />
      )}
    </div>
  );
};
