import React from "react";
import { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { TableWithPagination } from "../../components";
import TextBox from "../../components/textbox";
import { Grid } from "@mui/material";
import { Button, Divider } from "@mui/material";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import { NetworkCall } from "../../networkcall";
import config from "../../config";
import { LoadingSection } from "../../components";
import { Mollakheading } from "../../utils/mollakUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 12,
    padding: 10,
  },
  btn: {
    backgroundColor: theme.palette.primary.light,
    boxShadow: "0px 4px 10px rgba(78, 159, 61, 0.24)",
    borderRadius: "5px",
    width: "100%",
  },
  btnMain: {
    marginTop: 24,
    marginLeft: 10,
  },
  middlebtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
  },
  btn2: {
    color: theme.palette.primary.light,
    border: "1px solid #4E9F3D",
    borderRadius: "5px",
    padding: 10,
    width: "318px",
  },
  log: {
    marginTop: 40,
  },
}));

export const MolakConfig = () => {
  const classes = useStyles();
  const [custId, setCustId] = useState("");
  const [load, setLoad] = useState(false);
  const [tableData, setTabledata] = useState({
    dataPath: [],
    rowData: [],
  });
  const alert = React.useContext(AlertContext);

  // On Customer ID change
  const onCustIdchange = (e) => {
    setCustId(e.target.value);
  };

  // Save Customer ID
  const onSave = () => {
    const data = {
      mollak_key: custId,
    };
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "save_mollak");
    Formdata.append("action_items", JSON.stringify(data));
    NetworkCall(
      config.api_url + "/ccc_quickactions_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Changes Saved Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch Mollak Table
  const fetchMollak = () => {
    setLoad(true);
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "mollak_properties");
    Formdata.append("key", "");
    NetworkCall(
      config.api_url + "/ccc_quickfetch_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        let main = response?.data;
        const dataRow = [];

        if (main?.data?.properties.length > 0) {
          main?.data?.properties.map((row, index) => {
            let data = {};
            Object.keys(row).forEach((key) => {
              data["Sno"] = index;
              data[key] = row[key];
            });
            dataRow.push(data);
            return 0;
          });
          setTabledata({
            dataPath: Object.keys(main?.data?.properties[0]),
            rowData: dataRow,
          });
          setLoad(false);
        } else {
          setLoad(false);
          alert.setSnack({
            ...alert,
            open: true,
            severity: AlertProps.severity.warning,
            msg: "No data received",
            vertical: AlertProps.vertical.top,
            horizontal: AlertProps.horizontal.center,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={classes.root}>
      <Grid container style={{ display: "flex" }}>
        <Grid item xs={12} sm={7} md={6} lg={6}>
          <TextBox
            label={"Set DAM Mollak Customer ID"}
            type="text"
            value={custId}
            onChange={(e) => onCustIdchange(e)}
          />
        </Grid>
        <Grid item className={classes.btnMain} xs={12} sm={2} md={1} lg={1}>
          <Button
            className={classes.btn}
            variant="contained"
            onClick={() => onSave()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <br />
      <Divider variant="middle" />
      <div className={classes.middlebtn}>
        <Button
          className={classes.btn2}
          variant="outlined"
          onClick={() => fetchMollak()}
        >
          Fetch Registered Property Groups
        </Button>
      </div>
      {load && <LoadingSection />}
      {!load && tableData.rowData.length > 0 ? (
        <TableWithPagination
          heading={Mollakheading}
          rows={tableData.rowData}
          path={tableData.dataPath}
          showpagination={true}
          count="2"
          showpdfbtn={false}
          showexcelbtn={false}
          showSearch={false}
          dataType={[
            { type: ["number"], name: "Sno" },
            { type: ["text"], name: "name" },
            { type: ["text"], name: "project_name" },
            { type: ["text"], name: "group_name" },
            { type: ["number"], name: "group_id" },
            { type: ["status"], name: "status" },
          ]}
        />
      ) : null}
    </div>
  );
};
