const allOptions = {
    payment_method_comparision: {
        chartArea: { width: '90%' },
        hAxis: {
            viewWindow: { min: 0 },
            gridlines: {
                color: 'transparent',
            },
        },
        vAxis: {
            gridlines: {
                color: 'transparent'
            },
            viewWindow: { min: 0 }
        },
        curveType: 'function',
        legend: { position: 'bottom' },
    },
    escalated_tickets: {
        chartArea: { width: '85%'},
        hAxis: {
        },
        vAxis: {
            gridlines: {
                count: 6,
                color: 'transparent',
            },
            viewWindow: { min: 0 },
        },
        series: {
            0: { curveType: 'function' },
        },
        legend: { position: 'none' },
        colors: ['#EA4537']
    },
    vendor_expense_comparision: {
        bar: { groupWidth: 16 },
        chartArea: { width: '90%' },
        legend: { position: 'none' },
        vAxis: {
            gridlines: {
                count: 6,
                color: 'transparent',
            },
        },
        hAxis: {
            gridlines: {
                count: 0
            }
        },
        colors: ["#25BE90"]
    },
    service_expense_comparision: {
        chartArea: {
            height: "90%",
            width: "100%",
        },
        pieSliceText: 'none',
        legend: {
            alignment: 'center',
            textStyle: {
                color: '#545454',
                fontSize: 12,
                fontWeight: 600
            }
        },
        hAxis: {
            minValue: 0,
            gridlines: {
                color: 'transparent'
            }
        },
        vAxis: {
            gridlines: {
                color: 'transparent'
            }
        },
        slices: {
            0: { color: '#FF7B7B' },
            1: { color: '#FFC074' },
            2: { color: '#74FFBC' },
            3: { color: '#FF937B' },
            4: { color: '#9E7BFF' },
            5: { color: '#7BB0FF' },

        },
        pieHole: 0.6
    },
    collection_summary_report: {
        hAxis: {
  
        },
        isStacked: true,
        vAxis: {
       
        },
        chartArea: {
            width: '75%',
            height:"70%",
            overflowX:"scroll"
        },
        legend: {
            position: 'none',
        },
        colors: ['#25BE90', '#BE4125']
    },
}

export default allOptions;