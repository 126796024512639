import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { FormControlLabel, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
    marginTop: "20%",
  },
  Dialog: {
    borderRadius: 10,
    boxShadow: "0px 3px 24px rgba(0, 0, 0, 0.18)",
  },
  DialogContent: {
    minWidth: "410px",
    minHeight: "346px",
  },
  TabItem: {
    "&:hover": {
      "& $HidetextItem": {
        color: "#007961",
      },
    },
  },
  labelItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft:"-15px"
  },

  HidetextItem: {
    position: "absolute",
    right: 0,
    color: "transparent",
    fontFamily: "adda_ccc_regular",
    fontSize: 14
  },
  Tabchild: {
    "&:hover": {
      "& $HidetextChild": {
        color: "#007961",
      },
    },
  },
  labelChild: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft:"20px",  
  },
  HidetextChild: {
    position: "absolute",
    right: 0,
    color: "transparent",
    fontFamily: "adda_ccc_regular",
    fontSize: 14
  },
  check: {
    '&.Mui-checked': {
      color: "#4E9F3D",
    },
    marginLeft:10,
    "@media (max-width: 425px)": {
     marginLeft:15
    }
  },
 
}));

const TreeHead = withStyles({
  root: {
    "& .MuiTreeItem-content": {
      "&:hover": {
        backgroundColor: "#E1FEDB",
        color: "#007961"
      },
    },
  },
})(TreeItem);

const TreeChild = withStyles({
  root: {
    "& .MuiTreeItem-content": {
      "&:hover": {
        backgroundColor: "white",
      },
    },
  },
})(TreeItem);

export const ModuleTree = (props) => {

  const classes = useStyles();
  const {data,MData,checked} = props;
  const [expanded, setExpanded] = React.useState([]);

  // Handle Filter Tree
  const handleToggle = (event, nodeId) => {
    setExpanded([...expanded,nodeId]);
  };
    // Handle Filter Tree
  const handleToggleClose =(event,nodeId)=>{
    const Id = expanded.filter(val => val !== nodeId)
    setExpanded(Id);
  }
  
  // Handle Filter Tree
  const handleChange=(e,nodes,option)=>{
    props.onChange(
      e,
      nodes,
      option)
  }

  return (
    <TreeView
      expanded={expanded}
    >
      {data.length > 0
        ? data.map((nodes, index) => (
          <TreeHead
            className={classes.TabItem}
            nodeId={nodes.id}
            key={nodes.id}
            label={
              <div className={classes.labelItem}>
                <div style={{marginRight:10,display:"flex"}}>
                {expanded.includes(nodes.id) ? <ExpandMoreIcon onClick={(e)=>handleToggleClose(e,nodes.id)}/>
                : <ChevronRightIcon onClick={(e)=>handleToggle(e,nodes.id)}/>
                } 
                </div>       
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={MData.some(
                        item => item === nodes.id
                      )}
                      onChange={(e) =>
                       handleChange(
                          e,
                          nodes,
                          "parent"
                        )
                      }
                      className={classes.check}
                    />
                  }
                  label={
                  <span>{nodes.name}</span>
                  }
                  key={nodes.id}
                />
                <span
                  className={classes.HidetextItem}
                  variant="caption"
                  onClick={(e) => props.OnOnly(e, nodes)}
                >
                  Only
                </span>
              </div>
            }
          >
            {Array.isArray(nodes.children)
              ? nodes.children.map((items, index) => (
                <TreeChild
                  nodeId={items.id}
                  key={items.id}
                  className={classes.Tabchild}
                  label={
                    <div className={classes.labelChild}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              checked
                                ? MData
                                : MData.some(
                                  item => item === items.id
                                )
                            }
                            onChange={(e) =>
                              props.onChange(
                                e,
                                items,
                                "child"
                              )
                            }
                            className={classes.check}
                          />
                        }
                        label={items.name}
                        key={items.id}
                      />
                      <span
                        className={classes.HidetextChild}
                        variant="caption"
                        onClick={(e) => props.OnOnly(e, items)}
                      >
                        Only
                      </span>
                    </div>
                  }
                ></TreeChild>
              ))
              : null}
          </TreeHead>
        ))
        : null}
    </TreeView>
  )

}

ModuleTree.propType = {
  data: PropTypes.array,
}