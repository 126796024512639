import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Routes } from "../../router/routes";
import BreadCrumb from "../../components/breadcrumb";
import ParaTitle from "../../components/paratitle";
import { ConfigurationCard } from "../../components";
import Button from "@mui/material/Button";
import { NetworkCall } from "../../networkcall";
import { AlertContext } from "../../contexts";
import { AlertProps } from "../../utils";
import config from "../../config";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "24px 24px 24px 40px",
    margin: 20,
    borderRadius: "6px",
  },
  Paratitle: {
    marginTop: theme.spacing(3),
  },
  configcard: {
    marginLeft: "-10px",
  },
  button: {
    display: "flex",
    justifyContent: "center",
    marginRight: "10px",
  },
}));

const Configure = () => {
  const classes = useStyles();
  const [drop, setDrop] = React.useState();
  const [open, setOpen] = React.useState(false);
  const alert = React.useContext(AlertContext);

  //  State Update
  const updateState = (id, value) => {
    setDrop({ ...drop, [id]: value });
    setOpen(true);
  };
  //

  // On Save
  const onConfigure = () => {
    let keys = Object.keys(drop);
    let configuration_values = {};
    keys.forEach((k) => {
      configuration_values[k] = drop[k].value;
    });
    let data = {
      configuration_values,
    };
    var Formdata = new FormData();
    let auth = {
      owner_id: localStorage.getItem("owner_id"),
      auth_key: localStorage.getItem("auth_token"),
    };
    Formdata.append("auth", JSON.stringify(auth));
    Formdata.append("case", "save_configuration");
    Formdata.append("action_items", JSON.stringify(data));
    NetworkCall(
      config.api_url + "/ccc_quickactions_ver1",
      "post",
      Formdata,
      null,
      false
    )
      .then((response) => {
        alert.setSnack({
          ...alert,
          open: true,
          severity: AlertProps.severity.success,
          msg: "Changes Saved Successfully",
          vertical: AlertProps.vertical.top,
          horizontal: AlertProps.horizontal.center,
        });
        setOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //

  // Breadcrumbs
  const BreadCrumbData = [
    {
      title: "Data Tables",
      link: Routes.dataReport,
    },
    {
      title: "Configure",
      link: "#",
    },
  ];
  //

  // Intial Config Data
  const configData = JSON.parse(sessionStorage.getItem("configs_values"));
  //

  return (
    <div className={classes.root}>
      <BreadCrumb breadCrumbs={BreadCrumbData} />
      <div className={classes.Paratitle}>
        <ParaTitle />
      </div>

      <div className={classes.configcard}>
        <ConfigurationCard
          data={configData}
          onUpdate={updateState}
          drop={drop}
        />
      </div>

      <div className={classes.button}>
        <Button
          variant="contained"
          color="success"
          style={{ width: "150px", marginTop: "40px" }}
          onClick={() => onConfigure()}
          disabled={open ? false : true}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
export default Configure;
