import React from "react";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Box, Button, Grid, Hidden, IconButton, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import makeStyles from "@mui/styles/makeStyles";
import { useState } from "react";
import QuickActionCardParent from "../../quickActionCard";
import { useHistory } from "react-router";
import { Quickdata,setQuickActions } from "../../../utils/QuickActionutils";
import { useLocation } from "react-router-dom";
import { DialogContext } from "../../../contexts";
import { LocalStorageKeys, Message, QuickActionQueryParams } from "../../../utils";
import { CreateAnnouncement, CreateTicket, MolakConfig, Signature, AddstaffParent,RemoveStaff } from '../../../screens';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: "none"
  },
  toolBar: {
    minHeight: 54
  },
  cccContainer: {
    [theme.breakpoints.up("md")]: {
      marginLeft: theme.spacing(2),
    },
    display: "flex",
    alignItems: "center",
    backgroundImage: `url("/images/CCC .svg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  title: {
    fontWeight: "bold",
    cursor:"pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(8),
    },
  },
  quickactionsbtn: {
    fontWeight: "bold",
    width: "151px"
  },
  globalreportbtn: {
    fontWeight: "bold",
    width: "151px"
  },
  mobileGlobalReportBtn: {
    background: theme.palette.info.main,
    borderRadius: theme.spacing(0.5),
    width: 32,
    height: 32
  },
  mobileQuickActionBtn: {
    background: theme.palette.secondary.main,
    borderRadius: theme.spacing(0.5),
    color: theme.palette.text.primary
  }
}));


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


export const TopNavBar2 = ({
  hidePersonalInfo = false,
  ...props
}) => {

  const query = useQuery();
  const classes = useStyles();
  const history = useHistory();
  const [Quickopen, onQuickopen] = useState(false);
  const dialog = React.useContext(DialogContext);

  console.log(Quickdata,"itemms")
 
  // Quick Action Open
  const onQuickAction = () => {
    let quickAction = query.get('quick_action');
    if (!quickAction) {
      query.set("quick_action", sessionStorage.getItem("quick_action"));
    }
    if (Quickdata.length != JSON.parse(sessionStorage.getItem('quick_action')).length + 1){
      setQuickActions();
    }
    onQuickopen(true);
  }
 
  // Quick Action Close
  const onQuickActionClose = () => {
    onQuickopen(false)
  }

  // Global reports
  const onGlobalAction = () => {
    let url = JSON.parse(sessionStorage.getItem('global_report'))
    window.open(url)
    // history.push(Routes.global_reports)
  }
 
  // Quick Action Open
  const giveMeQuickActionComponent = (act) => {
    return {
      [QuickActionQueryParams.announcement]: <CreateAnnouncement onClose={onDialogClose} />,
      [QuickActionQueryParams.create_ticket]: <CreateTicket onClose={onDialogClose} />,
      [QuickActionQueryParams.save_mollak]: <MolakConfig onClose={onDialogClose}/>,
      [QuickActionQueryParams.save_signature]: <Signature onClose={onDialogClose}/>,
      [QuickActionQueryParams.add_staff]: <AddstaffParent onClose={onDialogClose}/>,
      [QuickActionQueryParams.remove_staff]: <RemoveStaff onClose={onDialogClose}/>
    }
  }

  // Quick Action Openn Check
  const canIOpen = (action) => {
    return [
      QuickActionQueryParams.announcement,
      QuickActionQueryParams.create_ticket,
      QuickActionQueryParams.save_mollak,
      QuickActionQueryParams.save_signature,
      QuickActionQueryParams.add_staff,
      QuickActionQueryParams.remove_staff,
    ].indexOf(action) > -1
  }

  React.useEffect(() => {

    //For Authtoken handling
    let authToken = query.get('authtoken');
    let ownerId = query.get('owner_id');

    if (authToken) { localStorage.setItem(LocalStorageKeys.authToken, authToken); }
    if (ownerId) { localStorage.setItem(LocalStorageKeys.ownerId, ownerId); }

    //For QuickAction Modal Navigation
    let quickAction = query.get('quick_action');
    if (!quickAction) {
      quickAction = sessionStorage.getItem("quick_action");
    }
    if (canIOpen(quickAction)) {
      dialog.setDialog({
        ...dialog,
        open: true,
        title: Message.English[quickAction].title,
        body: giveMeQuickActionComponent()[quickAction],
        hidePostivieBtn: true,
        hideNegativeBtn: true,
        showCloseBtn: true,
        onCancel: onDialogClose
      })
    }
    // eslint-disable-next-line
  }, [query]);


  // Dialog Context Close
  const onDialogClose = () => {
    dialog.setDialog({...dialog,open:false});
    history.push(history.location.pathname)
  }

  return (
    <div className={classes.grow}>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <Grid container>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center">
                {/* Central Command Center Logo  */}
                <Box>
                  <div className={classes.cccContainer}>
                    <Typography className={classes.title} variant="h6" onClick={()=>history.push("/graphical_report")}>
                      Central Command Center
                    </Typography>
                  </div>
                </Box>

                {/* Buttons Section  */}
                {!hidePersonalInfo && <Box flexGrow={1}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">

                    {/* Quick Actions Btn */}
                    <Box>
                      <Hidden smDown>
                        <Button variant="contained" size={"small"} color={"secondary"}
                          startIcon={<AddOutlinedIcon />}
                          className={classes.quickactionsbtn}
                          onClick={() => onQuickAction()}
                        >
                          Quick Actions
                        </Button>
                      </Hidden>

                      <Hidden smUp>
                        <IconButton size={"small"} color={"secondary"} className={classes.mobileQuickActionBtn}  onClick={() => onQuickAction()}>
                          <AddOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Hidden>
                    </Box>

                    {/* Global Report Btn  */}
                    <Box marginLeft="16px">
                      <Hidden smDown>
                        <Button variant="contained" size={"small"} color={"info"}
                          className={classes.globalreportbtn}
                          startIcon={<img src="/images/globalreport.svg" alt="globalreportLogo" />}
                          onClick={() => onGlobalAction()}
                        >
                          Global Reports
                        </Button>
                      </Hidden>
                      <Hidden smUp>
                        <IconButton size={"small"} className={classes.mobileGlobalReportBtn} onClick={() => onGlobalAction()}>
                          <img src="/images/globalreport.svg" alt="globalreportLogo" />
                        </IconButton>
                      </Hidden>
                    </Box>
                  </Box>
                </Box>}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {
        Quickopen &&
        <QuickActionCardParent
          open={Quickopen}
          data={Quickdata}
          onClose={onQuickActionClose}
        />
      }
    </div>
  );
};
