import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { NetworkCall } from "../../networkcall";
import config from "../../config";

let quillObj;

const useStyles = makeStyles((theme) => ({
  quill:{
    '& .ql-snow .ql-tooltip':{
      left: '0!important',
      position:'initial',
      transform:'translateY(0px)'
    }
  }
  
}))

const ReactQuillWrapper = (props) => {
  // Image Handler
  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async function () {
      const file = input.files[0];
      console.log("User trying to upload this:", file);

      const formData = new FormData();
      let auth = {
        owner_id: localStorage.getItem("owner_id"),
        auth_key: localStorage.getItem("auth_token"),
      };
      let file_details = {
        file_section: "notice",
        file_section_id: "",
      };
      formData.append("auth", JSON.stringify(auth));
      if (file !== null) {
        formData.append("file", file, file.name);
      }
      formData.append("file_details", JSON.stringify(file_details));

      NetworkCall(
        config.api_url + "/ccc_fileupload_ver1",
        "post",
        formData,
        null,
        false
      )
        .then((response) => {
          const main = response.data;
          const range = quillObj.getEditorSelection();
          const res = main.data?.file?.file_url;
          quillObj.getEditor().insertEmbed(range.index, "image", res);
        })
        .catch((err) => {
          console.log(err);
        });
    };
  };

  const classes = useStyles()
  // Modules
  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ 'bold': { tooltip: "Bold (Ctrl+B)" } }, "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          ["image", "link"],
          ["clean"],
        ],
        bounds: "'self'",
        handlers: {
          image: imageHandler,
          link: function (value) {
            if (value) {
              let range = this.quill.getSelection();
              if (range === null || range.length === 0) {
              }
              let tooltip = this.quill.theme.tooltip;
              tooltip.edit("link");
            } else {
              this.quill.format("link", false);
            }
          },
        },
      },
    }),
    []
  );
  
  const getTitle = (props) => {
    return (
      <div style={{ display: "flex" }}>
        <Typography
          variant="body1"
          style={{ fontSize: "12px", color: "textsecondary" }}
          gutterBottom
        >
          {props.label}
        </Typography>
        {props.isRequired && (
          <Typography color="error" variant="caption">
            &nbsp;*
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div>
      {getTitle(props)}
      <ReactQuill
        theme="snow"
        ref={(el) => {
          quillObj = el;
        }}
        value={props.value}
        modules={modules}
        className={classes.quill}
        placeholder={props?.placeholder}
        onChange={props.onChange}
      />
      {props.isError && (
        <Typography variant={"caption"} color={"error"}>
          {props.errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default ReactQuillWrapper;
