export const Routes = {
  home: "/",
  login: "/login",
  dataReport: "/data_report",
  configure: "/data_report/configure",
  graphicalReport: "/graphical_report",
  global_reports: "/global_reports",
  global_report: "/global_report/:id",
  global_report_parent: "/global_report/",
  create_announcement: "/create_announcement"
};
